import { render, staticRenderFns } from "./MarketTrends.vue?vue&type=template&id=5e78c32f&scoped=true"
import script from "./MarketTrends.vue?vue&type=script&lang=js"
export * from "./MarketTrends.vue?vue&type=script&lang=js"
import style0 from "./MarketTrends.vue?vue&type=style&index=0&id=5e78c32f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e78c32f",
  null
  
)

export default component.exports