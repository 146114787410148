<template>
  <div v-if="show">
    <CDialog
      id="dialog1"
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
        @close="handlClose">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :style="{ height: height_chart }">
          <div ref="dialogRef" style="width: 100%; height: 100%"></div>
          <!-- <div class="Yield_Style">年化收益率</div> -->
          <!-- <div class="Volatility_Style">年化波动率</div> -->
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CDialog from './CDialog';

export default {
  props: {
    show: Boolean,
    echart_options: Object,
  },
  components: { CDialog },
  data() {
    return {
      dialogVisible: true,
      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '72vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',
      //
      ROOTFONTFIZE: '',
    };
  },
  watch: {
    show(now) {
      if (now) {
        setTimeout(() => {
          this.renderEchart();
        }, 0);
      }
      this.dialogVisible = now;
    },
    echart_options(n) {
      if (this.echart_instance) {
        this.echart_instance.setOption(this.echart_options);
      }
    },
  },
  methods: {
    handlClose() {
      // console.log(555);
      setTimeout(() => {
        this.$emit('update:show', false);
      }, 0);
    },
    resize() {
      this.fnGetWidth();
      this.echart_instance && this.echart_instance.resize();
    },
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
    },
    fnDropdown(val) {
      let conf = this.$store.state.popUpWindowConfi[val];
      this.width_dialog = conf.width_dialog;
      this.top_dialog = conf.top_dialog;
      this.height_dialog = conf.height_dialog;
      this.popUpWindowIndex = val;
      this.height_chart = conf.height_chart;
      this.$nextTick(() => {
        // this.renderEchart();
        this.echart_instance && this.echart_instance.resize();
      });
    },
    renderEchart() {
      this.echart_instance = this.$echarts.init(this.$refs.dialogRef);
      this.echart_instance.setOption(this.echart_options);
    },
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
    // this.renderEchart();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    this.echart_instance && this.echart_instance.dispose();
  },
};
</script>

<style></style>
