<template>
  <div class="container">
    <span>
      <slot></slot>
      <el-cascader
        :style="cssVars"
        class="aaa"
        :class="{ last: isLast }"
        v-model="value"
        :popper-class="takeOut"
        :options="options"
        ref="cascaderCase"
        :show-all-levels="false"
        :placeholder="placeholder"
        :collapse-tags="collapsetags"
        :props="{
          expandTrigger: 'hover',
          checkStrictly: checkStrictly,
          multiple: multiple,
        }"
        @change="handleChange"></el-cascader>
    </span>
  </div>
</template>

<script>
export default {
  // 从父组件传值的
  props: {
    options: {
      type: Array,
      default: '',
    },
    wide: {
      type: Boolean,
      default: false,
    },
    middle: {
      type: Boolean,
      default: false,
    },
    default: {
      type: [String, Array],
      default: '',
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    checkStrictly: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    collapsetags: {
      type: Boolean,
      default: false,
    },
    needarr: {
      type: Boolean,
      default: false,
    },
    takeOut: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
  },
  methods: {
    handleChange(val) {
      let nodes = this.$refs.cascaderCase.getCheckedNodes();
      let nodesARR = nodes.map((node) => {
        return node.value;
      });

      // -------------------为node 部分---------------------------------
      let value = null;
      // ---------------------------------------------
      if (this.isDelete && val.length == 1) {
        this.isLast = true;
      } else {
        this.isLast = false;
      }
      // ---------------------------------------------

      if (this.multiple) {
        if (Array.isArray(val[0])) {
          value = [];
          val.forEach((node) => {
            node.forEach((e) => {
              value.push(e);
            });
          });

          value = [...new Set(value)];
        } else {
          value = val;
        }
      } else {
        value = this.needarr ? val : val[val.length - 1];
      }
      this.$emit('CCascader_select_val', value);
      this.$emit('CCascader_select_node', nodesARR);
    },
  },
  components: {},
  computed: {
    cssVars() {
      return {
        '--val3': this.val3,
      };
    },
  },
  created() {
    if (this.isDelete && this.value.length == 1) {
      this.isLast = true;
    } else {
      this.isLast = false;
    }
    // this.handleChange(this.value)
  },
  data() {
    return {
      value: this.default,
      isLast: false,
      val3: this.wide ? '300px' : this.middle ? '200px' : 'auto',

      //  dButtons1:['概览','市值相关','流动性相关'],
      //  dButtons1_current:'概览',
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

span {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: var(--chart_title);
}

// 切换按钮  start --------------------------------------------
::v-deep .el-cascader {
  background-color: #343435 !important;
  background-color: var(--cascader-bgc) !important;
  // border: 1px solid rgba(49, 53, 63, 1);
  padding: 0 !important;
  height: 35px !important;
  line-height: 35px !important;
  border: none;
  margin-left: 15px;
  display: flex !important;
  align-items: center !important;
  width: var(--val3) !important;
  border-radius: 3px !important;
}

::v-deep .el-input__inner {
  background-color: rgba(0, 0, 255, 0) !important;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
  padding-left: 5px !important;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  color: var(--autocomplete_suggestion__list_color);
  display: flex !important;
  align-items: center !important;
  // width: 100% !important;
  width: var(--val3) !important;
}

::v-deep .el-input__suffix {
  height: 100%;
  padding-right: 10px;
}

::v-deep .el-input__suffix-inner {
}

::v-deep .el-tag.el-tag--info {
  background-color: var(--cascader-bgc2) !important;
  color: var(--cascader-color) !important;
  border: none !important;
  font-weight: normal !important;
  display: flex;
  align-items: center;
}

::v-deep .el-tag .el-icon-close {
  background-color: #232324 !important;
  color: #fff !important;
  font-size: 12px !important;
}

::v-deep .el-cascader-menu__list {
  // background-color: #fff !important;
}

::v-deep .el-select-dropdown {
  background-color: var(--Select-bgc2) !important;
  border: none !important;
}

::v-deep .last .el-tag .el-icon-close {
  display: none;
}

::v-deep .el-checkbox__inner.is-indeterminate .el-checkbox__inner {
}

::v-deep .el-cascader-menu__list.el-cascader-node.el-checkbox {
  display: none;
}

// ::v-deep .el-checkbox.is-disabled{
//     display: none !important;
// }
</style>
