export function once(fn) {
  let called = false;
  return function () {
    if (!called) {
      called = true;
      fn.apply(this, arguments);
    }
  };
}

export function debounce(fn, delay = 500) {
  let timer = null;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
}

export function throttle(fn, delay = 500) {
  let timer = null;
  return function () {
    if (timer) {
      return;
    }
    fn.apply(this, arguments);
    timer = setTimeout(() => {
      timer = null;
    }, delay);
  };
}

export const codesToNameMap = (data) => {
  const getV = (list) => {
    return list.map((item) => {
      if (item.children instanceof Array) {
        return getV(item.children);
      }
      return item;
    });
  };
  return getV(data)
    .flat(Infinity)
    .reduce((p, c) => {
      p[c.value] = c.label;
      return p;
    }, {});
};

export const nestedArray = (value, z) => {
  let result = [value];
  for (let i = 0; i < z; i++) {
    result = [result];
  }
  return result;
};

// 个股默认值（全选）
export const codesToNameMap2 = (data) => {
  const getV = (data, ...args) => {
    return data.map((item) => {
      if (!item.children) {
        if (args.length) {
          return [...args, item.value];
        }
        // 第一层
        return [[[item.value]]];
      }
      return getV(item.children, ...args, item.value);
    });
  };
  return getV(data).flat(2);
};

// 大类默认值（全选）
export const categroyDefaultValue = (data) => {
  const getV = (data, ...args) => {
    return data.map((item) => {
      if (!item.children) {
        if (args.length) {
          return [...args, item.value];
        }
        return [[item.value]];
      }
      return [[item.value], ...getV(item.children, ...args, item.value)];
    });
  };
  return getV(data).flat(1);
};

export const numberFix2 = (n, size = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    // 使用 'en-US' 或任何不使用分组的 locale
    minimumFractionDigits: size,
    maximumFractionDigits: size,
    useGrouping: true, // 不使用分组
    roundingMode: 'trunc', //  直接截断，等同于floor
  });

  return formatter.format(n);
};
export const number2Pct = (n, size = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: size,
    useGrouping: false,
  });
  return formatter.format(n);
};

export function normalize(distribution, max, min) {
  let normalizationRatio = (distribution - min) / (max - min);
  return normalizationRatio;
}

const colorArr = ['#417f9c', '#eae8e8', '#ca6d55'];
export function getGradientColor(ratio, colors = colorArr) {
  // 确保 ratio 在 0-1 范围内
  ratio = Math.max(0, Math.min(1, ratio));

  // 确保至少有两种颜色
  if (!colors || colors.length < 2) {
    throw new Error('至少需要提供两种颜色');
  }

  const numColors = colors.length;
  // 计算每个颜色段的长度
  const segmentLength = 1 / (numColors - 1);
  // 确定 ratio 落在哪个颜色段
  const segmentIndex = Math.floor(ratio / segmentLength);
  // 计算在当前颜色段内的相对 ratio
  const segmentRatio = (ratio - segmentIndex * segmentLength) / segmentLength;

  // 如果 ratio 正好是 1，则直接返回最后一个颜色
  if (ratio === 1) {
    return new Color(colors[numColors - 1]).toString({ format: 'hex' });
  }

  // 创建起始颜色和结束颜色对象
  const startColor = new Color(colors[segmentIndex]);
  const endColor = new Color(colors[segmentIndex + 1]);

  // 使用 LCH 颜色空间进行插值
  const interpolatedColor = startColor.mix(endColor, segmentRatio, {
    space: 'lch',
  });

  return interpolatedColor.toString({ format: 'hex' });
}
