<template>
  <div class="card" ref="chartArea7">
    <div class="card_tit">
      估值分析器

      <div class="downloadBtns" v-show="isVip">
        <CButtonsDownload
          :dButtonsDownloads="dButtonsDownloads2"
          @fnButtondownload="fnButtonDL7_1" />

        <span style="display: none">
          <download-excel
            id="json_fields_column7"
            :data="data_ori"
            :fields="json_fields_column7"
            header="估值分析器"
            name="估值分析器.xls">
          </download-excel>
        </span>
      </div>
    </div>
    <div class="CH_Card_content" v-show="isVip">
      <CCategoryButtonNew
        :default_value.sync="presets_default"
        :list="presets_list"
        @CCategoryButton_select_val="fnCCascader_select_presets">
        预设包
      </CCategoryButtonNew>
    </div>
    <div class="CH_Card_content" v-show="isVip">
      <CCategoryButtonNew
        :default_value.sync="by_type"
        :list="by_type_list"
        @CCategoryButton_select_val="fnCCascader_select_val_by_type">
        显示类型
      </CCategoryButtonNew>
      <CSelect
        style="margin-left: 20px"
        @CSelect_select_val="fnCCascader_select_val_sortby"
        :options="sortby_list"
        :clearable="false"
        :mini="true"
        :default="sortby"
        >排序方式</CSelect
      >
      <CCascader
        style="margin-left: 20px"
        ref="CCascader_chart1"
        v-show="by_type_value == 'type'"
        @CCascader_select_node="fnCCascader_select_val_chart_type_r"
        :default="category_cascader_val_chart_type_r"
        :options="category_cascader_type_r"
        :checkStrictly="false"
        :multiple="true"
        :isDelete="false"
        :middle="true"
        :collapsetags="true"
        >选择类别</CCascader
      >

      <CCascader
        v-show="by_type_value == 'single'"
        ref="CCascader2_chart1"
        :checkStrictly="true"
        :multiple="true"
        :isDelete="false"
        :wide="true"
        class="chart3_cascasder CButtonsSeparate_style"
        takeOut="takeOut"
        placeholder="请选择个股"
        :collapsetags="true"
        @CCascader_select_val="fnSelect_select_val_chart_select_codes"
        :options="CODENAMEDATA">
        选择个股
      </CCascader>
    </div>
    <div class="CH_Card_content" v-show="isVip">
      <CSelect
        class="chart3_cascasder"
        @CSelect_select_val="change_factor1"
        :options="factor1_name_list"
        :clearable="false"
        :mini="false"
        :default="factor1_default">
        指标一</CSelect
      >
      <CSelect
        class="chart3_cascasder"
        @CSelect_select_val="change_factor1_graphic_type_val"
        :options="factor1_graphic_types"
        :clearable="false"
        :mini="true"
        :default="factor1_graphic_type_value">
      </CSelect>
      <CSelect
        class="chart3_cascasder CButtonsSeparate_style"
        @CSelect_select_val="change_factor2"
        :options="factor2_name_list"
        :clearable="false"
        :mini="false"
        :default="factor2_default">
        指标二</CSelect
      >
      <CSelect
        class="chart3_cascasder"
        @CSelect_select_val="change_factor2_graphic_type_val"
        :options="factor2_graphic_types"
        :clearable="false"
        :mini="true"
        :default="factor2_graphic_type_value">
      </CSelect>
      <CSelect
        v-if="factor1_graphic_type_value == 'bar' && factor2_graphic_type_value == 'bar'"
        class="chart3_cascasder CButtonsSeparate_style"
        @CSelect_select_val="change_type_bar"
        :options="bar_list"
        :clearable="false"
        :mini="true"
        :default="bar_default">
        柱状图方式</CSelect
      >
      <CSelect
        key="气泡指标选择"
        v-if="factor1_graphic_type_value == 'scatter' && factor2_graphic_type_value == 'scatter'"
        class="chart3_cascasder CButtonsSeparate_style"
        @CSelect_select_val="change_type_scatter"
        :options="bubble_name_list"
        :clearable="false"
        middle
        :default="bubble_name">
        气泡指标选择</CSelect
      >
    </div>
    <div v-show="!isVip" class="noVip">
      <i class="iconfont icon-taocan-suoxiao"></i>
      抱歉，当前内容只能VIP客户查看
      <el-button size="small" class="openBtn">联系管理员开通</el-button>
    </div>
    <div class="echarts_area CHARTS_BGC" v-show="isVip" ref="table7_1">
      <span @click="fnOpenDialog" class="iconfont icon-fangda icon-fangda2"></span>
      <div
        v-loading="loading_valuation_analyzer"
        element-loading-text="数据量巨大，正在计算中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        ref="chartColumnValuationAnalyzer"
        :style="{ width: '100%', height: OFFSETHEIGHT - 410 + 'px' }"></div>
      <div
        class="Yield_Style"
        v-if="factor1_graphic_type_value == 'scatter' && factor2_graphic_type_value == 'scatter'">
        {{ factor2.label }}
      </div>
      <div
        class="Volatility_Style"
        v-if="factor1_graphic_type_value == 'scatter' && factor2_graphic_type_value == 'scatter'">
        {{ factor1.label }}
      </div>
    </div>
    <CDialog
      id="dialog1"
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :style="{ height: height_chart }">
          <div ref="ipoDialogRef" style="width: 100%; height: 100%"></div>
          <div
            class="Yield_Style"
            v-if="
              factor1_graphic_type_value == 'scatter' && factor2_graphic_type_value == 'scatter'
            ">
            {{ factor2.label }}
          </div>
          <div
            class="Volatility_Style"
            v-if="
              factor1_graphic_type_value == 'scatter' && factor2_graphic_type_value == 'scatter'
            ">
            {{ factor1.label }}
          </div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CButtonsDownload from '@/components/Basic/CButtons_download';
import CCascader from '@/components/Basic/CCascader';
import CSelect from '@/components/Basic/CSelect';
import CCategoryButtonNew from '@/components/Basic/CCategoryButtonNew';
import CDialog from '@/components/Basic/CDialog';

import { fnDownloadBgc, fnGetLevelName } from '../../utils/util';

import html2canvas from 'html2canvas';

export default {
  name: 'Valuation',
  components: { CButtonsDownload, CCascader, CSelect, CCategoryButtonNew, CDialog },
  data() {
    return {
      isVip: false,
      //放大
      dialogVisible: false,
      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '72vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',

      OFFSETHEIGHT: 0,
      //下载
      json_fields_column7: {
        类别1: 'category_1',
        类别2: 'category_2',
        名称: 'name',
        股票代码: 'reit_code',
        指标1: 'factor1',
        指标2: 'factor2',
        气泡指标: 'bubble',
      },

      presets_list: [],
      presets_default: '',
      colors: {},
      //汇总数据
      data_ori: [],

      //都为柱状图的时候
      bar_list: [
        {
          value: '并排',
          label: '并排',
        },
        {
          value: '堆积',
          label: '堆积',
        },
        {
          value: '重叠',
          label: '重叠',
        },
      ],
      bar_default: '并排',
      //指标1图形类型,柱状、折线、散点
      factor1_graphic_types: [
        {
          value: 'bar',
          label: '柱状',
        },
        {
          value: 'line',
          label: '折线',
        },
        {
          value: 'scatter',
          label: '散点',
        },
      ],
      factor1_graphic_type_value: 'bar',
      //指标2图形类型,柱状、折线、散点
      factor2_graphic_types: [
        {
          value: 'bar',
          label: '柱状',
        },
        {
          value: 'line',
          label: '折线',
        },
        {
          value: 'scatter',
          label: '散点',
        },
      ],
      factor2_graphic_type_value: 'bar',
      //选择大类或者个股
      by_type_list: [
        {
          value: 'type',
          label: '类别',
          title: '类别',
        },
        {
          value: 'single',
          label: '个股',
          title: '个股',
        },
      ],
      by_type: '',
      by_type_value: '',
      //选择排序
      sortby_list: [
        {
          value: 'factor1',
          label: '指标1',
        },
        {
          value: 'factor2',
          label: '指标2',
        },
        {
          value: 'category',
          label: '类别',
        },
      ],
      sortby: 'factor1',
      //这个是个股
      CODENAMEDATA: [],
      CODENAMEDATA_values: [],
      //这个是大类
      category_cascader_val_chart_type_r: [
        '产权类',
        '物流仓储',
        '产业园',
        '保障性住房',
        '消费',
        '经营权类',
        '市政生态',
        '能源',
        '收费公路',
        '公募REITs',
      ],
      category_cascader_type_r: [
        {
          value: '产权类',
          label: '产权类',
          children: [
            {
              value: '物流仓储',
              label: '物流仓储',
            },
            {
              value: '产业园',
              label: '产业园',
            },
            {
              value: '保障性住房',
              label: '保障性住房',
            },
            {
              value: '消费',
              label: '消费',
            },
          ],
        },
        {
          value: '经营权类',
          label: '经营权类',
          children: [
            {
              value: '市政生态',
              label: '市政生态',
            },
            {
              value: '能源',
              label: '能源',
            },
            {
              value: '收费公路',
              label: '收费公路',
            },
          ],
        },
        {
          value: '公募REITs',
          label: '公募REITs',
        },
      ],

      echart_data: {},
      ROOTFONTFIZE: '',
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      factor1_name_list: [],
      factor2_name_list: [],
      factor1_default: 'Irr_seg_g2',
      factor2_default: 'Irr_single',
      factor1: {},
      factor2: {},
      bubble_name_list: '',
      bubble: {},
      bubble_name: '',
      chartColumnValuationAnalyzer: null,
      chartColumnValuationAnalyzer_data_x: [],
      chartColumnValuationAnalyzer_data_y_1: [],
      chartColumnValuationAnalyzer_data_y_2: [],
      chartColumnValuationAnalyzer_data_r: [],
      loading_valuation_analyzer: false,
      dButtonsDownloads2: [
        {
          id: '241053',
          name: '下载图片',
        },
        {
          id: '241049',
          name: '下载表格',
        },
      ],
      activeName: '',
    };
  },
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      this.$nextTick(() => {
        if (this.activeName != 'sixth') {
          return;
        }
        this.renderEchart();
      });
    },
  },
  created() {},
  mounted() {
    console.log('valuation mounted');
    // this.getPresets();
    // this.getfactor();
    this.fnCodeName();
    this.resize();
    window.addEventListener('resize', this.resize);
  },
  methods: {
    async handleClick(e) {
      console.log('valuation handleClick', e);

      this.activeName = e.name;
      localStorage.setItem('activeName', this.activeName);
      let activeName = e.name;
      console.log('handleClick', activeName);

      if (activeName == 'sixth') {
        this.isVip = fnGetLevelName() == 'VIP客户' ? true : false;
        this.$eventBus.$emit('setBreadcrumbs', 'IRR/NPV走势');
        if (!this.isVip) return;

        setTimeout(() => {
          this.renderEchart();
        }, 0);
      }
    },

    //放大start
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.renderEchart(true);
      });
    },
    fnDropdown(val) {
      let conf = this.$store.state.popUpWindowConfi[val];
      this.width_dialog = conf.width_dialog;
      this.top_dialog = conf.top_dialog;
      this.height_dialog = conf.height_dialog;
      this.popUpWindowIndex = val;
      this.height_chart = conf.height_chart;
      this.$nextTick(() => {
        this.renderEchart(true);
      });
    },
    //放大end
    fnCCascader_select_presets(val) {
      this.presets_default = val;
      //清空
      this.factor1_default = '';
      this.factor2_default = '';
      this.factor1_graphic_type_value = '';
      this.factor2_graphic_type_value = '';
      this.bar_default = '';
      this.bubble_name = '';
      var new_preset = {};
      this.presets_list.forEach((item) => {
        if (val == item.title) {
          new_preset = item;
        }
      });
      this.factor1_default = new_preset['factor1'];
      this.factor1_name_list.forEach((item) => {
        if (item.value == this.factor1_default) {
          this.factor1 = item;
        }
      });
      this.factor2_default = new_preset['factor2'];
      this.factor2_name_list.forEach((item) => {
        if (item.value == this.factor2_default) {
          this.factor2 = item;
        }
      });
      this.factor1_graphic_type_value = new_preset['factor1_pic'];
      this.factor2_graphic_type_value = new_preset['factor2_pic'];
      if (new_preset['bar_mode'] == 'duplicate') {
        this.bar_default = '重叠';
      }
      if (new_preset['bar_mode'] == 'relative') {
        this.bar_default = '并排';
      }
      if (new_preset['bar_mode'] == 'stack') {
        this.bar_default = '堆积';
      }
      this.bubble_name = new_preset['bubble'];
      this.bubble_name_list.forEach((item) => {
        if (item.value == this.bubble_name) {
          this.bubble = item;
        }
      });
      this.by_type_value = new_preset['by_type'];
      this.by_type_list.forEach((item) => {
        if (item.value == this.by_type_value) {
          this.by_type = item.title;
        }
      });
      this.sortby = new_preset['sortby'];
      this.$nextTick(() => {
        this.fnGetValuationAnalyzer();
      });
    },
    //预设包接口
    async getPresets() {
      const { data } = await this.$https.get('/api/v2/conghua/configIrrNpv/list');

      this.presets_list = [];
      data.forEach((item) => {
        this.presets_list.push({
          label: item.title,
          value: item.title,
          ...item,
        });
      });
      this.presets_default = this.presets_list[0].value;
      //默认
      var new_preset = this.presets_list[0];
      this.factor1_default = new_preset['factor1'];
      this.factor1_name_list.forEach((item) => {
        if (item.value == this.factor1_default) {
          this.factor1 = item;
        }
      });
      this.factor2_default = new_preset['factor2'];
      this.factor2_name_list.forEach((item) => {
        if (item.value == this.factor2_default) {
          this.factor2 = item;
        }
      });
      this.factor1_graphic_type_value = new_preset['factor1_pic'];
      this.factor2_graphic_type_value = new_preset['factor2_pic'];
      if (new_preset['bar_mode'] == 'duplicate') {
        this.bar_default = '重叠';
      }
      if (new_preset['bar_mode'] == 'relative') {
        this.bar_default = '并排';
      }
      if (new_preset['bar_mode'] == 'stack') {
        this.bar_default = '堆积';
      }
      this.bubble_name = new_preset['bubble'];
      if (this.bubble_name_list && this.bubble_name_list.length > 0) {
        this.bubble_name_list.forEach((item) => {
          if (item.value == this.bubble_name) {
            this.bubble = item;
          }
        });
      }
      this.by_type_value = new_preset['by_type'];
      this.by_type_list.forEach((item) => {
        if (item.value == this.by_type_value) {
          this.by_type = item.title;
        }
      });
      this.sortby = new_preset['sortby'];
    },
    async getfactor() {
      const { data } = await this.$https.get('/api/v2/conghua/valAnalysisAssumption/list');
      var list = data.list;
      var bubbles = data.bubbles;
      this.factor1_name_list = [];
      this.bubble_name_list = [];
      list.forEach((item) => {
        this.factor1_name_list.push({
          label: item.cn_name,
          label_format: item.label_format,
          value: item.metrics,
        });
      });
      this.factor2_name_list = this.factor1_name_list;
      bubbles.forEach((item) => {
        this.bubble_name_list.push({
          label: item.cn_name,
          label_format: item.label_format,
          value: item.metrics,
        });
      });
      this.factor1 = this.factor1_name_list[0];
      // this.factor1_default = this.factor1.value;
      this.factor2 = this.factor2_name_list[1];
      // this.factor2_default = this.factor2.value;
    },
    fnCCascader_select_val_sortby(val) {
      this.sortby = val;
      this.fnGetValuationAnalyzer();
    },
    fnCCascader_select_val_by_type(val) {
      this.by_type_list.forEach((item) => {
        if (item.title == val) {
          this.by_type = item.title;
          this.by_type_value = item.value;
        }
      });
      this.CODENAMEDATA_values = [];
      this.$refs.CCascader2_chart1.value = [];
      this.$refs.CCascader_chart1.value = this.$store.state.IncomeAnalysisCasCaderData;
      this.category_cascader_val_chart_type_r = [
        '产权类',
        '物流仓储',
        '产业园',
        '保障性住房',
        '消费',
        '经营权类',
        '市政生态',
        '能源',
        '收费公路',
        '公募REITs',
      ];
      this.fnGetValuationAnalyzer();
    },
    change_type_scatter(val) {
      this.bubble_name = val;
      this.bubble_name_list.forEach((item) => {
        if (item.value == val) {
          this.bubble = item;
        }
      });
      this.fnGetValuationAnalyzer();
    },
    change_type_bar(val) {
      this.bar_default = val;
      this.fnGetValuationAnalyzer();
    },
    change_factor1_graphic_type_val(val) {
      if (
        this.factor1.label_format != this.factor2.label_format &&
        val == 'bar' &&
        this.factor2_graphic_type_value == 'bar'
      ) {
        this.$message.error('此指标1与指标2下不可同时选择柱状图');
        return;
      }
      this.factor1_graphic_type_value = val;
      this.fnGetValuationAnalyzer();
    },
    change_factor2_graphic_type_val(val) {
      if (
        this.factor1.label_format != this.factor2.label_format &&
        val == 'bar' &&
        this.factor1_graphic_type_value == 'bar'
      ) {
        this.$message.error('此指标1与指标2下不可同时选择柱状图');
        return;
      }
      this.factor2_graphic_type_value = val;
      this.fnGetValuationAnalyzer();
    },
    change_factor1(val) {
      this.factor1_default = val;
      this.factor1_name_list.forEach((item) => {
        if (item.value == val) {
          this.factor1 = item;
        }
      });
      if (
        this.factor1.label_format != this.factor2.label_format &&
        this.factor2_graphic_type_value == 'bar' &&
        this.factor1_graphic_type_value == 'bar'
      ) {
        this.$message.error('此指标1与指标2下不可同时选择柱状图');
        return;
      }
      this.fnGetValuationAnalyzer();
    },
    change_factor2(val) {
      this.factor2_default = val;
      this.factor2_name_list.forEach((item) => {
        if (item.value == val) {
          this.factor2 = item;
        }
      });
      if (
        this.factor1.label_format != this.factor2.label_format &&
        this.factor2_graphic_type_value == 'bar' &&
        this.factor1_graphic_type_value == 'bar'
      ) {
        this.$message.error('此指标1与指标2下不可同时选择柱状图');
        return;
      }
      this.fnGetValuationAnalyzer();
    },
    // 初始化codename
    fnCodeName() {
      // 获取对比reits
      let REITsNAME = JSON.parse(localStorage.getItem('REITsNAME'));
      this.CODENAMEDATA = REITsNAME;
    },
    downloadImg() {
      let h = this.$refs.chartColumnValuationAnalyzer.scrollHeight;
      let w = this.$refs.chartColumnValuationAnalyzer.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumnValuationAnalyzer, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '估值分析器'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    // 下载 start
    fnButtonDL7_1(val) {
      if (val == '下载图片') {
        this.FnDownload7_1();
      } else {
        setTimeout(() => {
          document.getElementById('json_fields_column7').click();
        }, 0);
      }
    },
    FnDownload7_1() {
      let h = this.$refs.table7_1.scrollHeight;
      let w = this.$refs.table7_1.scrollWidth;
      let bgColor = fnDownloadBgc(this.sCurrentTheme);
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.table7_1, {
        async: true,
        height: h + 20,
        windowHeight: h + 20,
        width: w,
        backgroundColor: bgColor,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '估值分析器'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    fnSelect_select_val_chart_select_codes(val) {
      val = val.filter((node) => {
        return node.includes('.');
      });

      this.CODENAMEDATA_values = val;
      this.fnGetValuationAnalyzer();
    },
    fnCCascader_select_val_chart_type_r(val) {
      this.category_cascader_val_chart_type_r = val;
      this.fnGetValuationAnalyzer();
    },
    downloadHead() {
      this.json_fields_column7 = {};
      this.json_fields_column7['名称'] = 'name';
      this.json_fields_column7[this.factor1.label] = 'factor1';
      this.json_fields_column7[this.factor2.label] = 'factor2';
      if (this.by_type_value != 'type') {
        this.json_fields_column7['类别1'] = 'category_1';
        this.json_fields_column7['类别2'] = 'category_2';
        this.json_fields_column7['股票代码'] = 'reit_code';
      }
      if (
        this.factor1_graphic_type_value == 'scatter' &&
        this.factor2_graphic_type_value == 'scatter'
      ) {
        this.json_fields_column7[this.bubble.label] = 'bubble';
      }
    },
    async fnGetValuationAnalyzer() {
      this.loading_valuation_analyzer = true;

      // let codes = ['180101.SZ'];
      let codes = this.CODENAMEDATA_values;
      let str0 = codes.join('&codes=');
      let r_type = this.category_cascader_val_chart_type_r;
      let str = r_type.join('&r_type=');
      let factor1 = this.factor1.value; //pct带百分比,mil要除以百万
      let factor2 = this.factor2.value; //pct带百分比,mil要除以百万
      if (!factor1 || !factor2) {
        return;
      }
      let bubble = ''; //pct带百分比,mil要除以百万
      if (
        this.factor1_graphic_type_value == 'scatter' &&
        this.factor2_graphic_type_value == 'scatter'
      ) {
        bubble = this.bubble_name;
      }

      let by_type = this.by_type_value;
      let sortby = this.sortby;

      const { data } = await this.$https.get(
        '/api/v2/valuation_spot_data?r_type=' +
          str +
          '&codes=' +
          str0 +
          '&factor1=' +
          factor1 +
          '&factor2=' +
          factor2 +
          '&bubble=' +
          bubble +
          '&by_type=' +
          by_type +
          '&sortby=' +
          sortby
      );

      if (data.code !== 200) return this.$message.error(data.msg);
      this.chartColumnValuationAnalyzer_data_x = [];
      this.chartColumnValuationAnalyzer_data_y_1 = []; //指标1数值
      this.chartColumnValuationAnalyzer_data_y_2 = []; //指标2数值
      this.chartColumnValuationAnalyzer_data_r = []; //bubble数值
      if (data.data.length == 0) {
        this.loading_valuation_analyzer = false;
        this.chartColumnValuationAnalyzer.clear();
        return;
      }

      this.echart_data = data.data;

      //先写柱状图
      var factor1_data = data.data.factor1;
      var factor2_data = data.data.factor2;
      var bubble_data = data.data.bubble;

      factor1_data.forEach((item) => {
        if (item.index) {
          item['category_1'] = item.index;
          item['category_2'] = item.index;
          item['name'] = item.index;
          item['reit_code'] = item.index;
        }
      });
      factor2_data.forEach((item) => {
        if (item.index) {
          item['category_1'] = item.index;
          item['category_2'] = item.index;
          item['name'] = item.index;
          item['reit_code'] = item.index;
        }
      });
      if (bubble_data == '') {
        bubble_data = [];
      }
      bubble_data.forEach((item) => {
        if (item.index) {
          item['category_1'] = item.index;
          item['category_2'] = item.index;
          item['name'] = item.index;
          item['reit_code'] = item.index;
        }
      });

      //指标1指标2指标3数据合一
      var list = {};
      if (factor1_data) {
        factor1_data.forEach((item) => {
          list[item.name] = item;
        });
      }
      if (factor2_data) {
        factor2_data.forEach((item) => {
          if (list[item.name]) {
            list[item.name]['factor2'] = item.factor2;
          }
        });
      }
      if (bubble_data) {
        bubble_data.forEach((item) => {
          if (list[item.name]) {
            list[item.name]['bubble'] = item.bubble;
          }
        });
      }
      var new_list = Object.values(list);
      this.data_ori = new_list;

      //这里处理下表头
      this.downloadHead();

      new_list.forEach((item) => {
        this.chartColumnValuationAnalyzer_data_x.push(item.name);
        this.chartColumnValuationAnalyzer_data_y_1.push(item.factor1);
        this.chartColumnValuationAnalyzer_data_y_2.push(item.factor2);
        this.chartColumnValuationAnalyzer_data_r.push(item.bubble);
      });

      //画图
      this.$nextTick(() => {
        this.renderEchart();
      });
    },
    initChartValuationAnalyzer_1() {
      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;
      let option = {
        grid: {
          left: this.ROOTFONTFIZE * (70 / 144),
          top: this.ROOTFONTFIZE * (80 / 144),
          right: this.ROOTFONTFIZE * (130 / 144),
          bottom: this.ROOTFONTFIZE * (70 / 144),
          containLabel: true,
        },
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: (args) => {
            let str = '';
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let val = node.value;
              var format = '';
              if (this.factor1.label == node.seriesName) {
                format = this.factor1.label_format;
              }
              if (this.factor2.label == node.seriesName) {
                format = this.factor2.label_format;
              }
              if (format == 'pct') {
                val = (val * 100).toFixed(1) + '%';
              }
              if (format == 'mil') {
                val = (Number(val) / 1000000).toFixed(2) + '百万';
              }

              str += '<br>';
              str += `${node.marker}${node.seriesName}：${val}`;

              return str;
            });
            return str;
          },
        },
        legend: {
          show: true,
          orient: 'horizontal',
          top: this.ROOTFONTFIZE * (38 / 144),
          left: this.ROOTFONTFIZE * (180 / 144),
          align: 'left',
          icon: 'roundRect',
          itemGap: this.ROOTFONTFIZE * (20 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          itemWidth: this.ROOTFONTFIZE * (10 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: OPTIONDATA.legend.textStyle.fontSize,
          },
          data: [
            {
              name: this.factor1['label'],
              icon: 'rect',
            },
            {
              name: this.factor2['label'],
              icon: 'rect',
            },
          ],
        },
        xAxis: [
          {
            type: 'category',
            offset: this.ROOTFONTFIZE * (20 / 144),
            data: this.chartColumnValuationAnalyzer_data_x,
            boundaryGap: true,
            axisLine: {
              show: true,
              onZero: true, //轴线是否在0刻度轴上
            },

            axisTick: {
              show: true,
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              rotate: this.ROOTFONTFIZE * (12 / 144),
              margin: this.ROOTFONTFIZE * (10 / 144),
              padding: [0, this.ROOTFONTFIZE * (-30 / 144), 0, 0],
              show: true,
              rotate: 40,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
          },
        ],
        yAxis: [
          {
            // name: '',
            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            scale: false,
            // type: 'value',
            axisLine: {
              // show: false,
              onZero: true,
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,

              formatter: (args) => {
                var format = (format = this.factor1.label_format);
                if (format == 'pct') {
                  args = (args * 100).toFixed(1) + '%';
                } else if (format == 'mil') {
                  args = (Number(args) / 1000000).toFixed(2) + '百万';
                }
                return args;
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
        ],
        series: [
          {
            // name: this.factor1['label'],
            type: this.factor1_graphic_type_value,
            itemStyle: {
              color: '#5ae8ed',
              // color: 'rgba(90,232,237,0.5)',
              borderColor: OPTIONDATA.title.textStyle.color,
              borderWidth: 1,
              // opacity: 0.8,
            },
            z: 3,
            data: this.chartColumnValuationAnalyzer_data_y_1,
          },
          {
            // name: this.factor2['label'],
            type: this.factor2_graphic_type_value,
            itemStyle: {
              color: '#a1a1a1',
              // color: 'rgba(73,73,73,0.5)',
              borderColor: OPTIONDATA.title.textStyle.color,
              borderWidth: 1,
            },
            z: 2,
            data: this.chartColumnValuationAnalyzer_data_y_2,
          },
        ],
      };
      if (this.factor1.label_format != this.factor2.label_format) {
        option.yAxis.push({
          // name: '',
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          scale: false,
          // type: 'value',
          axisLine: {
            // show: false,
            onZero: true,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,

            formatter: (args) => {
              var format = (format = this.factor2.label_format);
              if (format == 'pct') {
                args = (args * 100).toFixed(1) + '%';
              } else if (format == 'mil') {
                args = (Number(args) / 1000000).toFixed(2) + '百万';
              }
              return args;
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        });
        option.series[1]['yAxisIndex'] = 1;
      }

      option.series[0]['name'] = this.factor1['label'];
      option.series[1]['name'] = this.factor2['label'];

      if (this.bar_default == '堆积') {
        option.series.forEach((item) => {
          item['stack'] = 'all';
        });
      }
      if (this.bar_default == '重叠') {
        option.series[1]['barGap'] = '-100%';
        option.series[0]['itemStyle']['color'] = 'rgba(90,232,237,0.8)';
        option.series[1]['itemStyle']['color'] = 'rgba(161,161,161,0.8)';
      }
      if (this.popUpWindowIndex == 1) {
        option.xAxis[0].axisLabel.fontSize = this.ROOTFONTFIZE * (12 / 144);
        option.xAxis[0].axisLabel.rotate = 60;
      } else {
        option.xAxis[0].axisLabel.fontSize = this.ROOTFONTFIZE * (14 / 144);
        option.xAxis[0].axisLabel.rotate = 40;
      }
      return option;
    },
    initChartValuationAnalyzer_2() {
      const colors = this.$store.state.colors || [];
      this.colors = colors.reduce((p, c) => {
        p[c.name] = c.value;
        return p;
      }, {});
      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let sum = 0;
      this.data_ori.forEach((item) => {
        sum += Number(item['bubble']);
      });
      let avg = sum / this.data_ori.length;
      let seriesArr = [];
      this.data_ori.forEach((item) => {
        var sclae = 1;
        var eff = item['bubble'] - avg;
        if (eff > 0) {
          sclae = item['bubble'] / avg;
        }
        if (sclae >= 2) {
          sclae = 2;
        }
        seriesArr.push({
          name: item['category_2'],
          data: [
            { value: [item['factor1'], item['factor2']], name: item.name, name2: item['bubble'] },
          ],
          type: 'scatter',
          symbolSize: sclae * 20,
          cursor: 'pointer',
          itemStyle: {
            normal: {
              color: this.colors[item.category_2],
              borderColor: '#fff',
              borderWidth: 1,
            },
          },
          label: {
            show: true,
            position: 'top',
            fontSize: this.ROOTFONTFIZE * (12 / 144),
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
            },
            formatter: () => {
              return item.name;
            },
          },
        });
      });

      let option = {
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },
        legend: {
          type: 'plain',
          left: this.ROOTFONTFIZE * (35 / 144),
          top: this.ROOTFONTFIZE * (10 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
        },
        grid: {
          left: this.ROOTFONTFIZE * (70 / 144),
          top: this.ROOTFONTFIZE * (80 / 144),
          right: this.ROOTFONTFIZE * (130 / 144),
          bottom: this.ROOTFONTFIZE * (70 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: (args) => {
            let str = '';
            args.forEach((node) => {
              // console.log("股票名称："+node.data.name);
              str += `${node.data.name}`;
              var i = 0;
              node.data.value.forEach((s_value) => {
                i++;
                let val = s_value;
                var format = this['factor' + i].label_format;
                if (format == 'pct') {
                  val = (val * 100).toFixed(1) + '%';
                }
                if (format == 'mil') {
                  val = (Number(val) / 1000000).toFixed(2) + '百万';
                }

                str += '<br>';
                str += `${node.marker}${this['factor' + i].label}：${val}`;
              });
              str += '<br>';
              if (node.data.name2) {
                let val = node.data.name2;
                var format = this.bubble.label_format;
                if (format == 'pct') {
                  val = (val * 100).toFixed(1) + '%';
                }
                if (format == 'mil') {
                  val = (Number(val) / 1000000).toFixed(2) + '百万';
                }
                if (format != 'pct' && format != 'mil') {
                  val = Number(val).toFixed(4);
                }
                str += `${node.marker}${this.bubble.label}：${val}`;
              }
              str += '<br>';
            });

            return str;
          },
        },
        xAxis: {
          type: 'value',
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#dcdcdd',
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: (val) => {
              var format = this.factor1.label_format;
              if (format == 'pct') {
                val = (val * 100).toFixed(2) + '%';
              } else if (format == 'mil') {
                val = (Number(val) / 1000000).toFixed(2) + '百万';
              }
              return val;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: this.sCurrentTheme ? '#48494c' : '#dcdcdd',
            },
          },
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#dcdcdd',
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: (val) => {
              var format = this.factor2.label_format;
              if (format == 'pct') {
                val = (val * 100).toFixed(2) + '%';
              } else if (format == 'mil') {
                val = (Number(val) / 1000000).toFixed(2) + '百万';
              }
              return val;
            },
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
        series: seriesArr,
      };
      return option;
    },
    async renderEchart(flag) {
      if (flag) {
        if (!this.echart_dialog_instance) {
          this.echart_dialog_instance = this.$echarts.init(this.$refs.ipoDialogRef);
        }
        this.echart_dialog_instance.clear();
      }
      if (!Object.keys(this.echart_data).length) {
        await this.getfactor();
        await this.getPresets();
        await this.fnGetValuationAnalyzer();
        return;
      }
      if (!this.echart_instance) {
        this.echart_instance = this.$echarts.init(this.$refs.chartColumnValuationAnalyzer);
      }
      this.echart_instance.clear();

      //todo 不同情况调用不同函数返回options
      var options = {};
      if (
        this.factor1_graphic_type_value != 'scatter' ||
        this.factor2_graphic_type_value != 'scatter'
      ) {
        options = this.initChartValuationAnalyzer_1();
      } else {
        options = this.initChartValuationAnalyzer_2();
      }
      if (flag) {
        this.echart_dialog_instance.setOption(options);
        this.echart_dialog_instance.resize();
      }
      this.echart_instance.setOption(options);
      this.echart_instance.resize();
    },
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
      let OFFSETHEIGHT = element.offsetHeight;
      this.OFFSETHEIGHT = OFFSETHEIGHT;
    },
    resize() {
      this.fnGetWidth();
      this.echart_instance && this.echart_instance.resize();
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    // loadMsg() {
    //   this.isVip = fnGetLevelName() == 'VIP客户' ? true : false;
    //   if (!this.isVip) return;
    //   setTimeout(() => {
    //     this.renderEchart();
    //   }, 0);
    // },
  },
  beforeDestroy() {
    this.echart_instance && this.echart_instance.dispose();
    this.echart_dialog_instance && this.echart_dialog_instance.dispose();
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style scoped lang="less">
.icon-fangda2 {
  right: 86px;
}

.CButtonsSeparate_style {
  // margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-left: 20px;
}

.CH_Card_content {
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
}

.card_tit {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  /* border-left: 5px solid rgba(21, 115, 254, 1); */
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  display: flex;
  justify-content: space-between;
}

.Yield_Style {
  color: var(--legndAreaItem_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  position: absolute;
  top: 50%;
  left: calc(var(--ROOTFONTFIZE) * (30 / 144));
  transform: translateY(-50%);
  letter-spacing: 0.3em;
  writing-mode: vertical-rl;
}

.Volatility_Style {
  color: var(--legndAreaItem_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  position: absolute;
  left: 50%;
  bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  letter-spacing: 0.3em;
  transform: translateX(-50%);
}

.noVip {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  letter-spacing: calc(var(--ROOTFONTFIZE) * (2 / 144));
  color: #78787a;
  color: #bababb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--ROOTFONTFIZE) * (500 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: #373a42;
  margin: calc(var(--ROOTFONTFIZE) * (20 / 144)) calc(var(--ROOTFONTFIZE) * (30 / 144));
  border: 1px solid #333335;
}

.icon-taocan-suoxiao {
  font-size: calc(var(--ROOTFONTFIZE) * (50 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.openBtn {
  background-color: var(--primary-color) !important;
  color: #333333 !important;
  border: none !important;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.openBtn:hover {
  background-color: var(--primary-color) !important;
  color: #333333 !important;
  border: none !important;
  background-size: contain;
}
</style>
