<template>
  <div class="card">
    <div class="chart_title">日内走势表现</div>
    <div class="content_case">
      <div class="left_case">
        <div class="con_1">
          <div class="iconfont icon-fangda" @click="show_chart_dialog = !show_chart_dialog"></div>
          <!-- <div class="news_case">
            899050[北证50] <span class="c1">15:00</span> 价 <span class="c2">1024.26</span> 涨跌
            <span class="c2">-29.27(-2.78%)</span> 成交金额 2.56亿
          </div> -->
          <!-- <div class="time">2025/01/10</div> -->
        </div>
        <div
          id="echart_market"
          ref="echart_market"
          v-loading="loading"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="width: 100%; height: 100%"></div>
      </div>
      <div class="right_case">
        <div class="internal_top">
          <div class="current_price">
            <div
              v-if="timesharing.length > 0"
              :class="timesharing[0]['price'] >= pre_close ? '_rise' : '_decline'">
              {{ timesharing[0]['price'] }}
            </div>
            <div
              v-if="timesharing.length > 0"
              class="sm"
              :class="timesharing[0]['price'] >= pre_close ? '_rise' : '_decline'">
              {{
                (timesharing[0]['price'] >= pre_close ? '+' : '') +
                (timesharing[0]['price'] - pre_close).toFixed(2)
              }}
            </div>
            <div
              v-if="timesharing.length > 0"
              class="sm"
              :class="timesharing[0]['price'] >= pre_close ? '_rise' : '_decline'">
              {{
                (timesharing[0]['price'] >= pre_close ? '+' : '') +
                (((timesharing[0]['price'] - pre_close) * 100) / pre_close).toFixed(2) +
                '%'
              }}
            </div>
          </div>
          <div class="_t">
            中证REITs全收益
            <br />
            <span>932047</span>
          </div>
        </div>
        <div class="internal_case">
          <div class="capital_flow">
            <div class="chart_title">分时成交</div>
            <div class="t2_t">
              <div class="_t">时间</div>
              <div class="_t">价格</div>
              <div class="_t1">成交量</div>
            </div>
            <div class="t2_detail">
              <div class="one" v-for="(item, index) in timesharing" :key="'timesharing_' + index">
                <div class="_t">{{ item.time }}</div>
                <div class="_t" :class="item.price >= pre_close ? '_rise' : '_decline'">
                  {{ item.price }}
                </div>
                <div class="_t1" :class="item.way == 2 ? '_rise' : '_decline'">
                  {{ item.volume }}
                  {{ item.way == 2 ? '↑' : '↓' }}
                </div>
              </div>
            </div>
          </div>
          <div class="income">
            <div class="valuation">
              <div class="chart_title">估值</div>
              <div class="c_list">
                <div class="one">
                  <div class="name">PB</div>
                  <div class="value">{{ valuation ? valuation.PB_ttm : '' }}</div>
                </div>
                <div class="one">
                  <div class="name">PNAV</div>
                  <div class="value">{{ valuation ? valuation.PNAV_ttm : '' }}</div>
                </div>
                <div class="one">
                  <div class="name">PFFO ttm</div>
                  <div class="value">{{ valuation ? valuation.PFFO_ttm : '' }}</div>
                </div>
                <div class="one">
                  <div class="name">PCFO ttm</div>
                  <div class="value">{{ valuation ? valuation.PCFO_ttm : '' }}</div>
                </div>
                <div class="one">
                  <div class="name">分派率 ttm</div>
                  <div class="value">
                    {{ valuation ? (valuation.div_yield_ttm * 100).toFixed(2) + '%' : '' }}
                  </div>
                </div>
                <div class="one">
                  <div class="name">产权分派率 ttm</div>
                  <div class="value">
                    {{ valuation ? (valuation.prop_div_yield_ttm * 100).toFixed(2) + '%' : '' }}
                  </div>
                </div>
              </div>
              <!-- <div class="table_area table_area1">
                <table>
                  <tr class="table_main">
                    <td>PB</td>
                    <td>{{ valuation ? valuation.PB_ttm : '' }}</td>
                  </tr>
                  <tr class="table_main">
                    <td>PNAV</td>
                    <td>{{ valuation ? valuation.PNAV_ttm : '' }}</td>
                  </tr>
                  <tr class="table_main">
                    <td>PFFO ttm</td>
                    <td>{{ valuation ? valuation.PFFO_ttm : '' }}</td>
                  </tr>
                  <tr class="table_main">
                    <td>PCFO ttm</td>
                    <td>{{ valuation ? valuation.PCFO_ttm : '' }}</td>
                  </tr>
                  <tr class="table_main">
                    <td>分派率 ttm</td>
                    <td>{{ valuation ? (valuation.div_yield_ttm * 100).toFixed(2) + '%' : '' }}</td>
                  </tr>
                  <tr class="table_main">
                    <td>产权分派率 ttm</td>
                    <td>
                      {{ valuation ? (valuation.prop_div_yield_ttm * 100).toFixed(2) + '%' : '' }}
                    </td>
                  </tr>
                </table>
              </div> -->
            </div>
            <div class="chg">
              <div class="chart_title">涨跌幅</div>
              <div class="c_list">
                <div
                  class="one"
                  @click="fnClick(item)"
                  v-for="item in top_list"
                  :key="'tops_' + item.REITs_name">
                  <div class="name">{{ item.REITs_name }}</div>
                  <div class="value up">{{ item.close }}</div>
                  <div class="pct up">{{ (item.pct * 100).toFixed(2) + '%' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChartDialog :show.sync="show_chart_dialog" :echart_options="chart_options" />
  </div>
</template>

<script>
import { getData } from './performane_data';
import { fnReturnTableStyle } from '@/utils/util';
import ChartDialog from '../Basic/ChartDialog.vue';

export default {
  name: 'Performance',
  components: { ChartDialog },
  data() {
    return {
      test: '',
      show_chart_dialog: false,
      chart_options: Object.freeze({}),
      time: null,
      timesharing: [],
      zoushi: [],
      pre_close: 0,
      zoushi_X: [],
      zoushi_price: [],
      zoushi_volume: [],
      zoushi_DEA: [],
      zoushi_DIF: [],
      zoushi_MACD: [],
      top_list: [],
      valuation: {
        PB_ttm: '',
        PNAV_ttm: '',
        PFFO_ttm: '',
        PCFO_ttm: '',
        div_yield_ttm: '',
        prop_div_yield_ttm: '',
      },
      category_growth: [],

      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,

      ROOTFONTFIZE: '',
      loading: false,
      echart_market: null,
    };
  },
  methods: {
    fnClick(row) {
      if (row.reit_code) {
        let arr = [row['REITs_name'], row['reit_code']];
        this.$eventBus.$emit('skipPage', arr);
      }
    },
    //板块异动start
    async ZzReitSignal(fun) {
      const { data } = await this.$https.get('/api/v2_2/zz_reit_signal?limit=20');
      if (data.code !== 200) {
        fun && fun();
        return this.$message.error(data.msg);
      }
      this.category_growth = data.data;
      fun && fun();
    },
    //板块异动end
    //估值start
    async fnGetValuationTable() {
      let r_type = this.category_cascader_valuationTable_val;

      let summarize = 1;

      let divn = 0;

      let factor_list = [
        'PFFO',
        'PCFO',
        'PNAV',
        'PB',
        'EV/EBITDA',
        'div_yield_ttm',
        'implied_cap_rate',
        'PAFFO',
      ];
      let str = factor_list.join('&factor_list=');
      // console.clear();
      // console.log(str);
      const qs = new URLSearchParams({
        r_type,
        summarize,
        divn,
        div_mod: 0,
      }).toString();
      const { data } = await this.$https.get('/api/v2_2/zz_reit_valuation');
      if (data.code !== 200) return this.$message.error(data.msg);
      data.data.forEach((item) => {
        if (item.factor_name == 'pb') {
          this.valuation['PB_ttm'] = item.data.toFixed(3);
        }
        if (item.factor_name == 'pnav') {
          this.valuation['PNAV_ttm'] = item.data.toFixed(3);
        }
        if (item.factor_name == 'pffo_ttm') {
          this.valuation['PFFO_ttm'] = item.data.toFixed(3);
        }
        if (item.factor_name == 'pcfo_ttm') {
          this.valuation['PCFO_ttm'] = item.data.toFixed(3);
        }
        if (item.factor_name == 'div_yield_ttm' && item.category == '公募REITs') {
          this.valuation['div_yield_ttm'] = item.data;
        }
        if (item.factor_name == 'div_yield_ttm' && item.category == '产权类') {
          this.valuation['prop_div_yield_ttm'] = item.data;
        }
      });
    },
    //估值end
    //分时走势start
    async fnZz_reit_timesharing() {
      const { data } = await this.$https.get('/api/v2_2/zz_reit_timesharing?limit=' + 40);
      if (data.code !== 200) return this.$message.error(data.msg);
      this.timesharing = data.data.slice(0, 40);
    },
    //分时走势end
    //日走势start
    async fnZz_reit_zoushi() {
      this.zoushi_X = [];
      this.zoushi_price = [];
      this.zoushi_volume = [];
      this.zoushi_DEA = [];
      this.zoushi_DIF = [];
      this.zoushi_MACD = [];
      const { data } = await this.$https.get('/api/v2_2/zz_reit_zoushi');
      if (data.code !== 200) return this.$message.error(data.msg);
      if (data.data.markets.length <= 0) {
        return;
      }
      this.pre_close = data.data.markets[0]['pre_close'];
      this.zoushi = data.data.markets;
      this.zoushi.forEach((item) => {
        this.zoushi_price.push({
          value: item.price,
        });
        this.zoushi_volume.push({
          value: item.volume,
          itemStyle: {
            color: item.amount_way == 1 ? 'green' : 'red',
          },
        });
        this.zoushi_DEA.push({
          value: item.DEA,
        });
        this.zoushi_DIF.push({
          value: item.DIF,
        });
        this.zoushi_MACD.push({
          value: item.MACD,
          itemStyle: {
            color: item.MACD < 0 ? 'green' : 'red',
          },
        });
      });
      this.ZzReitSignal(() => {
        this.$nextTick(() => {
          this.initChartMainPerformance();
        });
      });
    },
    //日走势end
    //getTop10 start
    async get_top10_pct() {
      // this.top_list = [];
      const { data } = await this.$https.get('/api/v2_2/get_real_top10_pct');
      if (data.code !== 200) return this.$message.error(data.msg);
      this.top_list = data.data;
    },
    returnMaxMin(list, preClose) {
      var max = 0;
      var min = 10000;
      list.forEach((item) => {
        if (item.value > max) {
          max = item.value;
        }
        if (item.value < min) {
          min = item.value;
        }
      });
      var Y1_max_deff = Math.abs(max - preClose);
      var Y1_min_deff = Math.abs(min - preClose);
      var Y1_max_min_deff = Number((Math.max(Y1_max_deff, Y1_min_deff) / 3).toFixed(2));
      return {
        max: preClose + 3 * Y1_max_min_deff,
        min: preClose - 3 * Y1_max_min_deff,
        deff: Y1_max_min_deff,
      };
    },
    //输入已记载数组,下标,x轴值，间隔值，正负值，获取y轴值
    getYvalue(list, index,x, deff) {
      //自动建立y轴12个位置
      var y_list = [
        this.pre_close + (deff * 1) / 2-deff*1/5,
        this.pre_close + (deff * 2) / 2-deff*1/6,
        this.pre_close + (deff * 3) / 2-deff*1/7,
        this.pre_close + (deff * 4) / 2-deff*1/8,
        this.pre_close + (deff * 5) / 2-deff*1/9,
        this.pre_close + (deff * 6) / 2-deff*1/10,
        this.pre_close - (deff * 6) / 2+deff*1/10,
        this.pre_close - (deff * 5) / 2+deff*1/9,
        this.pre_close - (deff * 4) / 2+deff*1/8,
        this.pre_close - (deff * 3) / 2+deff*1/7,
        this.pre_close - (deff * 2) / 2+deff*1/6,
        this.pre_close - (deff * 1) / 2+deff*1/5,
      ];
      if(index>11){
        index = 0;
      }
      //判断在x轴20分钟内是否有交叉，排除交叉的x轴的indx然后赋值没有被获取的y值
      var cross_list = [];
      list.forEach(item=>{
        var time_deff = this.calculateTimeDifference(x,item.x);
        if(time_deff<=20){
          cross_list.push(item.y);
        }
      });
      let setArray1 = new Set(y_list);
      let uniqueArray = [...setArray1].filter(item => !cross_list.includes(item));
      return uniqueArray[0];
    },
    calculateTimeDifference(time1, time2) {
      const [hours1, minutes1] = time1.split(':').map(Number);
      const [hours2, minutes2] = time2.split(':').map(Number);

      const date1 = new Date(0, 0, 0, hours1, minutes1);
      const date2 = new Date(0, 0, 0, hours2, minutes2);

      const difference = Math.abs(date1 - date2) / (1000 * 60); // 转换为分钟
      return difference;
    },
    initChartMainPerformance() {
      if (!this.echart_market) {
        this.echart_market = this.$echarts.init(this.$refs.echart_market);
      }

      let data_X = this.data_X;
      let data_Y1 = this.zoushi_price;
      let data_Y1_max = this.returnMaxMin(data_Y1, this.pre_close)['max'];
      let data_Y1_min = this.returnMaxMin(data_Y1, this.pre_close)['min'];
      let data_Y1_deff = this.returnMaxMin(data_Y1, this.pre_close)['deff'];

      let data_Y2 = this.zoushi_volume;
      let data_DEA = this.zoushi_DEA;
      let data_DIF = this.zoushi_DIF;
      let data_MACD = this.zoushi_MACD;
      var macd_concat = data_DEA
        .concat(data_DIF)
        .concat(data_MACD)
        .map((item) => item.value);
      let data_MACD_max = Math.max(...macd_concat);
      let data_MACD_min = Math.min(...macd_concat);

      let data_MACD_max_min = Math.max(Math.abs(data_MACD_max), Math.abs(data_MACD_min)).toFixed(2);
      let category_growth = [];
      let all_list = [];
      this.category_growth.forEach((item, index) => {
        var new_price = this.getYvalue(all_list,index,item.time,data_Y1_deff);
        all_list.push({
          x:item.time,
          y:new_price
        });

        category_growth.push({
          name: '异动',
          type: 'scatter',
          data: [{ value: [item.time, item.price], category: item.category, data: item.data }],
          symbolSize: 7,
          itemStyle: {
            color: item.data >= 0 ? 'red' : 'green',
          },
          z: 7,
          markLine: {
            silent: false,
            symbolSize: 10,
            // symbolOffset: [
            //   [0, 0],
            //   [0, 40],
            // ],
            lineStyle: {
              color: item.data >= 0 ? 'red' : 'green',
            },
            emphasis: {
              disabled: true,
            },
            data: [
              [
                {
                  coord: [item.time, item.price],
                  symbol: 'none',
                },
                {
                  coord: [item.time, new_price],
                  symbol: 'none',
                },
              ],
            ],
          },
          markPoint: {
            symbol: 'rect',
            symbolSize: [item.category.length > 5 ? 90 : 58, 30],
            silent: false,
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              fontSize: 12,
            },
            itemStyle: {
              color: item.data >= 0 ? 'red' : 'green',
            },
            data: [
              {
                name: '1',
                coord: [item.time, new_price],
                value:
                  item.category +
                  '\n' +
                  (item.data > 0 ? '+' : '') +
                  (item.data * 100).toFixed(2) +
                  '%',
                symbolOffset: ['50%', 0],
                emphasis: {
                  disabled: true,
                },
              },
            ],
          },
        });
        // if (num > 0) {
        //   positive_num++;
        // }
        // if (num < 0) {
        //   negative_num--;
        // }
      });

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;
      let options = {
        title: [
          {
            show: true,
            text: '成交量',
            right: '91.5%',
            top: '65%',
            textStyle: {
              fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
              margin: OPTIONDATA.xAxis.axisLabel.margin,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
          },
          {
            show: true,
            text: 'MACD',
            right: '91.5%',
            top: '83%',
            textStyle: {
              fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
              margin: OPTIONDATA.xAxis.axisLabel.margin,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
          },
        ],
        toolbox: {
          // 工具箱按钮
          right: 45,
          top: 0,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
            }, // 区域缩放
          },
        },
        dataZoom: [
          {
            type: 'inside', // 这个dataZoom组件是滑动条型
            xAxisIndex: [0, 1, 2], // 对第一个和第二个xAxis进行同步缩放
            start: 0,
            end: 100,
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross', // 十字准心
            snap: true, // 吸附到最近的数据点
            label: {
              show: true, // 显示标签
            },
            lineStyle: {
              type: 'dashed', // 虚线样式
            },
          },
          borderWidth: 1,
          borderColor: '#ccc',
          padding: 10,
          textStyle: {
            color: '#000',
          },
          // position: function (pos, params, el, elRect, size) {
          //   const obj = {
          //     top: 10,
          //   };
          //   obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          //   return obj;
          // },
          // extraCssText: 'width: 170px'
          formatter: (args) => {
            let str = '';
            str += `${args[0].axisValueLabel}`;
            let yd = null;
            args.forEach((node) => {
              let val = node.value;
              if (node.seriesName == '异动') {
                yd = node;
                return;
              }
              if (
                node.seriesName == 'DEA' ||
                node.seriesName == 'DIF' ||
                node.seriesName == 'MACD'
              ) {
                val = val.toFixed(3);
              }
              str += '<br>';
              str += `${node.marker}${node.seriesName}：${val}`;
              return str;
            });
            if (yd) {
              str += '<br>';
              str += `${yd.marker}${yd.data.category}:`;
              str += '<br>';
              str += `半小时${yd.data.data >= 0 ? '领涨' : '领跌'}幅度:${
                yd.data.data >= 0 ? '+' : ''
              }${(yd.data.data * 100).toFixed(2) + '%'}`;
              str += '<br>';
              return str;
            }
            if (this.test) {
              var all = this.test.value;
              var name = all.split('\n')[0];
              var v = all.split('\n')[1];
              str += '<br>';
              str += '<br>';
              str += this.test.data.coord[0];
              str += '<br>';
              str += '半小时内' + (v.indexOf('-') != -1 ? '领跌' : '领涨') + '板块:';
              str += '<br>';
              str += name + ' ' + v;
            }

            return str;
          },
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all',
            },
          ],
          label: {
            backgroundColor: '#777',
          },
        },
        grid: [
          {
            top: this.ROOTFONTFIZE * (50 / 144),
            left: this.ROOTFONTFIZE * (80 / 144),
            right: this.ROOTFONTFIZE * (20 / 144),
            height: '52%',
          },
          {
            top: '70%',
            left: this.ROOTFONTFIZE * (80 / 144),
            right: this.ROOTFONTFIZE * (20 / 144),
            height: '10%',
          },
          {
            top: '88%',
            left: this.ROOTFONTFIZE * (80 / 144),
            right: this.ROOTFONTFIZE * (20 / 144),
            height: '11%',
          },
        ],
        xAxis: [
          {
            type: 'category',
            data: data_X,
            axisLine: {
              onZero: false,
              // lineStyle: {
              //   color: '#fff',
              // },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
              margin: OPTIONDATA.xAxis.axisLabel.margin,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
              formatter: (v) => {
                if (v == '09:30' || v == '15:00') {
                  return v;
                }
                if (v == '11:30') {
                  return '11:30/13:00';
                }
              },
            },
          },
          {
            type: 'category',
            gridIndex: 1,
            data: data_X,
            axisLine: { onZero: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { show: false },
            axisPointer: {
              label: {
                show: false,
              },
            },
          },
          {
            type: 'category',
            gridIndex: 2,
            data: data_X,
            axisLine: { onZero: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { show: false },
            axisPointer: {
              label: {
                show: false,
              },
            },
          },
        ],
        yAxis: [
          {
            // scale: true,
            position: 'left',
            type: 'value',
            axisLine: { show: false },
            // alignTicks:true,
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: (val) => {
                var val = val.toFixed(2);
                return val;
              },
              textStyle: {
                color: (val) => {
                  if (val < this.pre_close) {
                    return `green`;
                  }
                  if (val > this.pre_close) {
                    return `red`;
                  }
                  return OPTIONDATA.yAxis.axisLabel.color;
                },
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: 'gray',
              },
            },
            max: function (value) {
              return value.max + 1;
            },
            min: function (value) {
              return value.min - 1;
            },
            splitNumber: 5,
            max: data_Y1_max,
            min: data_Y1_min,
            interval: data_Y1_deff,
          },
          {
            scale: false,
            gridIndex: 1,
            splitNumber: 2,
            // axisLabel: { show: false },
            min: 0,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: 'gray',
              },
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
            },
          },
          {
            scale: false,
            gridIndex: 2,
            splitNumber: 2,
            // axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: 'gray',
              },
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
            },
            splitNumber: 3,
            // max: 1,
            // min: -1,
            max: data_MACD_max_min,
            min: -data_MACD_max_min,
            interval: data_MACD_max_min / 2,
          },
        ],
        series: [
          {
            name: '价格',
            type: 'line',
            data: data_Y1,
            showSymbol: false,
            lineStyle: {
              color: OPTIONDATA.yAxis.axisLabel.color,
            },
          },
          //其他
          {
            name: '成交量',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: data_Y2,
          },
          {
            name: 'DEA',
            type: 'line',
            xAxisIndex: 2,
            yAxisIndex: 2,
            data: data_DEA,
            color: '#f5b323',
            z: 1,
          },
          {
            name: 'DIF',
            type: 'line',
            xAxisIndex: 2,
            yAxisIndex: 2,
            data: data_DIF,
            color: '#a2a2a2',
            z: 1,
          },
          {
            name: 'MACD',
            type: 'bar',
            xAxisIndex: 2,
            yAxisIndex: 2,
            data: data_MACD,
            z: 2,
            barWidth: '30%',
          },
          //这里是板块异动start
          ...category_growth,
          //这里是板块异动end
        ],
      };
      this.echart_market.setOption(options);
      this.echart_market.resize();
      this.chart_options = Object.freeze(options);
      this.echart_market.on('mousemove', (params) => {
        if (params.componentType == 'markPoint') {
          this.test = params;
        }
      });
      this.echart_market.on('mouseout', (params) => {
        this.test = '';
      });
    },
    pageInit() {
      setTimeout(() => {
        this.resize();
      }, 0);
      if (this._page_init) return;
    },
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
      return;
    },
    resize() {
      this.fnGetWidth();
      this.echart_market && this.echart_market.resize();
    },
    styleBindFun() {
      let val = this.$store.state.theme == 'dark' ? true : false;
      return fnReturnTableStyle(val);
    },
    fnGetTheme() {
      console.log('fnGetTheme index');
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
  },
  mounted() {
    this.resize();
    this.ZzReitSignal();
    this.fnGetValuationTable();
    this.get_top10_pct();
    this.fnZz_reit_timesharing();
    this.fnZz_reit_zoushi();
    clearInterval(this.time);
    this.time = null;
    this.time = setInterval(() => {
      this.ZzReitSignal();
      this.fnGetValuationTable();
      this.get_top10_pct();
      this.fnZz_reit_timesharing();
      this.fnZz_reit_zoushi();
    }, 30000);

    window.addEventListener('resize', this.resize);
    this.data = getData();
    this.data_X = [];
    this.data.data.forEach((item) => {
      this.data_X.push(item.date_str);
    });
  },
  beforeDestroy() {
    this.echart_instance && this.echart_instance.dispose();
    window.removeEventListener('resize', this.resize);
  },
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
      this.fnGetTheme();
      this.$nextTick(() => {
        this.initChartMainPerformance();
      });
    },
  },
};
</script>

<style scoped lang="less">
._rise {
  color: red;
}
._decline {
  color: #1aad19;
}
/deep/ .el-dialog {
  overflow: hidden !important;
}
/deep/ .el-dialog__body {
  overflow: hidden !important;
}
// table start --------------------------------------------------------------------
.table_area {
  padding: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
  overflow-x: auto !important;
  &::-webkit-scrollbar {
    width: calc(var(--ROOTFONTFIZE) * (4 / 144));
    height: calc(var(--ROOTFONTFIZE) * (6 / 144));
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
  }
}
.table_area1 {
  padding: 0 calc(var(--ROOTFONTFIZE) * (0 / 144));
  margin-left: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
}

table {
  border: none;
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  border-bottom: 1px solid var(--table_brc);
  padding-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.table_first {
  border-bottom: calc(var(--ROOTFONTFIZE) * (2 / 144)) solid var(--table_brc) !important;
  border-top: calc(var(--ROOTFONTFIZE) * (2 / 144)) solid var(--table_brc) !important;
  background-color: var(--table_th_bgc) !important;
  td:nth-child(1) {
    background-color: var(--table_th_bgc) !important;
  }
}

th {
  padding: calc(var(--ROOTFONTFIZE) * (5 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
  font-weight: 700;
  letter-spacing: 0.21px;
  line-height: calc(var(--ROOTFONTFIZE) * (15 / 144));
  color: var(--table_color);
  text-align: right;
  width: calc(var(--ROOTFONTFIZE) * (200 / 144));
}
th:nth-child(1) {
  width: calc(var(--ROOTFONTFIZE) * (300 / 144));
  position: sticky;
  left: 0;
  background-color: var(--table_th_bgc) !important;
}

.table_main {
  width: auto;
  background-color: var(--table_bgc);
  padding: 0;
  border-bottom: 1px solid var(--table_brc);
  background-color: transparent !important;
}

tr {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
}
tr:last-child {
  border-bottom: none !important;
}

td {
  width: calc(var(--ROOTFONTFIZE) * (200 / 144));
  text-align: right;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  letter-spacing: 0px;
  color: var(--table_color);
  padding: calc(var(--ROOTFONTFIZE) * (5 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: transparent !important;
}

td:nth-child(1) {
  position: sticky;
  left: 0;
  width: calc(var(--ROOTFONTFIZE) * (300 / 144));
  text-align: left;
  font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
  // line-height: 10px;
  color: var(--chart_title);
  padding: calc(var(--ROOTFONTFIZE) * (5 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: var(--table_bgc);
}

// table end --------------------------------------------------------------------
.up {
  color: red;
}
.down {
  color: green;
}
.card {
  background-color: rgba(29, 31, 37, 1);
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  // margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  // padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: 0 !important;
  height: 100%;
  box-sizing: border-box;
  .chart_title {
    color: var(--chart_title);
    font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
    font-weight: 700;
    // border-left: 5px solid #1573fe;
    border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
    padding-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
    position: relative;
  }
  .content_case {
    // padding-top: calc(var(--ROOTFONTFIZE) * (25 / 144));
    width: 100%;
    height: 90%;
    display: flex;
    .left_case {
      padding-top: 5px;
      width: 55%;
      .con_1 {
        position: relative;
        width: 100%;
        // display: flex;
        // justify-content: space-between;
        align-items: center;
        color: var(--chart_title);
        font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
        text-align: right;
        .iconfont {
          top: 9px;
          right: 19px;
        }
        // .news_case {
        //   box-sizing: border-box;
        //   padding: calc(var(--ROOTFONTFIZE) * (5 / 144)) 0 0 calc(var(--ROOTFONTFIZE) * (90 / 144));
        //   .c1 {
        //     color: #52ffff;
        //   }
        //   .c2 {
        //     color: #16bc50;
        //   }
        //   .c3 {
        //     color: #ad0a1d;
        //   }
        // }
      }
    }
    .right_case {
      width: 45%;
      // height: 670px;
      box-sizing: border-box;
      // display: flex;
      .internal_top {
        width: 95.5%;
        display: flex;
        justify-content: space-between;
        color: var(--chart_title);
        border-bottom: 1px solid gray;
        border-left: 1px solid gray;
        .current_price {
          width: 39%;
          height: calc(var(--ROOTFONTFIZE) * (40 / 144));
          text-indent: calc(var(--ROOTFONTFIZE) * (8 / 144));
          padding-bottom: calc(var(--ROOTFONTFIZE) * (15 / 144));
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: calc(var(--ROOTFONTFIZE) * (30 / 144));
          .sm {
            font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
          }
        }
        ._t {
          text-align: right;
          line-height: 1;
          font-size: calc(var(--ROOTFONTFIZE) * (25 / 144));
          // border-bottom: 1px solid gray;
          span {
            font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
          }
        }
      }
      .internal_case {
        padding-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
        width: 100%;
        height: 93%;
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
      }
      .capital_flow {
        width: 42%;
        height: 100%;
        overflow: hidden;
        border-left: 1px solid gray;
        border-right: 1px solid gray;
        padding-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
        padding-right: calc(var(--ROOTFONTFIZE) * (5 / 144));
        .title {
          padding-left: calc(var(--ROOTFONTFIZE) * 0.06944);
          border-left: calc(var(--ROOTFONTFIZE) * 0.03472) solid var(--primary-color);
          font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
          color: white;
          margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
        }
        .index_vaule {
          display: flex;
          align-items: center;
          font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
          span {
            padding-right: 10px;
          }
          .sm {
            font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
          }
        }
        .line_one {
          border-bottom: 1px solid gray;
          color: var(--chart_title);
          font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
        }
        .line_one2 {
          box-sizing: border-box;
          padding-right: 10px;
          border-bottom: 1px solid gray;
          color: var(--chart_title);
          font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            width: 30%;
            display: flex;
            justify-content: space-between;
            white-space: nowrap;
          }
        }
        .detail {
          box-sizing: border-box;
          padding-right: 10px;
          border-bottom: 1px solid gray;
          color: var(--chart_title);
          font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
          .one {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .t2_t {
          width: 100%;
          box-sizing: border-box;
          padding: calc(var(--ROOTFONTFIZE) * (5 / 144));
          color: var(--chart_title);
          font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
          display: flex;
          ._t {
            width: 38%;
          }
          ._t1 {
            width: 24%;
          }
        }
        .t2_detail {
          width: 100%;
          color: var(--chart_title);
          font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
          .one {
            display: flex;
            line-height: calc(var(--ROOTFONTFIZE) * (25 / 144));
            height: calc(var(--ROOTFONTFIZE) * (25 / 144));
          }
          ._t {
            width: 39%;
          }
          ._t1 {
            width: 22%;
          }
        }
      }
      .income {
        flex: 1;
        width: 58%;
        margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
        margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
        color: var(--chart_title);
        .valuation {
          font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
          padding-bottom: 10px;
          // border-bottom: 1px solid gray;
          .title {
            border-left: calc(var(--ROOTFONTFIZE) * 0.03472) solid var(--primary-color);
            padding-left: calc(var(--ROOTFONTFIZE) * 0.06944);
          }
        }
        .chg {
          font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
          .title {
            font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
            font-weight: 700;
            padding-left: calc(var(--ROOTFONTFIZE) * 0.06944);
            margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
            margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
            border-left: calc(var(--ROOTFONTFIZE) * 0.03472) solid var(--primary-color);
          }
        }
        .c_list {
          margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
          margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
          overflow: hidden;
          &::-webkit-scrollbar {
            width: calc(var(--ROOTFONTFIZE) * (4 / 144));
            height: calc(var(--ROOTFONTFIZE) * (6 / 144));
          }

          &::-webkit-scrollbar-thumb {
            background-color: gray;
          }
          .one {
            display: flex;
            font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
            font-weight: 400;
            letter-spacing: 0.4px;
            margin-bottom: calc(var(--ROOTFONTFIZE) * (5 / 144));
            justify-content: space-between;
            cursor: pointer;
            .name {
              width: 50%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .value {
              // color: white;
              color: var(--chart_title);
              text-align: center;
              width: 30%;
            }
            .pct {
              padding: calc(var(--ROOTFONTFIZE) * 0.02778) calc(var(--ROOTFONTFIZE) * 0.06944);
              border-radius: calc(var(--ROOTFONTFIZE) * 0.48611);
              letter-spacing: 0.41px;
              background: rgba(250, 92, 179, 0.1);
              font-size: calc(var(--ROOTFONTFIZE) * 0.09722);
              text-align: center;
              width: 20%;
            }
          }
        }
      }
    }
  }
}
</style>
