<template>
  <div class="container" :style="cssVars">
    <span class="btnArea">
      <el-button
        v-for="(item, index) in dBtn1"
        :key="index"
        :class="item == activeBtn1 ? 'isActiveStyle' : ''"
        size="mini"
        plain
        @click="fnChangeBtn1(item)"
        >{{ item }}</el-button
      >
    </span>
  </div>
</template>

<script>
export default {
  // 从父组件传值的
  props: {
    // dButtons1:{
    //     type:Array,
    //     default:""
    // },
    threeY: {
      type: Boolean,
      default: false,
    },
    spc1: {
      type: String,
      default: "",
    },
    FD: {
      type: Boolean,
      default: false,
    },
    default: {
      type: String,
      default: "",
    },
  },
  watch:{
    spc1(val){
      if(val){
        this.dBtn1 = this.spc1=='time_slot'
        ? ["YTD", "3M", "6M", "1Y", "3Y", "MAX"]
        : ["2021","2022","2023","2024"];
        this.fnChangeBtn1(this.dBtn1[0]);
      }
    }
  },
  methods: {
    fnChangeBtn1(val) {
      if(!this.isOpen) return

      if(this.isOpen && this.FD){
          this.isOpen = false
          this.activeBtn1 = val;
          this.$emit("CTimeButtons_select_val", val);
      } 

      if(!this.FD){
          this.activeBtn1 = val;
          this.$emit("CTimeButtons_select_val", val);
      } 
      // setTimeout(()=>{
      // this.isOpen = true
      // },3000)
      //    if(val == 'MAX') val ='max'
      // console.log(val,'val1231');
      
    },
  },
  components: {},
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
      };
    },
  },
  created() {
    this.ROOTFONTFIZE = window.sessionStorage.getItem("ROOTFONTFIZE");
  },
  data() {
    return {
      dBtn1: this.spc1=='time_slot'
        ? ["YTD", "3M", "6M", "1Y", "3Y", "MAX"]
        : ["2021","2022","2023","2024"],
      activeBtn1: this.default,
      ROOTFONTFIZE: "",
      isOpen:true
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  // font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  // line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
  color: #ffffff;
  display: flex;
  align-items: center;

  // background-color: var(--Btn-bgc);
  // padding: calc(var(--ROOTFONTFIZE) * (10 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
  // padding: 10px 20px;
  // border-radius: calc(var(--ROOTFONTFIZE) * (8 / 144));
  // border-radius: 8px;
}

.el-button {
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--Btn-borderColor) !important;
  box-sizing: border-box !important;
  height: 35px;
  background-color: var(--Btn-bgc2) !important;
  color: var(--stock_area_item_color1) !important;
  // border-radius: 4px;
}

.btnArea {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
}

.btnArea .el-button:hover {
  background-color: var(--Btn-bgc_hover);
  color: var(--Btn-color2) !important;
  // border: 1px solid var(--Btn-brc) !important;
  border: 1px solid var(--primary-color) !important;

}

.btnArea .isActiveStyle {
  background-color: var(--primary-color) !important;
  color: var(--Btn-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.btnArea .isActiveStyle:hover {
  color: var(--Btn-color) !important;
}
</style>
