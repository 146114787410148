<template>
  <div class="card" style="margin-bottom: 0">
    <div class="card-title">交易排名</div>
    <div class="wrap">
      <div>
        <CButtons2 :dButtons1="select_1" :defaultVal="select_1_default" @Cbtns="select1Change" />
      </div>
      <div class="flex items-center" style="margin-top: 20px">
        <div class="flex items-center">
          <span style="margin-right: 6px">显示类型: </span>
          <CButtons
            :dButtons1="select_2"
            :dButtons1_current="select_2_default"
            @Cbtns_sep="select2Change" />
        </div>
        <div v-if="select_1_default === '大宗交易'" style="margin: auto">
          <CButtons
            :dButtons1="select_3"
            :dButtons1_current="select_3_default"
            @Cbtns_sep="select3Change" />
        </div>
        <div style="margin-right: 0; margin-left: auto" v-if="select_1_default !== '大宗交易'">
          <Download
            :dButtonsDownloads="dButtonsDownloads"
            :excel_info="excel_info1"
            echartRefName="dwn1_rnkRef" />
        </div>
        <div style="margin-right: 0; margin-left: auto" v-if="select_1_default === '大宗交易'">
          <Download
            :dButtonsDownloads="dButtonsDownloads"
            :excel_info="excel_info2"
            echartRefName="dwn2_rnkRef" />
        </div>
      </div>
      <div style="margin-top: 20px" v-if="select_1_default === '二级市场'" key="二级市场1">
        <el-table
          class="table1"
          :header-cell-style="tableStyle"
          :data="page_data"
          @row-click="fnClick"
          :height="select_2_default === '大类' ? '' : 550"
          style="width: 100%">
          <!-- <el-table-column> -->
          <el-table-column type="index" width="50" label="No."></el-table-column>
          <el-table-column
            align="center"
            label="代码"
            width="100"
            v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? '-' : scope.row.reit_code }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="简称"
            width="160"
            v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? '-' : scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="大类" width="100">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? scope.row.reit_code : scope.row.category_1 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="细分"
            width="100"
            v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? '-' : scope.row.category_2 }}
            </template>
          </el-table-column>
          <!-- </el-table-column> -->
          <el-table-column label="成交额(亿元)" align="center">
            <el-table-column
              align="center"
              label="1D"
              prop="amt.1D"
              sortable
              class-name="color-box">
              <template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(scope.row.amt['1D'], normalize_data.amt.max, normalize_data.amt.min)
                    ),
                    color:
                      normalize(
                        scope.row.amt['1D'],
                        normalize_data.amt.max,
                        normalize_data.amt.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.amt['1D'] | amt }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="1W" prop="amt.1W" sortable class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(scope.row.amt['1W'], normalize_data.amt.max, normalize_data.amt.min)
                    ),
                    color:
                      normalize(
                        scope.row.amt['1W'],
                        normalize_data.amt.max,
                        normalize_data.amt.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.amt['1W'] | amt }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="1M" prop="amt.1M" sortable class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(scope.row.amt['1M'], normalize_data.amt.max, normalize_data.amt.min)
                    ),
                    color:
                      normalize(
                        scope.row.amt['1M'],
                        normalize_data.amt.max,
                        normalize_data.amt.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.amt['1M'] | amt }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="YTD"
              prop="amt.YTD"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.amt['YTD'],
                        normalize_data.amt.max,
                        normalize_data.amt.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.amt['YTD'],
                        normalize_data.amt.max,
                        normalize_data.amt.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.amt['YTD'] | amt }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="大宗成交额(亿元)" align="center">
            <el-table-column
              align="center"
              label="1D"
              prop="blocktrade_amt.1D"
              sortable
              class-name="color-box">
              <template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.blocktrade_amt['1D'],
                        normalize_data.blocktrade_amt.max,
                        normalize_data.blocktrade_amt.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.blocktrade_amt['1D'],
                        normalize_data.blocktrade_amt.max,
                        normalize_data.blocktrade_amt.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.blocktrade_amt['1D'] | amt }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1W"
              prop="blocktrade_amt.1W"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.blocktrade_amt['1W'],
                        normalize_data.blocktrade_amt.max,
                        normalize_data.blocktrade_amt.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.blocktrade_amt['1W'],
                        normalize_data.blocktrade_amt.max,
                        normalize_data.blocktrade_amt.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.blocktrade_amt['1W'] | amt }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1M"
              prop="blocktrade_amt.1M"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.blocktrade_amt['1M'],
                        normalize_data.blocktrade_amt.max,
                        normalize_data.blocktrade_amt.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.blocktrade_amt['1M'],
                        normalize_data.blocktrade_amt.max,
                        normalize_data.blocktrade_amt.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.blocktrade_amt['1M'] | amt }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="YTD"
              prop="blocktrade_amt.YTD"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.blocktrade_amt['YTD'],
                        normalize_data.blocktrade_amt.max,
                        normalize_data.blocktrade_amt.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.blocktrade_amt['YTD'],
                        normalize_data.blocktrade_amt.max,
                        normalize_data.blocktrade_amt.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.blocktrade_amt['YTD'] | amt }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="换手率" align="center">
            <el-table-column
              align="center"
              label="1D"
              prop="turnover.1D"
              sortable
              class-name="color-box">
              <template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.turnover['1D'],
                        normalize_data.turnover.max,
                        normalize_data.turnover.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.turnover['1D'],
                        normalize_data.turnover.max,
                        normalize_data.turnover.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.turnover['1D'] | pct }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1W"
              prop="turnover.1W"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.turnover['1W'],
                        normalize_data.turnover.max,
                        normalize_data.turnover.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.turnover['1W'],
                        normalize_data.turnover.max,
                        normalize_data.turnover.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.turnover['1W'] | pct }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1M"
              prop="turnover.1M"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.turnover['1M'],
                        normalize_data.turnover.max,
                        normalize_data.turnover.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.turnover['1M'],
                        normalize_data.turnover.max,
                        normalize_data.turnover.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.turnover['1M'] | pct }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="YTD"
              prop="turnover.YTD"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.turnover['YTD'],
                        normalize_data.turnover.max,
                        normalize_data.turnover.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.turnover['YTD'],
                        normalize_data.turnover.max,
                        normalize_data.turnover.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  {{ scope.row.turnover['YTD'] | pct }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px" v-show="select_1_default === '二级市场'" key="按百分比显示">
        <el-checkbox v-model="valueFix" style="margin-left: 0px" />
        <span style="margin-left: 6px"
          >按百分比显示
          <el-tooltip
            class="item"
            effect="dark"
            content="百分比显示表示该金额占同时间段总成交额的比例"
            placement="top">
            <i class="el-icon-question" style="color: rgb(64, 158, 255)"></i> </el-tooltip
        ></span>
      </div>
      <div style="margin-top: 20px" v-if="select_1_default === '二级市场'" key="二级市场2">
        <el-table
          class="table1"
          :header-cell-style="tableStyle"
          :data="page_data"
          @row-click="fnClick"
          :height="select_2_default === '大类' ? '' : 550"
          style="width: 100%">
          <!-- <el-table-column> -->
          <el-table-column type="index" width="50" label="No."></el-table-column>
          <el-table-column
            align="center"
            label="代码"
            width="100"
            v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? '-' : scope.row.reit_code }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="简称"
            width="160"
            v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? '-' : CODELIST[scope.row.reit_code].reitsName }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="大类" width="100">
            <template slot-scope="scope">
              {{
                select_2_default === '大类'
                  ? scope.row.reit_code
                  : CODELIST[scope.row.reit_code].reitsTypeL1
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="细分"
            width="100"
            v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? '-' : CODELIST[scope.row.reit_code].reitsTypeL2 }}
            </template>
          </el-table-column>
          <!-- </el-table-column> -->
          <el-table-column
            :render-header="renderHeader"
            :label="'资金净流入' + (valueFix ? '' : '(万元)')"
            align="center">
            <el-table-column
              align="center"
              label="1D"
              prop="netinflow.1D"
              sortable
              class-name="color-box">
              <template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netinflow['1D'],
                        normalize_data.netinflow.max,
                        normalize_data.netinflow.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netinflow['1D'],
                        normalize_data.netinflow.max,
                        normalize_data.netinflow.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netinflow['1D'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netinflow['1D'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1W"
              prop="netinflow.1W"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netinflow['1W'],
                        normalize_data.netinflow.max,
                        normalize_data.netinflow.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.amt['1W'],
                        normalize_data.amt.max,
                        normalize_data.amt.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netinflow['1W'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netinflow['1W'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1M"
              prop="netinflow.1M"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netinflow['1M'],
                        normalize_data.netinflow.max,
                        normalize_data.netinflow.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netinflow['1M'],
                        normalize_data.netinflow.max,
                        normalize_data.netinflow.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netinflow['1M'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netinflow['1M'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="YTD"
              prop="netinflow.YTD"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netinflow['YTD'],
                        normalize_data.netinflow.max,
                        normalize_data.netinflow.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netinflow['YTD'],
                        normalize_data.netinflow.max,
                        normalize_data.netinflow.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netinflow['YTD'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netinflow['YTD'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :render-header="renderHeader"
            :label="'主力资金静流入' + (valueFix ? '' : '(万元)')"
            align="center">
            <el-table-column
              align="center"
              label="1D"
              prop="netbuyamt_main.1D"
              sortable
              class-name="color-box">
              <template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netbuyamt_main['1D'],
                        normalize_data.netbuyamt_main.max,
                        normalize_data.netbuyamt_main.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netbuyamt_main['1D'],
                        normalize_data.netbuyamt_main.max,
                        normalize_data.netbuyamt_main.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netbuyamt_main['1D'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netbuyamt_main['1D'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1W"
              prop="netbuyamt_main.1W"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netbuyamt_main['1W'],
                        normalize_data.netbuyamt_main.max,
                        normalize_data.netbuyamt_main.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netbuyamt_main['1W'],
                        normalize_data.netbuyamt_main.max,
                        normalize_data.netbuyamt_main.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netbuyamt_main['1W'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netbuyamt_main['1W'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1M"
              prop="netbuyamt_main.1M"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netbuyamt_main['1M'],
                        normalize_data.netbuyamt_main.max,
                        normalize_data.netbuyamt_main.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netbuyamt_main['1M'],
                        normalize_data.netbuyamt_main.max,
                        normalize_data.netbuyamt_main.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netbuyamt_main['1M'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netbuyamt_main['1M'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="YTD"
              prop="netbuyamt_main.YTD"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netbuyamt_main['YTD'],
                        normalize_data.netbuyamt_main.max,
                        normalize_data.netbuyamt_main.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netbuyamt_main['YTD'],
                        normalize_data.netbuyamt_main.max,
                        normalize_data.netbuyamt_main.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netbuyamt_main['YTD'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netbuyamt_main['YTD'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :render-header="renderHeader"
            :label="'主力主动资金净流入' + (valueFix ? '' : '(万元)')"
            align="center">
            <el-table-column
              align="center"
              label="1D"
              prop="netbuyamt_a_main.1D"
              sortable
              class-name="color-box">
              <template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netbuyamt_a_main['1D'],
                        normalize_data.netbuyamt_a_main.max,
                        normalize_data.netbuyamt_a_main.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netbuyamt_a_main['1D'],
                        normalize_data.netbuyamt_a_main.max,
                        normalize_data.netbuyamt_a_main.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netbuyamt_a_main['1D'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netbuyamt_a_main['1D'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1W"
              prop="netbuyamt_a_main.1W"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netbuyamt_a_main['1W'],
                        normalize_data.netbuyamt_a_main.max,
                        normalize_data.netbuyamt_a_main.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netbuyamt_a_main['1W'],
                        normalize_data.netbuyamt_a_main.max,
                        normalize_data.netbuyamt_a_main.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netbuyamt_a_main['1W'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netbuyamt_a_main['1W'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="1M"
              prop="netbuyamt_a_main.1M"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netbuyamt_a_main['1M'],
                        normalize_data.netbuyamt_a_main.max,
                        normalize_data.netbuyamt_a_main.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netbuyamt_a_main['1M'],
                        normalize_data.netbuyamt_a_main.max,
                        normalize_data.netbuyamt_a_main.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netbuyamt_a_main['1M'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netbuyamt_a_main['1M'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="YTD"
              prop="netbuyamt_a_main.YTD"
              sortable
              class-name="color-box"
              ><template slot-scope="scope">
                <div
                  :style="{
                    '--box-color': getGradientColor(
                      normalize(
                        scope.row.netbuyamt_a_main['YTD'],
                        normalize_data.netbuyamt_a_main.max,
                        normalize_data.netbuyamt_a_main.min
                      )
                    ),
                    color:
                      normalize(
                        scope.row.netbuyamt_a_main['YTD'],
                        normalize_data.netbuyamt_a_main.max,
                        normalize_data.netbuyamt_a_main.min
                      ) > 0.3
                        ? 'black'
                        : '#fff',
                  }">
                  <div v-if="!valueFix">
                    {{ scope.row.netbuyamt_a_main['YTD'] | amt }}
                  </div>
                  <div v-else>
                    {{ scope.row.netbuyamt_a_main['YTD'] | pct }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px" v-if="select_1_default === '大宗交易'" key="大宗交易">
        <el-table
          :key="select_2_default"
          class="table1"
          :header-cell-style="tableStyle"
          :data="page_data2"
          @row-click="fnClick"
          :height="OFFSETHEIGHT - 340"
          style="width: 100%">
          <el-table-column type="index" width="50" label="No."></el-table-column>
          <el-table-column label="代码" width="100" key="代码" v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? '-' : scope.row.reit_code }}
            </template>
          </el-table-column>
          <el-table-column label="简称" width="170" key="简称" v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? '-' : scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column label="大类" width="150">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? scope.row.reit_code : scope.row.category_1 }}
            </template>
          </el-table-column>
          <el-table-column label="细分" width="120" key="细分" v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ select_2_default === '大类' ? '-' : scope.row.category_2 }}
            </template>
          </el-table-column>
          <el-table-column prop="times" sortable label="成交笔数" min-width="100">
          </el-table-column>
          <el-table-column prop="vol" sortable label="成交量" min-width="120"> </el-table-column>
          <el-table-column label="成交额(万元)" prop="amt" sortable min-width="180">
            <template slot-scope="scope">
              <div class="flex items-center">
                <div
                  class="color-bar2"
                  :style="{
                    '--bar_color': '#4ec0c5',
                    '--w': calcPx(
                      normalize(scope.row.amt, page_data2_info.amt.max, page_data2_info.amt.min)
                    ),
                  }"></div>
                <div>{{ scope.row.amt | amt }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="成交均价"
            min-width="120"
            key="成交均价"
            v-if="select_2_default !== '大类'">
            <template slot-scope="scope">
              {{ scope.row.avg_price | fix2 }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="185"
            label="平均折溢价率(相对前日收盘价)"
            prop="block_pre_discount"
            sortable
            class-name="line-bar">
            <template #header>平均折溢价率<br />(相对前日收盘价)</template>
            <template slot-scope="scope">
              <div class="flex items-center justify-center h-full">
                <div :style="{ width: '60%' }" class="flex items-center h-full">
                  <div
                    class="color-bar left"
                    :class="{ 'opacity-0': scope.row.block_pre_discount >= 0 }"
                    :style="{
                      '--bar_color': '#f2aa84',
                      '--w': number2Pct(
                        normalize(
                          Math.abs(scope.row.block_pre_discount),
                          page_data2_info.block_pre_discount.max,
                          page_data2_info.block_pre_discount.min
                        ) || 0.01
                      ),
                    }"></div>
                  <div class="line"></div>
                  <div
                    class="color-bar right"
                    :class="{ 'opacity-0': scope.row.block_pre_discount <= 0 }"
                    :style="{
                      '--bar_color': '#4ec0c5',
                      '--w': number2Pct(
                        normalize(
                          Math.abs(scope.row.block_pre_discount),
                          page_data2_info.block_pre_discount.max,
                          page_data2_info.block_pre_discount.min
                        ) || 0.01
                      ),
                    }"></div>
                </div>
                <div class="flex-1 text-left">
                  {{ scope.row.block_pre_discount | pct }}
                </div>
              </div>
              <!-- <div class="flex items-center justify-center h-full">
                <div :style="{ width: '60%' }" class="flex items-center h-full">
                  <div
                    class="color-bar left"
                    :class="{ 'opacity-0': scope.row.block_pre_discount >= 0 }"
                    :style="{
                      '--bar_color': 'skyblue',
                      '--w': number2Pct(Math.abs(scope.row.block_pre_discount)),
                    }"></div>
                  <div class="line"></div>
                  <div
                    class="color-bar right"
                    :class="{ 'opacity-0': scope.row.block_pre_discount <= 0 }"
                    :style="{
                      '--bar_color': 'deeppink',
                      '--w': number2Pct(Math.abs(scope.row.block_pre_discount)),
                    }"></div>
                </div>
                <div class="flex-1 text-left">
                  {{ scope.row.block_pre_discount | pct }}
                </div>
              </div> -->
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="185"
            label="平均折溢价率(相对当日收盘价)"
            prop="block_discount"
            sortable
            class-name="line-bar">
            <template #header>平均折溢价率<br />(相对当日收盘价)</template>
            <template slot-scope="scope">
              <div class="flex items-center justify-center h-full">
                <div :style="{ width: '60%' }" class="flex items-center h-full">
                  <div
                    class="color-bar left"
                    :class="{ 'opacity-0': scope.row.block_discount >= 0 }"
                    :style="{
                      '--bar_color': '#f2aa84',
                      '--w': number2Pct(
                        normalize(
                          Math.abs(scope.row.block_discount),
                          page_data2_info.block_discount.max,
                          page_data2_info.block_discount.min
                        ) || 0.01
                      ),
                    }"></div>
                  <div class="line"></div>
                  <div
                    class="color-bar right"
                    :class="{ 'opacity-0': scope.row.block_discount <= 0 }"
                    :style="{
                      '--bar_color': '#4ec0c5',
                      '--w': number2Pct(
                        normalize(
                          Math.abs(scope.row.block_discount),
                          page_data2_info.block_discount.max,
                          page_data2_info.block_discount.min
                        ) || 0.01
                      ),
                    }"></div>
                </div>
                <div class="flex-1 text-left">
                  {{ scope.row.block_discount | pct }}
                </div>
              </div>
              <!--   <div class="flex items-center justify-center h-full">
                <div :style="{ width: '60%' }" class="flex items-center h-full">
                  <div
                    class="color-bar left"
                    :class="{ 'opacity-0': scope.row.block_discount >= 0 }"
                    :style="{
                      '--bar_color': 'skyblue',
                      '--w': number2Pct(Math.abs(scope.row.block_discount)),
                    }"></div>
                  <div class="line"></div>
                  <div
                    class="color-bar right"
                    :class="{ 'opacity-0': scope.row.block_discount <= 0 }"
                    :style="{
                      '--bar_color': 'deeppink',
                      '--w': number2Pct(Math.abs(scope.row.block_discount)),
                    }"></div>
                </div>
                <div class="flex-1 text-left">
                  {{ scope.row.block_discount | pct }}
                </div>
              </div> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import CButtons from '../Basic/CButtons_separate.vue';
import CButtons2 from '../Basic/CButtons.vue';
import Download from '../Basic/Download.vue';
import { fnReturnTableStyle } from '@/utils/util';
import { normalize, getGradientColor, numberFix2, number2Pct } from '@/utils';

const calcPx = (n) => {
  return n * 70 + 'px';
};

const getMaxAndMin = (data, k, abs = false) => {
  const all = data.map((item) => {
    if (abs) return Math.abs(item[k]);
    return item[k];
  });
  return {
    max: Math.max(...all),
    min: Math.min(...all),
  };
};

export default {
  name: 'TradingRank',
  components: { CButtons, CButtons2, Download },
  data() {
    return {
      CODELIST: Object.freeze({}),
      OFFSETHEIGHT: 0,
      ROOTFONTFIZE: '',
      select_1: Object.freeze(['二级市场', '大宗交易']),
      select_1_default: '二级市场',
      select_2: Object.freeze(['大类', '个股']),
      select_2_default: '大类',
      select_3: Object.freeze(['1D', '1W', '1M', 'YTD']),
      // select_3: Object.freeze(['YTD', '3M', '6M', '1Y', '3Y', 'MAX']),
      select_3_default: '1D',
      valueFix: true,
      page_data: Object.freeze([]),
      page_data2: Object.freeze([]),
      normalize_data: Object.freeze({}),
      page_data2_info: Object.freeze({}),
      dButtonsDownloads: Object.freeze([
        /* {
          id: '241020',
          name: '下载图片',
        }, */
        {
          id: '241020',
          name: '下载表格',
        },
      ]),
      excel_info1: Object.freeze({
        /*overviewName: '',
          excel_data:[],
          json_fields:{}
        */
      }),
      excel_info2: Object.freeze({
        /*overviewName: '',
          excel_data:[],
          json_fields:{}
        */
      }),
    };
  },
  computed: {
    tableStyle() {
      const val = this.$store.state.theme === 'dark';
      return () => fnReturnTableStyle(val);
      // return fnReturnTableStyle(val);
    },
    reload() {
      return this.select_1_default + this.select_2_default + this.select_3_default + this.valueFix;
    },
    qsStr() {
      const sel_type = this.select_2_default === '大类' ? 'category' : 'single';
      const show_pct = this.valueFix ? 1 : 0;
      const time_type = this.select_3_default;
      const data = {
        sel_type,
      };
      if (this.select_1_default === '二级市场') {
        data.show_pct = show_pct;
      } else {
        data.time_type = time_type;
      }
      return new URLSearchParams(data);
    },
  },
  watch: {
    reload() {
      this.page_data = Object.freeze([]);
      this.loadData();
    },
  },
  filters: {
    fix2(n) {
      if (n === '') return '';
      return numberFix2(n);
    },
    pct(n) {
      if (n === '') return '';
      return number2Pct(n);
    },
    amt(n) {
      if (n === '') return '';
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping: true,
      });
      return formatter.format(n / 10000);
    },
  },
  methods: {
    calcPx,
    number2Pct,
    normalize,
    getGradientColor,
    fnClick(row, column, cell) {
      if (this.select_2_default !== '个股') return;
      let arr = [row['name'], row['reit_code']];
      // console.clear();
      // console.log(arr, row);
      this.$eventBus.$emit('skipPage', arr);
    },
    renderHeader(h, { column }) {
      // return h('span', {}, [
      //   h('span', {}, column.label.split('/')[0]),
      //   h('br'),
      //   h('span', {}, column.label.split('/')[1]),
      // ]);
      var str = '';
      if (column.label == '资金净流入' || column.label == '资金净流入(万元)') {
        str = '(当日的场内流通份额-前一日的场内流通份额)*当日成交均价';
      }
      if (column.label == '主力资金静流入' || column.label == '主力资金静流入(万元)') {
        str = '主力资金流入-主力资金流出';
      }
      if (column.label == '主力主动资金净流入' || column.label == '主力主动资金净流入(万元)') {
        str = '主力资金净流入中，主动成交已挂单的资金净额';
      }
      return h('span', [
        h('span', column.label),
        h(
          'el-tooltip',
          {
            props: {
              effect: 'dark',
              content: str,
              placement: 'top',
            },
          },
          [
            h('i', {
              class: 'el-icon-question',
              style: 'color:#409eff;margin-left:5px;',
            }),
          ]
        ),
      ]);
    },
    select1Change(v) {
      this.select_1_default = v;
    },
    select2Change(v) {
      this.select_2_default = v;
    },
    select3Change(v) {
      this.select_3_default = v;
    },
    pageInit() {
      if (this._page_init) return;
      this.loadData();
    },
    loadData() {
      if (this.select_1_default === '二级市场') {
        this.loadData1();
      }
      if (this.select_1_default === '大宗交易') {
        this.loadData2();
      }
    },
    getExcelInfo1() {
      const overviewName = '二级市场';
      const json_fields = {
        代码: 'reit_code',
        简称: 'reit_code',
        大类: this.select_2_default === '大类' ? 'reit_code' : 'category_1',
        细分: 'category_2',
        '成交额-1D': 'amt.1D',
        '成交额-1W': 'amt.1W',
        '成交额-1M': 'amt.1M',
        '成交额-YTD': 'amt.YTD',
        '大宗成交额-1D': 'blocktrade_amt.1D',
        '大宗成交额-1W': 'blocktrade_amt.1W',
        '大宗成交额-1M': 'blocktrade_amt.1M',
        '大宗成交额-YTD': 'blocktrade_amt.YTD',
        '换手率-1D': 'turnover.1D',
        '换手率-1W': 'turnover.1W',
        '换手率-1M': 'turnover.1M',
        '换手率-YTD': 'turnover.YTD',
        '资金净流入-1D': 'netinflow.1D',
        '资金净流入-1W': 'netinflow.1W',
        '资金净流入-1M': 'netinflow.1M',
        '资金净流入-YTD': 'netinflow.YTD',
        '主力资金流入-1D': 'netbuyamt_main.1D',
        '主力资金流入-1W': 'netbuyamt_main.1W',
        '主力资金流入-1M': 'netbuyamt_main.1M',
        '主力资金流入-YTD': 'netbuyamt_main.YTD',
        '主力主动资金净流入-1D': 'netbuyamt_a_main.1D',
        '主力主动资金净流入-1W': 'netbuyamt_a_main.1W',
        '主力主动资金净流入-1M': 'netbuyamt_a_main.1M',
        '主力主动资金净流入-YTD': 'netbuyamt_a_main.YTD',
      };
      if (this.select_2_default !== '个股') {
        delete json_fields['代码'];
        delete json_fields['简称'];
        delete json_fields['细分'];
      }
      setTimeout(() => {
        this.excel_info1 = Object.freeze({
          json_fields,
          overviewName,
          excel_data: this.page_data,
        });
      }, 0);
    },
    getExcelInfo2() {
      const overviewName = '大宗交易';
      const json_fields = {
        代码: 'reit_code',
        简称: 'name',
        大类: this.select_2_default === '大类' ? 'reit_code' : 'category_1',
        细分: 'category_2',
        成交笔数: 'times',
        成交量: 'vol',
        成交额: 'amt',
        成交均价: 'avg_price',
        '平均折溢价率(相对前日收盘价)': 'block_pre_discount',
        '平均折溢价率(相对当日收盘价)': 'block_discount',
      };
      if (this.select_2_default !== '个股') {
        delete json_fields['代码'];
        delete json_fields['简称'];
        delete json_fields['细分'];
        delete json_fields['代码'];
        delete json_fields['成交均价'];
      }
      setTimeout(() => {
        this.excel_info2 = Object.freeze({
          json_fields,
          overviewName,
          excel_data: this.page_data2,
        });
      }, 0);
    },
    async loadData1() {
      const { data } = await this.$https.get('/api/v2_2/trade_market_summary' + '?' + this.qsStr);
      if (data.code !== 200) return this.$message.error(data.msg);
      this._page_init = true;
      const result = (data.data || []).reduce((p, c) => {
        const { index, time_type, reit_code, category_1, category_2, name, ...args } = c;
        if (!p[c.reit_code]) {
          p[c.reit_code] = {
            reit_code,
            category_1,
            category_2,
            name,
          };
        }
        Object.keys(args).forEach((k) => {
          if (!p[c.reit_code][k]) {
            p[c.reit_code][k] = {};
          }
          const item = p[c.reit_code][k];
          item[time_type] = args[k];
        });

        return p;
      }, {});
      const reamrk_result = Object.freeze(Object.values(result));

      // 分块
      const all_num = reamrk_result.reduce((p, c) => {
        const { reit_code, category_1, category_2, name, ...args } = c;
        Object.keys(args).forEach((k) => {
          if (!p[k]) {
            p[k] = [];
          }
          p[k].push(...Object.values(args[k]));
        });
        return p;
      }, {});
      const normalize_data = Object.keys(all_num).reduce((p, c) => {
        p[c] = {
          max: Math.max(...all_num[c]),
          min: Math.min(...all_num[c]),
        };
        return p;
      }, {});

      // 每列单独
      /*  const all_num = reamrk_result.reduce((p, c) => {
        const { reit_code, ...args } = c;
        Object.keys(args).forEach((k) => {
          if (!p[k]) {
            p[k] = {};
          }
          Object.keys(args[k]).forEach((ak) => {
            if (!p[k][ak]) {
              p[k][ak] = [];
            }
            p[k][ak].push(args[k][ak]);
          });
        });
        return p;
      }, {});
      const normalize_data = Object.keys(all_num).reduce((p, c) => {
        const val = Object.keys(all_num[c]).reduce((v, k) => {
          const max = Math.max(...all_num[c][k]);
          const min = Math.min(...all_num[c][k]);
          v[k] = {
            max,
            min,
          };
          return v;
        }, {});
        p[c] = val;
        return p;
      }, {}); */
      this.page_data = reamrk_result;
      this.normalize_data = Object.freeze(normalize_data);
      this.getExcelInfo1();
    },
    async loadData2() {
      const { data } = await this.$https.get(
        '/api/v2_2/blocktrade_table_summary' + '?' + this.qsStr
      );
      if (data.code !== 200) return this.$message.error(data.msg);
      this._page_init = true;
      this.page_data2 = Object.freeze(data.data);
      this.page_data2_info = Object.freeze({
        amt: getMaxAndMin(data.data, 'amt'),
        block_pre_discount: getMaxAndMin(data.data, 'block_pre_discount', true),
        block_discount: getMaxAndMin(data.data, 'block_discount', true),
      });
      this.getExcelInfo2();
    },
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
      let OFFSETHEIGHT = element.offsetHeight;
      this.OFFSETHEIGHT = OFFSETHEIGHT;
      return;
    },
    resize() {
      this.fnGetWidth();
      this.echart_instance && this.echart_instance.resize();
    },
  },
  created() {
    const CODELIST = JSON.parse(localStorage.getItem('CODELIST')) || {};
    const remark_CODELIST = CODELIST.reduce((p, c) => {
      const { reitCode, ...args } = c;
      p[reitCode] = args;
      return p;
    }, {});
    this.CODELIST = Object.freeze(remark_CODELIST);
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    this.echart_instance && this.echart_instance.dispose();
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style scoped lang="less">
.card {
  background-color: rgba(29, 31, 37, 1);
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  height: auto;
  color: var(--chart_title);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  .card-title {
    color: var(--chart_title);
    font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
    font-weight: 700;
    // border-left: 5px solid #1573fe;
    border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
    padding-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
    position: relative;
  }
  .wrap {
    width: 97%;
    margin: auto;
    padding-top: 20px;
    .container {
      margin-left: 0;
    }
  }
}
.opacity-0 {
  opacity: 0;
}
/deep/.line {
  flex: none;
  width: 1px;
  height: 100%;
  border-left: 1px dashed #fff;
}
/deep/.flex {
  display: flex;
}
/deep/.flex-1 {
  flex: 1;
}
/deep/.text-left {
  text-align: left;
}
/deep/.items-center {
  align-items: center;
}
/deep/.space-between {
  justify-content: space-between;
}
/deep/.justify-center {
  justify-content: center;
}
/deep/.h-full {
  height: 100%;
}

/deep/.color-bar2 {
  width: var(--w);
  min-width: 1px;
  height: 16px;
  margin-right: 10px;
  background-color: var(--bar_color);
}
/deep/.color-bar {
  width: 50%;
  height: 16px;
  &.left {
    background: linear-gradient(to left, var(--bar_color) var(--w), rgba(0, 0, 0, 0) 0%);
  }
  &.right {
    background: linear-gradient(to right, var(--bar_color) var(--w), rgba(0, 0, 0, 0) 0%);
  }
}

/deep/.color-box {
  padding: 0 !important;
}
/deep/.line-bar {
  padding: 0 !important;
}
/deep/.color-box .cell,
/deep/.line-bar .cell {
  height: 100%;
  width: 100%;
  padding: 0;
}
/deep/.color-box .cell > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  // color: #fff;
  // color: black;
  background-color: var(--box-color);
}

/* el-table start */

::v-deep .table1 table,
.el-table--border,
.el-table--group {
  border: none;
}
::v-deep .el-table--border .el-table__cell {
  border-right: none;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid var(--table_brc);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  /* background-color: var(--table_color_hover); */
  color: var(--table_color_hover);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell:hover {
  cursor: pointer;
}

::v-deep .el-table::before,
.el-table::after {
  background-color: var(--table_bgc);
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: var(--table_bgc);
}

/* el-table end */
</style>
