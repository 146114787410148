<template>
  <el-container
    class="home-container"
    id="rootchart"
    :style="cssVars"
    v-loading.fullscreen.lock="fullscreenLoading">
    <el-aside :width="asideWidth" class="aside">
      <div class="comTitle">
        <div :class="value ? 'logoLight' : ''"></div>
        <!-- <div class="logoLight"></div> -->
        <!-- <div> -->
        <!-- <img src="@/assets/logo.png" alt=""> -->
        <!-- <img src="https://img.js.design/assets/img/6577c8a2b6bb15c7d5d6b1f8.png#c906fc1929c654d56c61305273c9514c" alt=""> -->
        <!-- </div> -->
        <!-- <div>葱花科技</div> -->
      </div>
      <!-- background-color="#1d1f25" text-color="#b7b7b8"   active-text-color="#333"  -->
      <el-menu
        :default-active="default_active"
        router
        :background-color="menuBackgroundColor"
        :text-color="menutTextColor"
        :active-text-color="menuActiveTextColor">
        <el-menu-item
          @click="saveNavState(item)"
          :index="item.path"
          id="menuItem"
          v-for="item in menuList"
          :key="item.id">
          <i :class="iconsObj[item.id]"></i>
          <span>{{ item.authName }}</span>
        </el-menu-item>
      </el-menu>
      <div class="bottomArea">
        <div><i class="el-icon-share"></i> Help</div>
        <div @click="logout" class="logout"><i class="el-icon-edit"></i> Log Out</div>
      </div>
    </el-aside>

    <el-container>
      <!-- <div class="aaa">123</div>
    <div class="bbb">123</div> -->
      <el-header class="app-breadcrumb1">
        <div v-show="currentRoute == '/dashboard'" class="breadcrumb">Dashboard</div>
        <div v-show="currentRoute == '/marketDynamics'" class="breadcrumb">
          市场动态 |
          <div class="overview">{{ BreadcrumbsName }}</div>
        </div>
        <div v-show="currentRoute == '/financialReport'" class="breadcrumb">
          财报分析 |
          <div class="overview">{{ BreadcrumbsName }}</div>
        </div>
        <div v-show="currentRoute == '/underlyingAssets'" class="breadcrumb">底层资产</div>
        <div v-show="currentRoute == '/valuationAnalysis'" class="breadcrumb">
          估值分析 |
          <div class="overview">{{ BreadcrumbsName }}</div>
        </div>
        <div v-show="currentRoute == '/trendInights'" class="breadcrumb">
          趋势洞察 |
          <div class="overview">{{ BreadcrumbsName }}</div>
        </div>
        <div v-show="currentRoute == '/individualShare'" class="breadcrumb">
          个股部分 |
          <div class="overview">{{ BreadcrumbsName }}</div>
        </div>
        <div v-show="currentRoute == '/market-newsletter'" class="breadcrumb">
          市场快报 |
          <div class="overview">{{ BreadcrumbsName }}</div>
        </div>
        <div class="right_area">
          <div>
            <!-- <el-button icon="el-icon-search" size="mini" circle></el-button> -->

            <!-- active-color="#e3e5ed" -->
            <!-- width="36" -->
            <el-switch
              class="switchStyle"
              v-model="value"
              inactive-color="#2a4037"
              active-color="#f8f7f5"
              :disabled="switchDisabled"
              @change="fnSwitch">
            </el-switch>
          </div>

          <div>
            <!-- clearable -->
            <el-autocomplete
              class="SsearchStyle"
              v-model="state2"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              :popper-append-to-body="false"
              :trigger-on-focus="true"
              suffix-icon="el-icon-search"
              @select="handleSelect">
            </el-autocomplete>
          </div>

          <div v-if="!show_badge" @click="showDialog(true)">
            <el-tooltip class="item" placement="bottom">
              <div slot="content">更新说明：{{ info.version }}<br />{{ info.date }}</div>
              <i class="el-icon-bell"></i>
            </el-tooltip>
          </div>
          <el-badge v-else :value="1" class="item" @click.native="showDialog(true)">
            <el-tooltip class="item" placement="bottom">
              <div slot="content">更新说明：{{ info.version }}<br />{{ info.date }}</div>
              <i class="el-icon-bell"></i>
            </el-tooltip>
          </el-badge>

          <div>
            <!-- <div>
              <img
                src="https://img.js.design/assets/img/6318115e8c377c40ed39a4a9.png#eea81afaad56fef55551f8b7ef2aeb3f"
                alt=""
              />
              
               <div> -->

            <!-- <div>

                </div> -->
            <span>
              {{ nickName }}
            </span>
            <div class="NAMEAREA">
              <img v-show="levelName == 'VIP客户'" src="@/assets/vip.png" alt="" />

              <img v-show="levelName == '普通客户'" src="@/assets/putong.png" alt="" />

              <img v-show="levelName == '试用版'" src="@/assets/shiyong.png" alt="" />
            </div>
          </div>
        </div>

        <!-- </div> -->
        <!-- </div> -->
      </el-header>

      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { judgeUpdate } from '../utils/util';

import update_info from './dialog/update_info';

export default {
  inject: ['showUpdateDialog'],
  data() {
    return {
      info: Object.assign(update_info[0]),
      show_badge: false,
      fullscreenLoading: false,
      value: 1,
      currentRoute: '',

      state2: '',
      searchdata: [
        // {
        //   address: "3.714",
        //   value: "张江光大(508000)",
        //   state: "-0.35%",
        // },
      ],

      menuList: Object.freeze([
        {
          id: 1,
          authName: 'Dashboard',
          path: 'dashboard',
        },
        {
          id: 8,
          authName: '市场快报',
          path: 'market-newsletter',
        },
        {
          id: 2,
          authName: '市场动态',
          path: 'marketDynamics',
        },
        {
          id: 3,
          authName: '底层资产',
          path: 'underlyingAssets',
        },
        {
          id: 4,
          authName: '财报分析',
          path: 'financialReport',
        },
        {
          id: 5,
          authName: '估值分析',
          path: 'valuationAnalysis',
        },
        {
          id: 6,
          authName: '趋势洞察',
          path: 'trendInights',
        },
        {
          id: 7,
          authName: '个股部分',
          path: 'individualShare',
        },
      ]),

      fromPath: '',

      iconsObj: Object.freeze({
        1: 'iconfont icon-cangku_kucun_o',
        2: 'iconfont icon-shichangdongtai',
        8: 'el-icon-s-order',
        3: 'iconfont icon-zichanguanli-',
        4: 'iconfont icon-caibao',
        5: 'iconfont icon-guzhixitong',
        6: 'iconfont icon-qushi',
        7: 'iconfont icon-gegu',
      }),
      //动态绑定路径
      activePath: '',
      roleName: '',
      roleGender: '',

      // 工作模式导航切换
      bWorkModel: true,

      default_active: '',

      BreadcrumbsName: '概览',

      ROOTFONTFIZE: 0,
      asideWidth: '',

      levelName: '',
      nickName: '',

      menuBackgroundColor: '',
      menutTextColor: '',
      menuActiveTextColor: '',

      switchDisabled: false,
      isActiveBeforeName: false,
    };
  },
  created() {
    this.value = localStorage.getItem('Theme') == 'light' ? true : false;
    this.fnSwitch(this.value);
    this.fnInitSearchData();
    this.fnPromiseOrder();
    // this.fnGetCodeName();
    this.fnGetBenchMark();
    this.fnGetLevelList();
    this.fnGetLevelInfo();
    this.fnGetBenchMark2();
    this.fnGetFinData();
    this.fnSetCategory();
    this.fnGetBenchMarkAndBentype();
    this.fnGetCustomColor();
    this.fnGetCustomColor2();
    this.fnGetCustomColorBenchMark();

    // 在created阶段请求左侧数据
    //在页面刷新时,赋值给路径
    this.currentRoute = '/' + window.sessionStorage.getItem('activePath');
    this.default_active = window.sessionStorage.getItem('activePath');

    // setTimeout(()=>{

    // },500)

    //  this.searchdata = []

    this.$eventBus.$on('skipPage', (data) => {
      // 之前的路由
      let activeName = localStorage.getItem('activeName');
      localStorage.setItem('activebeforeName', activeName);
      this.isActiveBeforeName = true;
      this.handleSelect(data, true);
    });
    this.$eventBus.$on('updateSwitch', (data) => {
      this.switchDisabled = data;
    });
  },
  mounted() {
    const show_badge = judgeUpdate();
    this.show_badge = show_badge;
    this.showDialog(show_badge);
    this.$eventBus.$on('setBreadcrumbs', (data) => {
      this.fnGetBreadcrumbsName(data);
    });

    this.fnGetWidth();
    window.addEventListener('resize', this.fnGetWidth);

    window.addEventListener('popstate', this.fnBackButton);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.fnGetWidth);
    window.removeEventListener('popstate', this.fnBackButton);
    this.$eventBus.$off('setBreadcrumbs');
    this.$eventBus.$off('updateSwitch');
    this.$eventBus.$off('skipPage');
  },
  methods: {
    showDialog(show) {
      this.showUpdateDialog(show, () => {
        this.show_badge = false;
      });
    },
    fnBackButton(val) {
      // console.log('home页，点击后退了');
      let menuItem = document.querySelectorAll('#menuItem');
      // menuItem.innerText
      let path = this.fromPath.replace(/\//g, '');

      // let path = window.sessionStorage.getItem("activePath");

      console.log(path, 'path玛莎拉蒂');
      let authName = this.menuList.find((node) => node.path == path).authName;

      let index = null;
      menuItem.forEach((node, idx) => {
        if (node.innerText == authName) {
          index = idx;
        }
      });
      menuItem[index].click();

      if (isActiveBeforeName) {
        let activebeforeName = localStorage.getItem('activebeforeName');
        localStorage.setItem('activeName', activebeforeName);
        this.isActiveBeforeName = false;
      }
    },
    // 获取当前主题
    // fnGetTheme(){
    //   let theme = this.$store.state.theme;
    //   console.log(theme,'主题');
    //   if(theme == 'dark'){
    //     this.menuBackgroundColor = '#3b3d42'
    //     this.menutTextColor = '#b7b7b8'
    //     this.menuActiveTextColor = '#58dee3'
    //   }else{
    //      this.menuBackgroundColor = '#ffffff'
    //     this.menutTextColor = '#594f4f'
    //     this.menuActiveTextColor = 'ffffff'
    //   }
    // },

    // 获取自定义颜色 L1
    async fnGetCustomColor() {
      let tagLevel = 1;

      const res = await this.$https.get(
        'api/chart/chartConfiguration/list?pageNum=1&pageSize=10&tagLevel=' +
          tagLevel +
          '&tagType=reits'
      );
      if (res.data.code !== 200) return this.$message.error(res.data.msg);

      let data = res.data.rows;

      // let data =  [
      //     {
      //         "chartId": "1801886357928427522",
      //         "parentName": "0",
      //         "tagLevel": "1",
      //         "tagName": "公募REITs",
      //         "tagType": "reits",
      //         "tagColor": "#e53d30",
      //         // "tagColor": "#5AE8ED",
      //         "createBy": "admin",
      //         "createTime": "2024-06-15 15:56:00"
      //     },
      //     {
      //         "chartId": "1801886433484619777",
      //         "parentName": "0",
      //         "tagLevel": "1",
      //         "tagName": "产权类",
      //         "tagType": "reits",
      //         "tagColor": "#00A6FF",
      //         "createBy": "admin",
      //         "createTime": "2024-06-15 15:56:18"
      //     },
      //     {
      //         "chartId": "1801886499846897666",
      //         "parentName": "0",
      //         "tagLevel": "1",
      //         "tagName": "经营权类",
      //         "tagType": "reits",
      //         "tagColor": "#85D907",
      //         "createBy": "admin",
      //         "createTime": "2024-06-15 15:56:34"
      //     }
      // ]
      data = data.map((node) => {
        return {
          tagName: node['tagName'],
          tagColor: node['tagColor'],
        };
      });

      this.$store.commit('setColor', data);
    },
    // 获取自定义颜色 L2
    async fnGetCustomColor2() {
      let tagLevel = 2;

      const res = await this.$https.get(
        'api/chart/chartConfiguration/list?pageNum=1&pageSize=10&tagLevel=' +
          tagLevel +
          '&tagType=reits'
      );
      if (res.data.code !== 200) return this.$message.error(res.data.msg);

      let data = res.data.rows;

      data = data.map((node) => {
        return {
          tagName: node['tagName'],
          tagColor: node['tagColor'],
        };
      });
      this.$store.commit('setColor2', data);
    },

    // 获取自定义颜色 L2
    async fnGetCustomColorBenchMark() {
      let tagType = 'benchmark';

      const res = await this.$https.get(
        'api/chart/chartConfiguration/list?pageNum=1&pageSize=10&tagType=' + tagType
      );
      if (res.data.code !== 200) return this.$message.error(res.data.msg);

      let data = res.data.rows;

      data = data.map((node) => {
        return {
          tagName: node['tagName'],
          tagColor: node['tagColor'],
        };
      });

      this.$store.commit('setColor3', data);
    },

    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1440 ? Number(ROOTFONTFIZE) - 200 : ROOTFONTFIZE;

      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      let OFFSETHEIGHT = element.offsetHeight;
      let TABPANEHEIGHT = OFFSETHEIGHT - 135;
      // this.OFFSETHEIGHT = OFFSETHEIGHT;
      localStorage.setItem('ROOTFONTFIZE', ROOTFONTFIZE / 10);
      localStorage.setItem('OFFSETHEIGHT', OFFSETHEIGHT);
      localStorage.setItem('TABPANEHEIGHT', TABPANEHEIGHT);
      // asideWidth
      // width: calc(var(--ROOTFONTFIZE) * (500 / 96)) ;

      let screenRatio = localStorage.getItem('screenRatio');
      screenRatio = Number(screenRatio) / 100;

      this.asideWidth = this.ROOTFONTFIZE * (224 / 144) + 'px';

      return;

      // console.log(this.OFFSETWIDTH,'OFFSETWIDTH');
      // let OFFSETWIDTH = element.offsetWidth;
      // this.OFFSETHEIGHT= element.offsetHeight;
      // element.style['min-height']= (OFFSETWIDTH * 0.625)+'px'
      // // element.style.height= '1200px'

      // console.log(this.OFFSETHEIGHT,'OFFSETHEIGHT');

      // rootfontsize
      let rootfontsize = OFFSETWIDTH / 1920;
      this.rootfontsize = rootfontsize;
      console.log(rootfontsize, 'rootfontsize');

      // var loginArea = document.getElementById("loginArea");
      // loginArea.style.width= (OFFSETWIDTH * 555 / 1920)+'px'

      // var element2 = document.getElementById("leftArea");
      // var width2 = element2.offsetWidth;
      // // element2.style.height= (width * 0.77)+'px'
      // element.style.height= (width * 0.67)+'px'
      // element2.style.height= (width2 * 0.77)+'px'
    },

    // 获取搜索选项
    fnGetSearchData(data) {
      //  let DATA123 = JSON.parse(localStorage.getItem("CODELIST"));
      // console.log(DATA123,'DATA123');
      this.searchdata = data.map((node) => {
        let obj = {
          address: '3.714',
          state: '-0.35%',
          value: node.reitsName + '(' + node.reitCode + ')',
        };

        return obj;
      });
    },

    async fnPromiseOrder() {
      await this.fnGetOrder();
      await this.fnGetCodeName();
    },

    // 获取排序
    async fnGetOrder() {
      let params = 'types';
      const res = await this.$https.get('/api/pic_label_order?by_type=' + params);

      if (res.data.success !== true) return this.$message.error('获取排序失败！');

      let data = res.data.data;
      data.push('benchmark');
      localStorage.setItem('ORDER', JSON.stringify(data));
    },

    // 获取股票代码
    async fnGetCodeName() {
      const { data } = await this.$https.get('api/conghua/basicInfo/list');

      // 排序
      // let order = [
      //   "公募REITs",
      //   "产权类",
      //   "物流仓储",
      //   "产业园",
      //   "保障性住房",
      //   "消费",
      //   "经营权类",
      //   "市政生态",
      //   "能源",
      //   "收费公路",
      //   "benchmark",
      // ];

      let order = JSON.parse(localStorage.getItem('ORDER'));

      // 获取大类
      let L1 = data.map((node) => {
        return node.reitsTypeL1;
      });

      let L2 = data.map((node) => {
        return node.reitsTypeL2;
      });

      L1 = [...new Set(L1)];
      L1.unshift('公募REITs');

      // 存入L1
      localStorage.setItem('L1NAME', JSON.stringify(L1));

      // L2
      L2 = [...new Set(L2)];

      // 分类
      let CLASSIFY = [...L1, ...L2];
      let tmp = [];
      order.forEach((node) => {
        let obj = CLASSIFY.filter((item) => {
          return node == item;
        });
        if (obj.length > 0) {
          tmp.push(obj[0]);
        }
      });
      CLASSIFY = tmp;
      localStorage.setItem('CLASSIFY', JSON.stringify(CLASSIFY));

      L1 = L1.map((node) => {
        let obj = {
          value: node,
          label: node,
        };
        return obj;
      });

      let BIGCATEGORY = [];

      L1.forEach((node) => {
        let children = [];
        data.forEach((item) => {
          if (node.label == item.reitsTypeL1) {
            children.push(item.reitsTypeL2);
          }
        });

        children = [...new Set(children)];
        if (children.length > 0) {
          children = children.map((node) => {
            let obj = {
              value: node,
              label: node,
            };
            return obj;
          });

          let obj2 = {
            value: node.value,
            label: node.label,
            children: children,
          };

          BIGCATEGORY.push(obj2);
        } else {
          BIGCATEGORY.push(node);
        }
      });
      this.fnSetBIGCATEGORY(BIGCATEGORY);

      // let FINALLYCATEGORY = [...BIGCATEGORY]
      // console.log(FINALLYCATEGORY,'xiangzenan');

      // 存入大类
      // localStorage.setItem("BIGCATEGORYNAME", JSON.stringify(BIGCATEGORY));

      // 存入REITs
      let REITs = [...BIGCATEGORY];
      REITs.forEach((node) => {
        if (node.children) {
          node.children.forEach((item) => {
            item.children = [];
            data.forEach((ele) => {
              if (item.label == ele.reitsTypeL2) {
                let obj = {
                  value: ele.reitCode,
                  label: ele.reitsName,
                };
                item.children.push(obj);
              }
            });
          });
        }
      });

      localStorage.setItem('REITsNAME', JSON.stringify(REITs));

      localStorage.setItem('CODELIST', JSON.stringify(data));
      this.fnGetSearchData(data);

      this.$store.commit('setCodeList', data);

      let obj = {};
      data.forEach((ele) => {
        let left = ele.reitCode;
        let right = ele.reitsName;
        obj[left] = right;
      });

      localStorage.setItem('CODEobj', JSON.stringify(obj));

      this.$store.commit('setCodeName', obj);

      // 存 MAP_L2
      let MAP_L2 = data.map((node) => {
        return {
          name: node.reitCode,
          value: node.reitsTypeL2,
        };
      });

      localStorage.setItem('MAP_L2', JSON.stringify(MAP_L2));
    },

    // 存入大类
    fnSetBIGCATEGORY(data) {
      let dataL1 = JSON.parse(JSON.stringify(data));

      dataL1 = dataL1.map((node) => {
        if (node.children) {
          delete node.children;
        }
        return node;
      });

      // 存入大类
      localStorage.setItem('FINALLYCATEGORY', JSON.stringify(data));
      localStorage.setItem('FINALLYCATEGORYL1', JSON.stringify(dataL1));

      return;
      data.forEach((node) => {
        if (node.children && node.children.children) {
          delete node.children.children;
        }
      });

      data = data.map((node) => {
        if (node.label !== '公募REITs') {
          let obj = {
            label: node.label,
            value: node.value,
          };
          node.children.unshift(obj);
          return node;
        } else {
          return node;
        }
      });
    },

    // 获取BENCHMARK
    async fnGetBenchMark() {
      const res = await this.$https.get('/api/button_benchmark_diff_return');

      if (res.data.success !== true) return this.$message.error('获取BenchMark失败！');

      let data = res.data.data;

      let benchmark = data.map((node) => {
        return {
          value: node,
          label: node,
        };
      });

      localStorage.setItem('BENCHMARKNAME', JSON.stringify(benchmark));
    },

    // 获取BENCHMARK2
    async fnGetBenchMark2() {
      const res = await this.$https.get('/api/button_benchmark_in_market_line');
      if (res.data.success !== true) return this.$message.error('获取BenchMark失败！');

      let data = res.data.data;
      // let data = [
      //   "CN1YR",
      //   "CN2YR",
      //   "CN3YR",
      //   "CN5YR",
      //   "CN10YR",
      //   "US1YR",
      //   "US2YR",
      //   "US3YR",
      //   "US5YR",
      //   "US10YR",
      //   "JP3YR",
      //   "JP5YR",
      //   "JP10YR",
      //   "UK10YR",
      //   "现货黄金",
      //   "现货白银",
      //   "沪深300",
      //   "中证500",
      //   "中证100",
      //   "大宗商品",
      //   "公用指数",
      //   "上证50",
      //   "红利指数",
      //   "深证红利",
      //   "中证新能源指数",
      //   "红利低波100",
      //   "中证红利",
      //   "股息龙头",
      //   "全指公用",
      //   "上证公用",
      //   "高股息策略",
      //   "东证红利低波",
      //   "国企红利",
      //   "sw_公用事业",
      //   "高收益债ETFs(全)CNY",
      //   "sh_十年国债ETF",
      //   "nhf_南华商品指数",
      //   "国证交通运输行业指数",
      //   "运输指数",
      //   "国证物流",
      //   "sw_高速公路",
      //   "绿色能源",
      //   "电力指数",
      //   "国证电力公用事业行业指数",
      //   "sw_电力",
      //   "cni_风光装备",
      //   "sw_物流",
      //   "sw_产业地产",
      //   "cj_园区开发(长江)",
      //   "sw_水务及水治理",
      //   "sw_环保",
      //   "cj_垃圾焚烧(长江)",
      //   "日经225",
      //   "标普500",
      //   "恒生指数",
      //   "恒生地产分类指数",
      //   "道琼斯工业平均",
      // ];

      let benchmark = data.map((node) => {
        return {
          value: node,
          label: node,
        };
      });

      localStorage.setItem('BENCHMARKNAME2', JSON.stringify(benchmark));
    },

    // 获取Benchmark和bentype
    async fnGetBenchMarkAndBentype() {
      const res = await this.$https.get('api/conghua/benchmarkData/simple-list');
      if (res.status !== 200) return this.$message.error('获取BenchMark失败！');

      let data = res.data;
      localStorage.setItem('BENCHMARKNAMEBENTYPE', JSON.stringify(data));
    },

    // 获取报告期
    async fnGetFinData() {
      const { data } = await this.$https.get('api/conghua/finData/list');

      let periodData = data.map((node) => {
        return {
          value: node,
          label: node,
        };
      });

      localStorage.setItem('PERIODDATA', JSON.stringify(periodData));
    },

    // 存入类别选择数组
    fnSetCategory() {
      let data = [
        {
          value: '产权类',
          label: '产权类',
          children: [
            {
              value: '物流仓储',
              label: '物流仓储',
            },
            {
              value: '产业园',
              label: '产业园',
            },
            {
              value: '保障性住房',
              label: '保障性住房',
            },
            {
              value: '消费',
              label: '消费',
            },
          ],
        },
        {
          value: '经营权类',
          label: '经营权类',
          children: [
            {
              value: '市政生态',
              label: '市政生态',
            },
            {
              value: '能源',
              label: '能源',
            },
            {
              value: '收费公路',
              label: '收费公路',
            },
          ],
        },
        {
          value: '公募REITs',
          label: '公募REITs',
        },
      ];

      localStorage.setItem('CATEGORYDATA', JSON.stringify(data));
    },

    //
    async fnGetLevelList() {
      const res = await this.$https.post('api/level/get-level-list');

      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
        setTimeout(() => {
          this.$router.push('/login');
        }, 2000);
      } else {
        let levelList = JSON.stringify(res.data.data);
        window.sessionStorage.setItem('levelList', levelList);
      }

      //  if (res.data.code !== 200) return
    },

    async fnGetLevelInfo() {
      const res = await this.$https.get('api/system/user/getInfo');
      if (res.data.code !== 200) return this.$message.error(res.data.msg);

      let levelName = res.data.data.user.levelName;
      let nickName = res.data.data.user.nickName;

      this.levelName = levelName;
      this.nickName = nickName;
      window.sessionStorage.setItem('levelName', levelName);
    },

    fnGetBreadcrumbsName(data) {
      this.BreadcrumbsName = data;
      // this.BreadcrumbsName = window.sessionStorage.getItem("BreadcrumbsName");
    },

    handleSelect(item, flag) {
      if (!flag) {
        this.state2 = item.value;
        let val = item.value;
        let arr = val.split('(');
        let name = arr[0];
        this.$store.state.CODELIST.forEach((node) => {
          if (node.reitsName == name) {
            name = node.reitsNameSe;
          }
        });
        window.sessionStorage.setItem('CURRENTCODE', arr[1].slice(0, -1));
        window.sessionStorage.setItem('CURRENTNAME', name);
      } else {
        function isNumber(str) {
          return /^\d+$/.test(str);
        }

        if (!isNumber(item[1].split('.')[0])) return;

        let name = item[0];
        this.$store.state.CODELIST.forEach((node) => {
          if (node.reitsName == name) {
            name = node.reitsNameSe;
          }
        });
        window.sessionStorage.setItem('CURRENTCODE', item[1]);
        window.sessionStorage.setItem('CURRENTNAME', name);
      }
      this.$eventBus.$emit('getIndividualShare');

      let menuItem = document.querySelectorAll('#menuItem');
      menuItem[menuItem.length - 1].click();

      this.saveNavState({ path: 'individualShare' });
      this.$router.push('/individualShare');
    },

    fnInitSearchData() {},

    querySearch(queryString, cb) {
      let data = this.searchdata;
      data = data.filter((node) => {
        return node.value.includes(queryString);
      });
      var results = data;
      cb(results);
    },

    async logout() {
      // const res = await this.$https.get(domainApi + '/logout?_ajax=1')
      // this.judgeState(res)
      // if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
      // this.$message.success(res.data.msg)
      // this.$router.push('/login')
      // 退出就是清除token
      //  let value=this.$cookies.remove('PHPSESSID')
      // console.log(value,'value');
      // this.$cookies.set('PHPSESSID','')
      // let all=this.$cookies.keys()
      // console.log(all,'all');
      //    let val=this.$cookies.get('PHPSESSID')
      // console.log(val,'val');
      // window.sessionStorage.clear();
      // console.log('token');
      // this.$router.push('/login')
    },

    // 点击按钮,切换菜单的折叠
    // toggleCollapse() {
    //   this.isCollapse = !this.isCollapse
    // },
    // //保存路径
    saveNavState(data) {
      let val = window.sessionStorage.getItem('CURRENTCODE');
      if (!val) {
        window.sessionStorage.setItem('CURRENTCODE', '508000.SH');
        window.sessionStorage.setItem('CURRENTNAME', '张江光大');
      }

      window.sessionStorage.setItem('activePath', data.path);
      localStorage.setItem('activeName', 'first');

      this.currentRoute = '/' + data.path;
    },

    logout() {
      this.$router.push('/login');
    },

    fnSwitch(val) {
      // this.switchDisabled = !this.switchDisabled
      // true - light
      if (val) {
        this.$store.commit('setTheme', 'light');
        localStorage.setItem('Theme', 'light');
        // this.menuBackgroundColor = "#ffffff";
        this.menutTextColor = '#594f4f';
        this.menuActiveTextColor = 'ffffff';
      } else {
        this.$store.commit('setTheme', 'dark');
        localStorage.setItem('Theme', 'dark');
        // this.menuBackgroundColor = "#3b3d42";
        this.menutTextColor = '#b7b7b8';
        this.menuActiveTextColor = '#58dee3';
      }
    },
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      // 保存下from地址
      setTimeout(() => {
        this.fromPath = from.path;
      }, 100);
      this.currentRoute = to.path;
    },
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
    ...mapState({
      isLogin: (state) => state.isLogin,
    }), //等同于==>...mapState(['isLogin']);映射 this.isLogin 为 this.$store.state.isLogin
  },
};
</script>

<style lang="less" scoped>
/* el-table 滚动条样式 */
// .home_root .home-container_work .bottom::-webkit-scrollbar{
//     width: 13px;
//  }
// .home_root .home-container_work .bottom::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   background: rgba(0, 0, 0, 0.2);
// }

// :root{
//   --aa:'pink'
// }

// .aaa{
//   width: 500px;
//   height: 20px;
//   background-color: pink;
// }

// .bbb{
//   width: calc(var(--ROOTFONTFIZE) * (500 / 96)) ;
//   height: 20px;
//   background-color: orange;
// }

.home-container {
  height: 100%;
  min-width: 1700px;
  min-height: 900px;
  background-color: var(--bigger-card-bgc);
}

.el-header {
  // background-color: #373d41;
}

.el-main {
  background-color: var(--bigger-card-bgc);
  padding: 0 !important;
  // margin-top: 16px;
  // margin-left: 20px;
  // border-radius: 20px;
  margin-top: 16px;
  margin-left: 20px;
  border-radius: 20px;
  height: 100%;
  box-sizing: border-box;
}

.aside {
  // background-color: rgba(29, 31, 37, 1) !important;
  background-color: var(--el-aside-bgc) !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-top: 26px;
  height: auto;
  padding-top: calc(var(--ROOTFONTFIZE) * (26 / 144));
  overflow-y: hidden;
}

.el-menu {
  border: 0 !important;
  // width: 178px !important ;
  width: calc(var(--ROOTFONTFIZE) * (178 / 144)) !important ;
  background-color: var(--menuBackgroundColor);
  background-color: transparent;
}

.el-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  // font-size: 16px;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;

  // margin-bottom: 20px;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.el-menu-item:hover {
  background-color: var(--el-menuitem-hover-bgc) !important;
  border-radius: 8.83px !important;
}

.el-menu-item i {
  // margin-right: 20px;
  // font-size: 18px;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 700;
  display: block;
}

/* el-menu 选中背景色修改 */
.home-container .el-menu-item.is-active {
  // background-color: rgba(8, 46, 102, 1) !important;
  // color: rgba(137, 185, 255, 1) !important;

  background-color: var(--el-menuitem-active-bgc) !important;
  color: var(--el-menuitem-active-color) !important;
  border-radius: 8.83px;
}

.comTitle {
  // width: 178px;
  // padding-bottom: 20px;
  width: calc(var(--ROOTFONTFIZE) * (178 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));

  border-bottom: 1px solid rgba(96, 96, 96, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 50px;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.comTitle div {
  background-image: url('../assets/logo.png');
  background-size: contain;
  width: calc(var(--ROOTFONTFIZE) * (145 / 144));
  height: calc(var(--ROOTFONTFIZE) * (50 / 144));
  background-repeat: no-repeat;
}

.logoLight {
  background-image: url('../assets/logo_light.png') !important;
  //background-image: url("../assets/logo.png") !important;
}

.comTitle img {
  width: 100%;
  height: 100%;
}

.bottomArea {
  // bottom: 20px;
  // width: 178px;
  margin-top: calc(var(--ROOTFONTFIZE) * (100 / 144));
  width: calc(var(--ROOTFONTFIZE) * (178 / 144));
  border-top: 1px solid rgba(97, 97, 97, 1);
}

.bottomArea div {
  color: var(--el-aside-bottom-color);
  // font-size: 14.07px;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.09px;
  display: flex;
  align-items: center;
  // margin-left: 15px;
  margin-left: calc(var(--ROOTFONTFIZE) * (15 / 144));
}

.bottomArea div:nth-child(1) {
  // margin-top: 20px;
  // margin-bottom: 38px;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (38 / 144));
}

.bottomArea div i {
  // font-size: 24px;
  // margin-right: 16px;
  font-size: calc(var(--ROOTFONTFIZE) * (24 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (16 / 144));
}

// =======================
.app-breadcrumb1 {
  // height: 72px;
  height: calc(var(--ROOTFONTFIZE) * (72 / 144));
  background-color: var(--el-header-bgc);

  padding: 0px 0;
  // padding-left: 20px;
  // margin-left: 20px;
  // border-radius: 20px;

  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
}

.breadcrumb {
  color: var(--el-header-text-color);
  // font-size: 26px;
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 600;
  display: flex;
  align-items: center;
}

.right_area {
  display: flex;
  align-items: center;
  // margin-right: 60px;
  margin-right: calc(var(--ROOTFONTFIZE) * (60 / 144));
}

.right_area div:nth-child(1) {
  // margin-right: 32px;
  margin-right: calc(var(--ROOTFONTFIZE) * (32 / 144));
}

.right_area div:nth-child(3) {
  // background-color: #282c38;
  // background-color: #3b3d42;
  // background-color: var(--el-header-inform-color);
  background-color: var(--el-header-SsearchStyle-bgc);
  // width: 36px;
  // height: 36px;
  width: calc(var(--ROOTFONTFIZE) * (36 / 144));
  height: calc(var(--ROOTFONTFIZE) * (36 / 144));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: 20px;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.right_area div:nth-child(3) i {
  color: var(--el-header-iconfont-color);
}

.right_area div:nth-child(4) {
  background-color: #282c38;
  background-color: #282c38;
  background-color: var(--el-header-SsearchStyle-bgc);

  // width: 123px;
  // height: 36px;
  width: auto;
  margin: 0 !important;
  // height: calc(var(--ROOTFONTFIZE) * (36 / 144));
  // height: auto;
  height: calc(var(--ROOTFONTFIZE) * (36 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (36 / 144));

  border-radius: calc(var(--ROOTFONTFIZE) * (18 / 144));
  display: flex;
  justify-content: center;
  align-items: center;

  padding: calc(var(--ROOTFONTFIZE) * (0 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-size: 14px;
  color: var(--el-header-iconfont-color);
}

.right_area div:nth-child(4):hover {
  // cursor: pointer;
}

// .right_area div:nth-child(4) div:nth-child(1) {
//   width: calc(var(--ROOTFONTFIZE) * (30 / 144));
//   height: calc(var(--ROOTFONTFIZE) * (30 / 144));
//   margin-left: calc(var(--ROOTFONTFIZE) * (6 / 144));
//   position: relative;

// }

// switch=====================================

// search=====================================
.SsearchStyle {
  // background-color: var(--el-header-SsearchStyle-bgc);
  padding: 0 !important;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

/deep/.SsearchStyle .el-input__inner {
  width: calc(var(--ROOTFONTFIZE) * (317 / 144));
  background-color: var(--el-header-SsearchStyle-bgc) !important;
  border-radius: 18px;
  margin-left: 0 !important;
  height: calc(var(--ROOTFONTFIZE) * (36 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (36 / 144));
  border: none !important;
  color: var(--el-header-SsearchStyle-color) !important;
}

::v-deep .SsearchStyle .el-input__inner::-webkit-input-placeholder {
  // color: #1573fe !important;
  color: var(--el-search-placeholder-color) !important;
}

// /deep/
//   .el-scrollbar.el-autocomplete-suggestion__wrap
//   .el-scrollbar__wrap
//   .el-input__inner {
//   background-color: pink !important;
// }

/deep/.el-input__suffix-inner {
  display: flex !important;
  align-items: center !important;
  color: var(--el-header-iconfont-color);
}

/deep/.el-icon-search {
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144)) !important;
  font-weight: 700 !important;
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144)) !important;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144)) !important;
}

/deep/.el-icon-bell {
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144)) !important;
  font-weight: 700 !important;
}

.logout:hover {
  cursor: pointer;
}

.el-main {
  box-sizing: border-box;
}

// /==================================================================================
.overview {
  color: var(--primary-color);
  margin-left: calc(var(--ROOTFONTFIZE) * (8 / 144));
}

.NAMEAREA {
  width: 70px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NAMEAREA img {
  width: 100%;
  height: 100%;
}

/deep/ .el-tabs__nav {
  margin-left: 18px;
  margin-top: -10px;
}
</style>
