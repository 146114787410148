<template>
  <div id="rootchart" class="login_container">
    <video
      class="bg_cover"
      autoplay
      loop
      muted
      src="https://conghua-pics.obs.cn-north-4.myhuaweicloud.com/database/static/poster22.mp4"></video>

    <div class="top">
      <img src="@/assets/logo.png" alt="" />
    </div>

    <div class="main">
      <div class="left_case">
        <div class="big_title">不动产金融领域的科技洞见者</div>
        <img class="splite" src="@/assets/login/splite.png" alt="" />
        <div class="desc">
          在泛不动产时代新一轮的基金化机会中，通过深度理解不同底层业务模式，跨越一二级市场认知与投研能力，前沿高效的综合数据与科学方法<br />
          <br />
          为另类投资领域的一二级广泛参与者，提供富有洞见、深掘底层逻辑的投资助力，穿越不确定性，把握投资机遇。
        </div>
      </div>
      <div class="loginArea">
        <div class="loginArea_main">
          <input class="username" v-model="loginForm.username" placeholder="请输入账号" />
          <input
            class="password"
            v-model="loginForm.password"
            placeholder="请输入密码"
            type="password" />
          <div class="verificationCode">
            <span>
              <el-checkbox v-model="checked">记住密码</el-checkbox>
            </span>
          </div>
          <div class="loginBTN" @click="Login" type="primary"></div>

          <div class="loginInfo"><span>登录即代表同意</span> <span class="yinsi" @click="showYinSi">《隐私权政策》</span></div>

          <div class="forget">忘记密码？</div>
        </div>
      </div>
    </div>
    <!--隐私弹窗-->
    <yinsi :show.sync="show_yinsi"></yinsi>

  </div>
</template>

<script>
import yinsi from "./dialog/yinsi"
export default {
  components: {
    yinsi
  },
  data() {
    return {
      screenRatio: 1,
      flag: false,

      codeImgSrc: '',
      codeImgResult: '',
      url: '',
      token: '',
      // 这是登录表单的数据绑定对象
      loginForm: {
        username: '',
        password: '',
      },
      verifiCode: '',
      // 这是表单的验证规则对象
      stateJudage: this.$route.query.data,
      checked: false,
      activeName: 'second',

      OFFSETWIDTH: 300,
      OFFSETHEIGHT: 200,
      ROOTFONTFIZE: 0,
      show_yinsi: false,
    };
  },
  beforeCreate() {},
  created() {
    this.fnGetUnits();
    this.fnGetRatio();
    this.fnGetPeriod();
  },
  mounted() {},
  methods: {
    showYinSi(){
      this.show_yinsi = true;
    },
    //获取屏幕缩放比例
    fnGetRatio() {
      function getRatio() {
        var ratio = 0;
        var screen = window.screen;
        var ua = navigator.userAgent.toLowerCase();
        if (window.devicePixelRatio !== undefined) {
          ratio = window.devicePixelRatio;
        } else if (~ua.indexOf('msie')) {
          if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
          }
        } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
          ratio = window.outerWidth / window.innerWidth;
        }
        if (ratio) {
          ratio = Math.round(ratio * 100);
        }
        return ratio;
      }

      this.screenRatio = getRatio();

      localStorage.setItem('screenRatio', this.screenRatio);
    },

    async Login() {
      // window.sessionStorage.setItem("activePath", "dashboard");
      // this.$router.push("/home");
      // return;
      let username = this.loginForm.username;
      let password = this.loginForm.password;
      if (username == '') {
        this.$message.error('用户名不能为空');
        return;
      }
      if (password == '') {
        this.$message.error('密码不能为空');
        return;
      }

      const { data } = await this.$https.post(
        'api/auth/customer/login?userName=' + username + '&password=' + password
      );
      if (data.code !== 200) return this.$message.error(data.msg);
      this.$message.success('欢迎登录葱花投研！');
      window.sessionStorage.setItem('activePath', 'dashboard');
      let token = data.data.access_token;
      window.sessionStorage.setItem('token', token);
      this.$router.push('/home');
    },

    // 获取财务 - 单位
    async fnGetUnits() {
      const res = await this.$https.get('api/v2/conghua/finTableAssumption/list');

      let data = res.data;

      localStorage.setItem('UNITS', JSON.stringify(data));
    },
    // 获取财报时间
    async fnGetPeriod() {
      const res = await this.$https.get('api/v2/conghua/findata/period/list');

      let data = res.data;
      Object.keys(data).forEach((k) => {
        console.log(data, k);
        data[k] = (data[k] || []).map((item) => item.value);
      });
      console.log(555555555555, data);
      localStorage.setItem('period2', JSON.stringify(data));
    },
  },
};
</script>

<style lang="less" scoped>
@vh: 1 /10.8vh;

.login_container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: url('../assets/login/bg.jpg') no-repeat;
  background-size: cover;
  position: relative;

  .bg_cover {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
    z-index: 100;
  }

  .bg_cover_shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .top {
    position: absolute;
    top: @vh * (60);
    left: @vh * (80);
    z-index: 200;

    img {
      width: @vh * (200);
      height: auto;
    }
  }

  .main {
    width: 90%;
    display: flex;
    position: absolute;
    top: 250 * @vh;
    width: 1468 * @vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;

    .left_case {
      width: @vh * (966);
      margin-right: @vh * (72);

      .splite {
        position: absolute;
        top: 190 * @vh;
        width: 40 * @vh;
        height: 6 * @vh;
      }

      .big_title {
        position: absolute;
        top: @vh * (90);
        font-size: @vh * (60);
        color: #ffffff;
      }

      .desc {
        position: absolute;
        width: @vh * (780);
        top: 231 * @vh;
        font-size: @vh * (22);
        color: #ffffff;
      }
    }

    .loginArea {
      position: absolute;
      top: 0;
      right: 0;
      width: @vh * (502);
      height: 582 * @vh;
      background: url('../assets/login/login_bg.png') no-repeat;
      background-size: cover;

      .loginArea_main {
        position: relative;

        .username {
          outline: none;
          position: absolute;
          left: 110 * @vh;
          top: 175 * @vh;
          border: none;
          width: 320 * @vh;
          height: 48 * @vh;
          font-size: 16 * @vh;
        }

        .password {
          outline: none;
          position: absolute;
          left: 110 * @vh;
          top: 267 * @vh;
          border: none;
          width: 320 * @vh;
          height: 48 * @vh;
          font-size: 16 * @vh;
        }

        .loginBTN {
          position: absolute;
          top: 410 * @vh;
          left: 60 * @vh;
          width: 383 * @vh;
          height: 51 * @vh;
          background: url('../assets/login/login_btn.png') no-repeat;
          background-size: cover;
          cursor: pointer;
        }

        .forget {
          position: absolute;
          top: 340 * @vh;
          right: 60 * @vh;
          color: #00c9c3;
          font-size: @vh * (14);
          cursor: pointer;
        }

        .verificationCode {
          position: absolute;
          top: 333 * @vh;
          left: 60 * @vh;

          .el-checkbox {
            color: #fdfdfd;
            margin-left: 0px;
          }
        }

        .loginInfo {
          position: absolute;
          top: 450 * @vh;
          left: 50%;
          transform: translateX(-50%);
          font-size: @vh * (16);
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #f7f7f7;
          text-align: center;
          padding-top: @vh * (30);
          .yinsi {
            cursor: pointer;
          }
        }

        .loginInfo span:nth-child(1) {
          color: #999999;
          font-weight: 700;
        }

        .loginInfo span:nth-child(2) {
          color: #00c9c3;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
