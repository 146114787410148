<template>
  <div class="container" id="rootchart" :style="cssVars">
    <div class="CARD" v-show="true">
      <!-- <div class="CARD_tit">鹏华深圳能源 REITs  | 180401.SZ</div> -->
      <div class="CARD_tit">
        {{ STOCKNAME }} | <span class="CARD_tit_code">{{ CODE }}</span>
      </div>

      <div class="CARD_body">
        <div class="CARD_body_first">
          <span>
            <CButtonsSeparate
              @Cbtns_sep="fnGetCbtnsSep"
              class="CARD_body_btn_sep"
              :dButtons1="dButtons_separrate"
              :dButtons1_current="dButtons_separrate_current" />
          </span>

          <!-- <download-excel v-show="bButtons_separrate_card3" :data="dRestrictHolder_table"
                       :fields="json_fields2" :header="title2" name="解禁份额.xls">
                      <i class="el-icon-download"></i>
                 </download-excel> -->
        </div>

        <div v-show="bButtons_separrate_card1">
          <div class="TITLESTYLE">
            历史分红

            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads"
                @fnButtondownload="fnButtonDL8_2" />

              <span style="display: none">
                <download-excel
                  id="downloadExcel8_21"
                  :data="dDivTable_table"
                  :fields="json_fields1"
                  :header="title1"
                  name="历史分红.xls">
                </download-excel>

                <!-- 
                        <download-excel
                      :data="json_fields_data1_data"
                      :fields="json_fields_data1"
                      header="历史数据"
                      name="历史数据.xls"
                    >
                    </download-excel> -->
              </span>
            </div>
          </div>
          <table ref="table8_2">
            <tr class="table_first LISHIFENHONG">
              <th v-for="(node, index) in dDivTable_th" :key="index">
                {{ node }}
              </th>
            </tr>
            <tr class="table_main" v-for="(node, index) in dDivTable_td" :key="index">
              <td v-for="(e, idx) in node" :key="idx">{{ e }}</td>
            </tr>
          </table>

          <div class="TITLESTYLE">
            基金净值

            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads"
                @fnButtondownload="fnButtonDL8_3" />

              <span style="display: none">
                <download-excel
                  :data="dNavTable_table"
                  id="downloadExcel8_3"
                  :fields="json_fields2"
                  :header="title2"
                  name="基金净值.xls">
                </download-excel>
              </span>
            </div>
          </div>
          <table ref="table8_3">
            <tr class="table_first LISHIFENHONG">
              <th v-for="(node, index) in dNavTable_th" :key="index">
                {{ node }}
              </th>
            </tr>
            <tr class="table_main" v-for="(node, index) in dNavTable_td" :key="index">
              <td v-for="(e, idx) in node" :key="idx">{{ e }}</td>
            </tr>
          </table>
        </div>

        <div v-show="bButtons_separrate_card3">
          <div class="TITLESTYLE">
            解禁集中到期时间

            <div class="downloadBtns pr">
              <span @click="fnOpenDialog" class="iconfont icon-fangda icon-fangdaPr"></span>

              <div class="jiejinjizhongxiazai">
                <CButtonsDownloadSingle
                  :dButtonsDownloads="dButtonsDownloads2"
                  @fnButtondownload="fnButtonDL8_4" />
              </div>
            </div>
          </div>

          <div class="pr">
            <div class="canvasArea" ref="chartColumn1" style="width: 100%; height: 390px"></div>
          </div>

          <div class="downloadBtns2">
            <CButtonsDownloadSingle
              :dButtonsDownloads="dButtonsDownloads1"
              @fnButtondownload="fnButtonDL8_20" />

            <span style="display: none">
              <download-excel
                id="downloadExcel8_20"
                :data="json_fields_table_data"
                :fields="json_fields_table"
                header="解禁集中到期时间_表格"
                name="解禁集中到期时间_表格.xls">
              </download-excel>
            </span>
          </div>

          <!-- @row-click="fnCell" -->
          <el-table
            class="table1"
            :header-cell-style="styleBindFun"
            :data="restrictHolderData"
            style="width: 100%"
            :height="restrictHolderData.length == 0 ? null : 580">
            <el-table-column
              prop="对象名称"
              show-overflow-tooltip
              align="left"
              label="对象名称"
              width="300">
            </el-table-column>

            <el-table-column prop="机构名称" show-overflow-tooltip label="机构名称" align="left">
            </el-table-column>

            <el-table-column
              align="center"
              sortable
              prop="限售期(月)"
              label="限售期(月)"
              width="150">
            </el-table-column>
            <el-table-column align="center" sortable prop="解禁日期" label="解禁日期" width="150">
            </el-table-column>

            <el-table-column
              align="center"
              width="160"
              sortable
              prop="限售份额(万份)"
              label="限售份额(万份)">
              <template slot-scope="scope">
                <span>{{ toLocaleString(scope.row['限售份额(万份)']) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="100" prop="交易场所" label="交易场所">
            </el-table-column>
            <el-table-column
              align="left"
              show-overflow-tooltip
              width="300"
              prop="限售对象类型"
              label="限售对象类型">
            </el-table-column>
            <el-table-column
              align="center"
              width="160"
              sortable
              prop="限售金额(万元)"
              label="限售金额(万元)"
              show-overflow-tooltip>
              <template slot-scope="scope">
                <!-- <span>{{ toLocaleString(scope.row['限售金额(万元)']) }}</span> -->
                <span>{{ scope.row['限售金额(万元)'] }}</span>
              </template>
            </el-table-column>
          </el-table>

          <!-- <table class="UnlockingShares">
            <tr class="table_first">
              <th
                v-for="(node, index) in dRestrictHolder_th"
                :key="index"
                :class="index == 3 ? 'w100' : ''"
              >
                {{ node }}
              </th>
            </tr>
            <tr
              class="table_main"
              v-for="(node, index) in dRestrictHolder_td"
              :key="index"
            >
              <td class="special_td"  v-for="(e, idx) in node" :key="idx">
                {{ e }}
              </td>
            </tr>
          </table> -->
        </div>
        <!-- 投资者结构 -->
        <chartColumnInvestorStructure :show.sync="bButtons_separrate_card4"/>
      </div>
    </div>

    <CDialog ref="CDialog" :DIALOGHEIGHT="height_dialog" :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div class="canvasArea" ref="chartColumn1_dialog" style="width: 100%; height: 100%"></div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CSelects from '@/components/Basic/CSelects';
import CSelect from '@/components/Basic/CSelect';
import CCategoryRadio from '@/components/Basic/CCategoryRadio';
import CButtons from '@/components/Basic/CButtons';
import CTimeButtons from '@/components/Basic/CTimeButtons';
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CButtonsDownload from '@/components/Basic/CButtons_download';
import CButtonsDownloadSingle from '@/components/Basic/CButtons_download_single';
import CDialog from '@/components/Basic/CDialog';


import html2canvas from 'html2canvas';

import { fnReturnTableStyle, fnDownloadBgc } from '@/utils/util';
//投资者结构
import chartColumnInvestorStructure from '@/components/IndividualShare/common/chartColumnInvestorStructure';

export default {
  // props: {
  //   isFlag: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  data() {
    return {
      
      //end

      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      ROOTFONTFIZE: 0,
      TABPANEHEIGHT: 0,

      // 下载
      dDownloadSingle1: ['下载图片'],
      dDownloadSingle2: ['下载表格'],
      json_fields_table: {},
      json_fields_table_data: [],

      dialogVisible: false,
      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '71vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',

      dButtonsDownloads: [
        {
          id: '241083',
          name: '下载图片',
        },
        {
          id: '241084',
          name: '下载表格',
        },
      ],

      dButtonsDownloads1: [
        // {
        //   id:'241083',
        //   name:'下载图片'
        // },
        {
          id: '241084',
          name: '下载表格',
        },
      ],

      dButtonsDownloads2: [
        {
          id: '241083',
          name: '下载图片',
        },
      ],

      title1: '历史分红',
      json_fields1: {
        年份: '年份',
        权益登记日: '权益登记日',
        除权日: '除权日',
        每基金单位分红: '每基金单位分红',
        分红发放日: '分红发放日',
      },
      dDivTable_table: [],

      title2: '基金净值',
      json_fields2: {
        报告期: '报告期',
        单位净值: '单位净值',
        累计净值: '累计净值',
      },
      dNavTable_table: [],

      title3: '基金净值',
      json_fields3: {
        报告期: '报告期',
        单位净值: '单位净值',
        累计净值: '累计净值',
      },
      dRestrictHolder_table: [],

      CODE: '',
      STOCKNAME: '',

      basis_value: '',
      basis_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      days_value: '',
      days_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      level_value: '',
      level_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      classA_value: '',
      classA_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      classB_value: '',
      classB_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      radio1: '',
      radio2: '',

      dBtn1: ['3M', '6M', '1Y', 'YTD', 'MAX'],
      activeBtn1: '3M',

      category_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      //================ new =================================
      // dButtons_separrate:['数值','比例'],
      // dButtons_separrate_current:'数值',

      dButtons_separrate: ['历史分红', '解禁份额', '投资者结构'],
      dButtons_separrate_current: '历史分红',

      bButtons_separrate_card1: true,
      bButtons_separrate_card3: false,
      bButtons_separrate_card4: false,

      chartColumn1: null,
      chartColumn1_dialog: null,

      dDivTable_th: [],
      dDivTable_td: [],

      dNavTable_th: [],
      dNavTable_td: [],

      dRestrictHolder_th: [],
      dRestrictHolder_td: [],

      dRestrictHolder_bar: [],

      restrictHolderData: [],
    };
  },
  created() {
    // if(this.isFlag){
    //   this.fnGetCbtnsSep('历史分红')
    // }
  },
  mounted() {
    // this.funResize();
    window.addEventListener('resize', this.funResize);
    this.fnGetWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
    this.chartColumn1 && this.chartColumn1.dispose();
    this.chartColumnInvestorStructure && this.chartColumnInvestorStructure.dispose();
  },
  methods: {
    toLocaleString(e) {
      return e.toLocaleString();
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },

    //  表头方法
    styleBindFun() {
      let val = this.$store.state.theme == 'dark' ? true : false;
      return fnReturnTableStyle(val);
    },
    funResize() {
      this.fnGetWidth();
      if (this.chartColumn1) {
        this.chartColumn1.resize();
      }
    },

    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      let OFFSETHEIGHT = element.offsetHeight;
      this.TABPANEHEIGHT = OFFSETHEIGHT - 135;

      // var chartColumn1 = document.getElementById("chartColumn1");
      // chartColumn1.style.height = this.ROOTFONTFIZE * (371 / 144) + "px";

      // var chartColumn2 = document.getElementById("chartColumn2");
      // chartColumn2.style.height = this.ROOTFONTFIZE * (360 / 144) + "px";

      // var chartColumn4 = document.getElementById("chartColumn4");
      // chartColumn4.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn5 = document.getElementById("chartColumn5");
      // chartColumn5.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn3 = document.getElementById("chartColumn3");
      // chartColumn3.style.height = this.ROOTFONTFIZE * (358 / 144) + "px";

      // var chartColumnInvestorStructure = document.getElementById('chartColumnInvestorStructure');
      // chartColumnInvestorStructure.style.height = this.ROOTFONTFIZE * (300 / 144) + 'px';

      return;
    },

    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart1(true);
      });
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      }
    },

    // 下载 start
    fnButtonDL8_2(val) {
      console.log(val, 'val3123');
      if (val == '下载图片') {
        this.FnDownload8_2();
      } else {
        document.getElementById('downloadExcel8_21').click();
        // document.getElementById("downloadExcel8_3").click();
      }
    },

    FnDownload8_2() {
      let h = this.$refs.table8_2.scrollHeight;
      let w = this.$refs.table8_2.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.table8_2, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '历史分红'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnButtonDL8_3(val) {
      if (val == '下载图片') {
        this.FnDownload8_3();
      } else {
        document.getElementById('downloadExcel8_3').click();
      }
    },

    FnDownload8_3() {
      let h = this.$refs.table8_3.scrollHeight;
      let w = this.$refs.table8_3.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.table8_3, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '基金净值'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnButtonDL8_4(val) {
      this.FnDownload8_4();
    },
    FnDownload8_4() {
      let h = this.$refs.chartColumn1.scrollHeight;
      let w = this.$refs.chartColumn1.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn1, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '解禁集中到期时间'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnButtonDL8_20(val) {
      document.getElementById('downloadExcel8_20').click();
    },

    // 下载 end

    // =====================================================================================================
    //  1. 表格 1
    async fnGetDivTable() {
      let code = this.CODE;
      this.dDivTable_th = [];
      this.dDivTable_td = [];
      const { data } = await this.$https.get('/api/div_table?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);
      let dataTmp = data.data;

      if(Object.keys(dataTmp).length==0){
        return;
      }
      this.dDivTable_table = dataTmp;

      let arr1 = Object.keys(dataTmp[0]);
      let arr2 = dataTmp.map((node) => {
        return Object.values(node);
      });

      this.dDivTable_th = arr1;
      this.dDivTable_td = arr2;
    },

    //  2. 表格 2
    async fnGetNavTable() {
      let code = this.CODE;
      this.dNavTable_th = [];
      this.dNavTable_td = [];

      const { data } = await this.$https.get('/api/nav_table?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);

      let dataTmp = data.data;
      
      if(Object.keys(dataTmp).length==0){
        return;
      }

      this.dNavTable_table = dataTmp;
      let arr1 = Object.keys(dataTmp[0]);
      let arr2 = dataTmp.map((node) => {
        return Object.values(node);
      });

      arr2 = arr2.map((node, idxx) => {
        node = node.map((e, index) => {
          if (index !== 0) {
            e = Math.abs(Number(e)) >= 10 ? Number(e).toFixed(1) : Number(e).toFixed(2);
          }
          return e;
        });
        return node;
      });

      arr1 = arr1.map((node) => {
        if (node == '单位净值' || node == '累计净值') {
          node = node + '(元)';
        }
        return node;
      });

      this.dNavTable_th = arr1;
      this.dNavTable_td = arr2;
    },

    //  3. 表格 3
    async fnGetRestrictHolder() {
      let code = this.CODE;

      let freq = 'M';

      const { data } = await this.$https.get('/api/restrict_holder?code=' + code + '&freq=' + freq);

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start
      this.json_fields_table_data = JSON.parse(JSON.stringify(data.data.table));
      let arr = Object.keys(this.json_fields_table_data[0]);
      arr.forEach((node) => {
        if (node !== 'reit_code') {
          this.json_fields_table[node] = node;
        }
      });

      // 下载 end

      let dataTmp = data.data.table;

      this.dRestrictHolder_table = dataTmp;

      let arr1 = Object.keys(dataTmp[0]);
      let arr2 = dataTmp.map((node) => {
        return Object.values(node);
      });

      arr2 = arr2.map((node, idxx) => {
        node = node.map((e, index) => {
          if (index == node.length - 1) {
            e = Math.abs(Number(e)) >= 10 ? Number(e).toFixed(1) : Number(e).toFixed(2);
          }
          return e;
        });
        return node;
      });

      arr1 = arr1.map((node, idx) => {
        if (node == '限售份额(份)') {
          node = '限售份额(万份)';
          return node;
        }

        if (node == '限售金额') {
          node = '限售金额(万元)';
          return node;
        }
        return node;
      });

      arr1.splice(arr1.length - 2, 1);

      arr2 = arr2.map((node, idx) => {
        node = node.map((e, index) => {
          if (index == 4 || index == node.length - 1) {
            e = Number((e / 10000).toFixed(1));
            return e;
          } else {
            return e;
          }
        });

        node.splice(node.length - 2, 1);
        return node;
      });

      console.log(arr1, 'arr1');
      console.log(arr2, 'arr2');

      this.restrictHolderData = arr2.map((node) => {
        let obj = {};
        node.forEach((e, idx) => {
          obj[arr1[idx]] = e;
        });
        return obj;
      });

      this.dRestrictHolder_th = arr1;
      this.dRestrictHolder_td = arr2;

      // 柱状图
      this.dRestrictHolder_bar = data.data.bar;
      this.$nextTick(() => {
        this.initChart1();
      });
    },

    initChart1(val) {
      if (!val) {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      } else {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1_dialog);
      }

      this.chartColumn1.clear();

      // this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);

      let data = this.dRestrictHolder_bar;

      // data = data.slice(0,10)

      let X = data.map((node) => {
        return node.解禁日期;
      });

      let Y = data.map((node) => {
        return node.限售金额;
      });

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        grid: {
          top: this.ROOTFONTFIZE * (50 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          // left: this.ROOTFONTFIZE * (50 / 144),
          left: '5%',
          right: this.ROOTFONTFIZE * (50 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            let str = '';
            str += `${args[0].axisValueLabel}`;
            str += '<br>';
            args.forEach((node) => {
              let val = (Number(node.value) / 100000000).toFixed(2);
              str += `${node.marker}${node.seriesName}：${val}亿`;

              return str;
            });
            return str;
          },
        },

        xAxis: {
          type: 'category',
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,
          boundaryGap: false,

          axisLine: {
            show: true,
            onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            // rotate: 5,
            rotate: this.ROOTFONTFIZE * (14 / 144),
            margin: this.ROOTFONTFIZE * (20 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          name: '单位：（亿元）',
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          type: 'value',
          axisLine: {
            show: true,
          },
          offset: 30,
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              if (args == 0) {
                return 0;
              } else {
                args = Number(args) / 100000000;
                let num = (parseInt(Math.abs(args)) + '').length >= 2 ? 1 : 2;
                args = args.toFixed(num);
                return args;
              }
            },
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            name: 'CREITs',
            type: 'bar',
            data: Y,
            itemStyle: {
              color: 'rgba(90, 202, 198, 1)',
            },
            barWidth: this.ROOTFONTFIZE * (37 / 144) + 'px',
            label: {
              show: true,
              position: 'top',
              color: OPTIONDATA.title.textStyle.color,
              formatter: function (val) {
                if (val.value !== 0) {
                  let val2 = Number(val.value) / 100000000;
                  let num = (parseInt(Math.abs(val2)) + '').length >= 2 ? 1 : 2;
                  return val2.toFixed(num) + '亿';
                } else {
                  return '';
                }
              },
            },
          },
        ],
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.funResize()
    },

    fnGetCbtnsSep(data) {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
      this.STOCKNAME = window.sessionStorage.getItem('CURRENTNAME');

      if (data == '历史分红') {
        this.bButtons_separrate_card1 = true;
        this.bButtons_separrate_card3 = false;
        this.bButtons_separrate_card4 = false;
        this.dButtons_separrate_current = '历史分红';
        this.fnGetDivTable();
        this.fnGetNavTable();
      }

      if (data == '解禁份额') {
        this.bButtons_separrate_card1 = false;
        this.bButtons_separrate_card3 = true;
        this.bButtons_separrate_card4 = false;
        this.dButtons_separrate_current = '解禁份额';
        this.fnGetRestrictHolder();
      }

      if (data == '投资者结构') {
        this.bButtons_separrate_card1 = false;
        this.bButtons_separrate_card3 = false;
        this.bButtons_separrate_card4 = true;
        this.dButtons_separrate_current = '投资者结构';
      }
    },
  },
  components: {
    CSelect,
    CSelects,
    CCategoryRadio,
    CButtons,
    CTimeButtons,
    CButtonsSeparate,
    CButtonsDownload,
    CButtonsDownloadSingle,
    CDialog,
    chartColumnInvestorStructure,
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
        '--TABPANEHEIGHT': this.TABPANEHEIGHT + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      fnDownloadBgc();
      this.$nextTick(() => {
        this.initChart1();
      });

      // this.initChart();
      // this.initChart2();
      // this.initChart3();
      // this.initChart4();
      // this.initChart5();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
}

.CARD {
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: var(--charts_bgc);
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) 0px;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (25 / 144));
}

.CARD:last-child {
  margin-bottom: 0;
}

.CARD_tit {
  padding-left: calc(var(--ROOTFONTFIZE) * (22 / 144));
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  // height: 20px;
  color: var(--chart_title);
}

.CARD_tit2 {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.CARD_body_first {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.CARD_body {
  padding: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.CARD_body_tabs {
  padding: 0px calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CARD_body_tabs div {
  display: flex;
  align-items: center;
}

.el-icon-download {
  margin-left: calc(var(--ROOTFONTFIZE) * (45 / 144));
}

.CARD_body_btn_sep {
  margin-top: calc(var(--ROOTFONTFIZE) * (38 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-left: 0px !important;
}

.marketValue {
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.marketValue .value span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (36 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
}

.marketValue .value span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(49, 208, 104, 1);
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.marketValue .time {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.CARD_body_echart,
.CARD_body_table {
  height: calc(var(--ROOTFONTFIZE) * (245 / 144));
  background: rgba(0, 0, 0, 0.1);
  border: 0.2px solid rgba(135, 135, 135, 1);
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144));
}

.choose_area {
  margin-top: calc(var(--ROOTFONTFIZE) * (24 / 144));
}

.charts_area {
  display: flex;
}

.left {
  flex: 1;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.right {
  flex: 1;
}

// table start --------------------------------------------------------------------
.table_area {
  padding: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
}
table {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--table_brc);
  margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
}

.UnlockingShares {
}

.table_first {
  // border-bottom: 3px solid #c7c7c7 !important;
  // border-top: 2px solid #bebfbf !important;
  // background-color: #4a4c51 !important;

  border-bottom: calc(var(--ROOTFONTFIZE) * (2 / 144)) solid var(--table_brc) !important;
  border-top: calc(var(--ROOTFONTFIZE) * (2 / 144)) solid var(--table_brc) !important;
  background-color: var(--table_th_bgc) !important;
}

th {
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 700;
  letter-spacing: 0.21px;
  line-height: calc(var(--ROOTFONTFIZE) * (16.76 / 144));
  color: var(--table_color);
  text-align: left;
  white-space: nowrap;
}

.LISHIFENHONG th {
  text-align: center;
}

.w100 {
  width: calc(var(--ROOTFONTFIZE) * (100 / 144));
}

.table_main {
  width: 100%;
  background-color: var(--table_bgc);
  padding: 0;
  border-bottom: 1px solid var(--table_brc);
}

.table_main:hover {
  background-color: var(--table_bgc_hover);
}

tr {
  line-height: calc(var(--ROOTFONTFIZE) * (15 / 144));
  // padding: 0 !important;
}

tr:last-child {
  border-bottom: none !important;
}

td {
  text-align: center;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  letter-spacing: 0px;
  color: var(--table_color);
  padding: calc(var(--ROOTFONTFIZE) * (10 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
}

td:nth-child(1) {
  text-align: center;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 700;
  // line-height: 10px;
  color: var(--table_color);
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.table_main2 td {
  width: calc(var(--ROOTFONTFIZE) * (200 / 144)) !important;
}

.special_td {
  font-weight: normal !important;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144)) !important;
  text-align: left;
}

.tl {
  text-align: left;
}

// table end --------------------------------------------------------------------

.canvasArea {
  background-color: #1a1c21;
  border: 1px solid #313237;
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);

  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.TITLESTYLE {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  justify-content: space-between;
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.HEIGHT_664 {
  height: calc(var(--ROOTFONTFIZE) * (664 / 144)) !important;
}

.HEIGHT_370 {
  height: calc(var(--ROOTFONTFIZE) * (370 / 144)) !important;
}

.HEIGHT_471 {
  height: calc(var(--ROOTFONTFIZE) * (471 / 144)) !important;
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.downloadBtns2 {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: flex;
  justify-content: flex-end;
}

/* el-table start */

.table1 {
  margin-top: 10px;
}

::v-deep .table1 table {
  border: none;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid var(--table_brc);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  /* background-color: var(--table_color_hover); */
  color: var(--table_color_hover);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell:hover {
  cursor: pointer;
}

::v-deep .el-table::before {
  background-color: var(--table_bgc);
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: var(--table_bgc);
}

.pr {
  position: relative;
}

.r {
  background-color: pink;
}

.icon-fangdaPr {
  position: absolute;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.jiejinjizhongxiazai {
  position: relative;
  z-index: 2;
}

.iconFontStyle {
  position: relative;
}

/* el-table end */
</style>
