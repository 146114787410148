<template>
  <div class="container" id="rootchart" :style="cssVars">
    <div class="CARD">
      <!-- <div class="CARD_tit">鹏华深圳能源 REITs | 180401.SZ</div> -->
      <div class="CARD_tit">
        {{ STOCKNAME }} | <span class="CARD_tit_code">{{ CODE }}</span>
      </div>
      <div class="CARD_body">
        <CButtonsSeparate
          @Cbtns_sep="fnCbtns_sep_chart1"
          class="CARD_body_btn_sep"
          :dButtons1="dButtons_separrate"
          :dButtons1_current="dButtons_separrate_current" />

        <div class="charts_area">
          <div class="left CARD_body_echart HEIGHT_389">
            <div
              v-loading="loading_chart1"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              ref="chartColumn1"
              style="width: 100%; height: 389px"></div>
          </div>
          <div class="right CARD_body_echart HEIGHT_389">
            <div
              v-loading="loading_chart1"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              ref="chartColumn2"
              style="width: 100%; height: 389px"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="CARD">
      <div class="CARD_body_tabs">
        <div>
          <CButtons @Cbtns="fnGetCbtns" :dButtons1="dButtons1" :defaultVal="dButtons1_current" />
          <CSelect
            :mini="true"
            :clearable="false"
            @CSelect_select_val="fnSelect_select_val_chart1"
            :options="annualreportDATA"
            :default="annualreportDATA_chart1"></CSelect>

          <CSelect
            v-show="bButtons1_card2 && !bButtons1_card4"
            :auto="true"
            :clearable="false"
            ref="periodDATA_chart3"
            class="reportingPeriodDIV"
            @CSelect_select_val="fnSelect_select_val_chart3"
            :options="periodDATA"
            :default="periodDATA_chart3"></CSelect>

          <CSelect
            v-show="bButtons1_card2 && bButtons1_card4 && !bButtons1_card5"
            :auto="true"
            :clearable="false"
            ref="periodDATA_chart4"
            class="reportingPeriodDIV"
            @CSelect_select_val="fnSelect_select_val_chart3"
            :options="periodDATA_a"
            :default="periodDATA_chart4"></CSelect>

          <CSelect
            v-show="bButtons1_card2 && bButtons1_card4 && bButtons1_card5"
            :auto="true"
            :clearable="false"
            ref="periodDATA_chart5"
            class="reportingPeriodDIV"
            @CSelect_select_val="fnSelect_select_val_chart3"
            :options="periodDATA_h"
            :default="periodDATA_chart5"></CSelect>
        </div>
        <div>
          <div v-show="dButtons1_current == '业绩概览'" class="downloadBtns">
            <CButtonsDownloadSingle
              :dButtonsDownloads="dButtonsDownloads"
              @fnButtondownload="fnButtonDL8_30" />

            <span style="display: none">
              <download-excel
                id="downloadExcel8_30"
                :data="json_table"
                :fields="json_fields"
                :header="json_title"
                name="业绩概览.xls">
              </download-excel>
            </span>
          </div>

          <div v-show="dButtons1_current == '分派表'" class="downloadBtns">
            <CButtonsDownloadSingle
              :dButtonsDownloads="[
                {
                  id: '241076',
                  name: '下载图片',
                },
                {
                  id: '241076',
                  name: '下载表格',
                },
              ]"
              @fnButtondownload="fnButtonDL8_31" />

            <span style="display: none">
              <download-excel
                id="downloadExcel8_31"
                :data="json_table2"
                :fields="json_fields2"
                :header="json_title2"
                name="分派表.xls">
              </download-excel>
            </span>
            <span style="display: none">
              <download-excel
                id="downloadExcel8_31_1"
                :data="chart4_add_adj"
                :fields="json_fields2"
                :header="json_title2"
                name="调增项细项.xls">
              </download-excel>
            </span>
            <span style="display: none">
              <download-excel
                id="downloadExcel8_31_2"
                :data="chart4_sub_adj"
                :fields="json_fields2"
                :header="json_title2"
                name="调减项细项.xls">
              </download-excel>
            </span>
          </div>

          <div v-show="dButtons1_current == '基金情况'" class="downloadBtns">
            <CButtonsDownloadSingle
              :dButtonsDownloads="dButtonsDownloads"
              @fnButtondownload="fnButtonDL8_32" />

            <span style="display: none">
              <download-excel
                id="downloadExcel8_32"
                :data="json_table3"
                :fields="json_fields3"
                :header="json_title3"
                name="基金情况.xls">
              </download-excel>
            </span>
          </div>

          <!-- <div v-show="dButtons1_current == '基金情况'" class="downloadBtns">
            <CButtonsDownloadSingle
              :dButtonsDownloads="dButtonsDownloads"
              @fnButtondownload="fnButtonDL8_31"
            />

            <span style="display: none">
              <download-excel
                id="downloadExcel8_30"
                :data="json_table"
                :fields="json_fields"
                :header="json_title"
                name="业绩概览.xls"
              >
              </download-excel>
            </span>
          </div> -->

          <!-- <download-excel
            :data="json_table"
            :fields="json_fields"
            :header="json_title"
            name="业绩概览.xls"
          >
            <i class="el-icon-download"></i>
          </download-excel> -->
        </div>
      </div>

      <div v-show="bButtons1_card1" class="table_area table_area1">
        <table :style="table1_width">
          <tr class="table_first">
            <th v-for="(node, index) in dSingleReitPLsnapshot_table_th" :key="index">
              {{ node }}
            </th>
          </tr>
          <tr
            class="table_main"
            :class="node[0] == '可分派金额' ? 'table_first' : ''"
            v-for="(node, index) in dSingleReitPLsnapshot_table_td"
            :key="index">
            <td
              v-for="(e, idx) in node"
              :class="e.includes('%') && idx > 0 ? 'italicStyle' : ''"
              :key="idx">
              {{ e }}
            </td>
          </tr>
        </table>
      </div>

      <!-- <div class="reportingPeriod">

           
          </div> -->
      <div class="table_area">
        <div class="downloadBtnsBack_case" v-if="show_key1 || show_key2">
          <CButtonsDownload2
            class="downloadBtnsBack"
            :dButtonsDownloads="[
              {
                id: '241069',
                name: '返回',
              },
            ]"
            @fnButtondownload="fnButtonChart4Back" />
        </div>
        <div
          v-loading="loading_chart3"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-show="bButtons1_card2"
          class="canvasArea"
          ref="chartColumn3"
          style="width: 100%; height: 403px"></div>
      </div>

      <div v-show="bButtons1_card2" class="table_area table_area1">
        <table :style="table2_width">
          <tr class="table_first">
            <th v-for="(node, index) in dSingleReitDIVSnapshot_th" :key="index">
              {{ node }}
            </th>
          </tr>
          <tr
            class="table_main"
            :class="
              node[0] == '净利润（万元）' ||
              node[0] == 'EBITDA（万元）' ||
              node[0] == '可分派金额（万元）' ||
              node[0] == 'Div Yield(报告期)（%）  '
                ? 'table_first'
                : ''
            "
            v-for="(node, index) in dSingleReitDIVSnapshot_td"
            :key="index"
            @click="showMore(node[0])">
            <td
              :class="e.includes('%') && idx > 0 ? 'italicStyle' : ''"
              v-for="(e, idx) in node"
              :key="idx">
              <span
                v-if="
                  idx == 0 &&
                  index > dSingleReitDIVSnapshot_td_key1 &&
                  index < dSingleReitDIVSnapshot_td_key2
                "
                style="padding-left: 20px; font-size: 90%"
                >{{ e }}</span
              >
              <span
                v-else-if="
                  idx > 0 &&
                  index > dSingleReitDIVSnapshot_td_key1 &&
                  index < dSingleReitDIVSnapshot_td_key2
                "
                style="font-size: 90%"
                >{{ e }}</span
              >
              <span
                v-else-if="
                  idx == 0 &&
                  index > dSingleReitDIVSnapshot_td_key2 &&
                  index < dSingleReitDIVSnapshot_td.length - 3
                "
                style="padding-left: 20px; font-size: 90%"
                >{{ e }}</span
              >
              <span
                v-else-if="
                  idx > 0 &&
                  index > dSingleReitDIVSnapshot_td_key2 &&
                  index < dSingleReitDIVSnapshot_td.length - 3
                "
                style="font-size: 90%"
                >{{ e }}</span
              >
              <span v-else>{{ e }}</span>
              <i
                class="el-icon-arrow-down"
                v-if="(node[0] == '调增项（万元）' || node[0] == '调减项（万元）') && idx == 0"></i>
            </td>
          </tr>
        </table>
      </div>

      <div v-show="bButtons1_card3" class="table_area table_area1">
        <table :style="table3_width">
          <tr class="table_first">
            <th v-for="(node, index) in dSingleReitFundStatus_th" :key="index">
              {{ node }}
            </th>
          </tr>
          <tr
            class="table_main"
            :class="
              node[0] == '资产单位估值' ||
              node[0] == '基金层面现金占比（%）' ||
              node[0] == '基金单位净值'
                ? 'table_first'
                : ''
            "
            v-for="(node, index) in dSingleReitFundStatus_td"
            :key="index">
            <td v-for="(e, idx) in node" :key="idx">{{ e }}</td>
          </tr>
        </table>
      </div>

      <!-- <div class="charts_area CARD_body_echart HEIGHT_396 ">
                
              </div> -->
    </div>
  </div>
</template>

<script>
import CSelects from '@/components/Basic/CSelects';
import CSelect from '@/components/Basic/CSelect';
import CCategoryRadio from '@/components/Basic/CCategoryRadio';
import CButtons from '@/components/Basic/CButtons';
import CButtonsDownload2 from '@/components/Basic/CButtons_download2';
import CTimeButtons from '@/components/Basic/CTimeButtons';
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CButtonsDownloadSingle from '@/components/Basic/CButtons_download_single';

import html2canvas from 'html2canvas';

import { fnGetMuchDecimals, fnDownloadBgc } from '@/utils/util';

export default {
  props: {
    isFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list_assign: [
        //调整
        {
          num: 2,
          list: [
            //筹资相关
            '资金募集调整',
            '购买项目',
            '处置相关调整',
            '本金偿还',
            '分红支付资金',
            '利息调整',
            //现金相关
            '应收应付调整',
            '预留项调整',
            '期初现金余额',
            //其他
            '利息及所得税相关调整',
            '所得税相关调整',
            '其他调整',
            //投资相关
            'CAPEX相关调增',
          ],
        },
        //调减
        {
          num: 3,
          list: [
            //筹资相关
            '资金募集调整',
            '购买项目',
            '处置相关调整',
            '本金偿还',
            '分红支付资金',
            '利息调整',
            //现金相关
            '应收应付调整',
            '预留项调整',
            '期初现金余额',
            //其他
            '利息及所得税相关调整',
            '所得税相关调整',
            '其他调整',
            //投资相关
            'CAPEX相关调增',
          ],
        },
      ],
      map_obj: {
        资金募集调整: {
          name: '筹资相关',
          color: '#ff1e10',
        },
        购买项目: {
          name: '筹资相关',
          color: '#ff1e10',
        },
        处置相关调整: {
          name: '筹资相关',
          color: '#ff1e10',
        },
        本金偿还: {
          name: '筹资相关',
          color: '#ff1e10',
        },
        分红支付资金: {
          name: '筹资相关',
          color: '#ff1e10',
        },
        利息调整: {
          name: '筹资相关',
          color: '#ff1e10',
        },

        应收应付调整: {
          name: '现金相关',
          color: '#1aad19',
        },
        预留项调整: {
          name: '现金相关',
          color: '#1aad19',
        },
        期初现金余额: {
          name: '现金相关',
          color: '#1aad19',
        },

        利息及所得税相关调整: {
          name: '其他',
          color: '#bbbdac',
        },
        所得税相关调整: {
          name: '其他',
          color: '#bbbdac',
        },
        其他调整: {
          name: '其他',
          color: '#bbbdac',
        },

        CAPEX相关调增: {
          name: '投资相关',
          color: '#1a9bff',
        },
      },

      loading_chart1: false,
      loading_chart3: false,

      initChart1Empty: false,
      initChart2Empty: false,
      isEmpty: false,
      ROOTFONTFIZE: 0,
      json_title: '业绩概览',
      json_title2: '分派表',
      json_title3: '基金情况',

      json_fields: {
        // factor_name: "factor_name",
        // "2022Q4": "2022Q4",
        // "2023Q1": "2023Q1",
        // "2023Q2": "2023Q2",
        // "2023Q3": "2023Q3",
        // "2023Q4": "2023Q4",
      },
      json_fields2: {},
      json_fields3: {},

      json_table: [],
      json_table2: [],
      json_table3: [],

      dDownloadSingle1: ['下载表格'],

      dButtonsDownloads: [
        // {
        //   id:'241075',
        //   name:'下载图片'
        // },
        {
          id: '241076',
          name: '下载表格',
        },
      ],

      CODE: '',
      STOCKNAME: '',
      basis_value: '',
      basis_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      days_value: '',
      days_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      level_value: '',
      level_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      classA_value: '',
      classA_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      classB_value: '',
      classB_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      radio1: '',
      radio2: '',

      dBtn1: ['3M', '6M', '1Y', 'YTD', 'MAX'],
      activeBtn1: '3M',

      category_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      //================ new =================================
      dButtons_separrate: ['数值', '比例'],
      dButtons_separrate_current: '数值',

      dButtons1: ['业绩概览', '分派表', '基金情况'],
      dButtons1_current: '业绩概览',

      annualreportDATA: [
        {
          label: '季报',
          value: 'q',
        },
        {
          label: '中报',
          value: 'h',
        },
        {
          label: '年报',
          value: 'a',
        },
      ],
      annualreportDATA_chart1: 'q',

      periodDATA: [
        {
          label: '2022Q4',
          value: '2022Q4',
        },
        {
          label: '2023Q1',
          value: '2023Q1',
        },
        {
          label: '2023Q2',
          value: '2023Q2',
        },
        {
          label: '2023Q3',
          value: '2023Q3',
        },
        {
          label: '2023Q4',
          value: '2023Q4',
        },
      ],

      periodDATA_a: [
        {
          label: '2022A',
          value: '2022A',
        },
      ],
      periodDATA_h: [
        {
          label: '2022H1',
          value: '2022H1',
        },
        {
          label: '2022H2',
          value: '2022H2',
        },
      ],
      periodDATA_q: [
        {
          label: '2022Q4',
          value: '2022Q4',
        },
        {
          label: '2023Q1',
          value: '2023Q1',
        },
        {
          label: '2023Q2',
          value: '2023Q2',
        },
        {
          label: '2023Q3',
          value: '2023Q3',
        },
        {
          label: '2023Q4',
          value: '2023Q4',
        },
      ],
      periodDATA_chart3: '',
      periodDATA_chart4: '',
      periodDATA_chart5: '',

      bButtons1_card1: true,
      bButtons1_card2: false,
      bButtons1_card3: false,
      bButtons1_card4: false,
      bButtons1_card5: false,

      dSingleReitDIVSnapshot_ori: [],
      dSingleReitDIVSnapshot: [],
      dSingleReitDIVSnapshot_th: [],
      dSingleReitDIVSnapshot_td: [],
      add_adj_list: [],
      sub_adj_list: [],
      show_key1: false,
      show_key2: false,
      dSingleReitDIVSnapshot_td_key1: 0,
      dSingleReitDIVSnapshot_td_key2: 0,
      assign_table: null,

      chart4_add_adj: [],
      chart4_sub_adj: [],
      chart4_rp_period: '',
      single_reit_DIVsnapshot_waterfall_ori_data: {},

      ReLine: ['分位数', '标准差'],
      activeBtn1: '分位数',

      dSingleReitPLsnapshot_table_td: [],
      dSingleReitPLsnapshot_table_th: [],

      dSingleReitDIVSnapshotWaterfall_td1: [],
      dSingleReitDIVSnapshotWaterfall_td2: [],

      dSingleReitFundStatus_td: [],
      dSingleReitFundStatus_th: [],

      dSingleReitPLsnapshot_bar: [],
      dSingleReitPLsnapshot_line: [],

      chartColumn1: null,
      chart1_data: [],
      chartColumn2: null,

      // 成交额及换手率对比
      dDailyAmtTurnoverReit: [],
      chartColumn3: null,
      chart3_obj: null,
      chart3_data1: null,
    };
  },
  created() {
    // this.FnInit()
    // if(this.isFlag){
    //   this.fnGetSingleReitPLsnapshot();
    //   this.fnGetSingleReitDIVSnapshotWaterfall();
    //   this.fnGetSingleReitFundStatus();
    // }
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
    this.chartColumn1 && this.chartColumn1.dispose();
    this.chartColumn2 && this.chartColumn2.dispose();
    this.chartColumn3 && this.chartColumn3.dispose();
  },
  methods: {
    funResize() {
      if (this.chartColumn1) {
        this.chartColumn1.resize();
      }
      if (this.chartColumn2) {
        this.chartColumn2.resize();
      }
      if (this.chartColumn3) {
        this.chartColumn3.resize();
      }

      if (this.chartColumn4) {
        this.chartColumn4.resize();
      }
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },

    fnButtonDL8_30(val) {
      document.getElementById('downloadExcel8_30').click();
    },

    fnButtonDL8_31(val) {
      if (val.name == '下载表格') {
        document.getElementById('downloadExcel8_31').click();
        document.getElementById('downloadExcel8_31_1').click();
        document.getElementById('downloadExcel8_31_2').click();
        return;
      }
      this.fnButtonDL8_31_download();
    },
    fnButtonDL8_32(val) {
      document.getElementById('downloadExcel8_32').click();
    },
    fnButtonDL8_31_download() {
      let h = this.$refs.chartColumn3.scrollHeight;
      let w = this.$refs.chartColumn3.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn3, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '分派表数据'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    FnDownload() {
      let h = this.$refs.chartColumn1.scrollHeight;
      let w = this.$refs.chartColumn1.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn1, {
        height: h + 20,
        width: w,
        backgroundColor: '#35373b',
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '板块收益不同时间段对比'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    async FnInit() {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
      this.STOCKNAME = window.sessionStorage.getItem('CURRENTNAME');
      this.dButtons1_current = '业绩概览';

      await this.fnGetCbtns('业绩概览');
      await this.fnGetSingleReitPLsnapshot();
      await this.fnGetSingleReitDIVSnapshot();
      await this.fnGetSingleReitFundStatus();
      //this.fnGetSingleReitDIVSnapshotWaterfall()
    },

    // =====================================================================================================
    //  1. 表格 1
    fnCbtns_sep_chart1(val) {
      this.dButtons_separrate_current = val;
      this.fnGetSingleReitPLsnapshot();
    },
    async fnSelect_select_val_chart1(val) {
      if (val == 'q') {
        this.bButtons1_card4 = false;
        this.bButtons1_card5 = false;
      } else {
        this.bButtons1_card5 = false;
        this.bButtons1_card4 = true;
      }

      if (val == 'h') {
        this.bButtons1_card5 = true;
      }

      this.annualreportDATA_chart1 = val;
      await this.fnGetSingleReitDIVSnapshot2();
      await this.fnGetSingleReitDIVSnapshot();
      await this.fnGetSingleReitPLsnapshot();
      await this.fnGetSingleReitFundStatus();
      await this.fnGetSingleReitDIVSnapshotWaterfall();
    },
    async fnGetSingleReitPLsnapshot() {
      this.loading_chart1 = true;

      let code = this.CODE;
      let show_mode = this.dButtons_separrate_current == '数值' ? 'num' : 'ratio';
      let rp_type = this.annualreportDATA_chart1;
      const { data } = await this.$https.get(
        // '/api/single_reit_PLsnapshot?code=' +
        '/api/single_reit_PLsnapshot_tmp?code=' +
          code +
          '&show_mode=' +
          show_mode +
          '&rp_type=' +
          rp_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let data1 = data.data.table;
      if (data1 == null || data1.length == 0) {
        this.dSingleReitPLsnapshot_table_td = [];
        this.dSingleReitPLsnapshot_table_th = [];
        this.initChart1Empty = true;
        this.initChart2Empty = true;
        this.$nextTick(() => {
          this.initChart1();
          this.initChart2();
        });
        return;
      }
      if (Object.keys(data1[0]).length == 1) {
        this.dSingleReitPLsnapshot_table_td = [];
        this.dSingleReitPLsnapshot_table_th = [];
        this.initChart1Empty = true;
        this.initChart2Empty = true;
        this.$nextTick(() => {
          this.initChart1();
          this.initChart2();
        });
        return;
      }
      this.initChart1Empty = false;
      this.initChart2Empty = false;

      // 下载start

      this.json_fields = {};
      this.json_table = data.data.table;
      this.json_fields['factor_name'] = 'factor_name';
      const keys = Object.keys(this.json_table[0]).reverse();
      keys.forEach((node) => {
        if (node !== 'factor_name') {
          this.json_fields[node] = node;
        }
      });

      // 下载end

      let table_td = data1.map((node) => {
        return Object.values(node);
      });

      let table_th = Object.keys(data1[0]);
      table_th.shift();
      table_th.reverse();
      table_th.unshift('');

      this.dSingleReitPLsnapshot_table_td = table_td;
      this.dSingleReitPLsnapshot_table_th = table_th;

      let ARRTMP = [];

      this.dSingleReitPLsnapshot_table_td.forEach((node) => {
        // 处理数据是不是百分比的情况
        // let bpercentage =
        //   node[1].toString().split(".")[1].length > 12 ? true : false;
        var obj_1 = node[0];
        node.shift();
        node.reverse();
        node.unshift(obj_1);
        let bpercentage = Number(node[1]) < 1 && Number(node[1]) > -1 ? true : false;
        let ARR = [];
        if (!bpercentage) {
          node[0] = node[0] + '（万元）';

          node.forEach((e, idx) => {
            if (idx >= 1 && idx <= node.length) {
              e = e / 10000;
              e = Number(Number(e).toFixed(1)).toLocaleString();
              ARR.push(e);
            } else {
              ARR.push(e);
            }
          });
        } else {
          node[0] = node[0] + '（%）  ';

          node.forEach((e, idx) => {
            if (idx >= 1 && idx <= node.length) {
              e = (Number(e) * 100).toFixed(1) + '%';
              ARR.push(e);
            } else {
              ARR.push(e);
            }
          });
        }

        ARRTMP.push(ARR);
      });

      this.dSingleReitPLsnapshot_table_td = ARRTMP;

      // 柱图
      this.dSingleReitPLsnapshot_bar = data.data.bar;

      this.chart1_data = show_mode;
      this.$nextTick(() => {
        this.initChart1();
      });

      // 折线图
      this.dSingleReitPLsnapshot_line = data.data.line;
      this.$nextTick(() => {
        this.initChart2();
      });
      this.$nextTick(() => {
        document
          .getElementsByClassName('table_area1')[0]
          .scrollTo(document.getElementsByClassName('table_area1')[0].scrollWidth, 0);
      });
    },
    // 柱状图
    initChart1() {
      this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();
      if (this.initChart1Empty) {
        this.loading_chart1 = false;
        return;
      }

      let show_mode = this.chart1_data;

      let dSingleReitPLsnapshot_bar = this.dSingleReitPLsnapshot_bar;
      let X = Object.keys(dSingleReitPLsnapshot_bar[0]);
      X.shift();
      X.reverse();

      // x轴完成
      let Y = [];
      dSingleReitPLsnapshot_bar.map((node) => {
        let obj = {
          name: '',
          data: [],
        };
        let arr = Object.values(node);
        obj.name = arr[0];
        arr.shift();
        arr.reverse();
        obj.data = arr;
        Y.push(obj);
      });
      // Y轴完成

      let Name = Y.map((node) => {
        return node.name;
      });

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let seriesArr = Y.map((v, index) => {
        return {
          name: v.name,
          data: v.data,
          type: 'bar',
          // barWidth: 14,
          cursor: 'pointer',
          label: {
            show: true,
            position: 'top',
            fontSize: 13,
            textStyle: {
              color: OPTIONDATA.title.textStyle.color,
            },
            formatter: function (val) {
              let value = val.value;
              if (show_mode == 'num') {
                if (value >= 10) {
                  return (value / 10000).toFixed(1);
                } else {
                  return (value / 10000).toFixed(2);
                }
              } else {
                return (Number(value) * 100).toFixed(2) + '%';
              }
            },
          },
        };
      });

      let unit = show_mode == 'num' ? '万元' : '%';
      const max = Math.max(...seriesArr.map((item) => (item.data || []).length));
      const endValue = max - 4;
      const startValue = max;
      let option = {
        title: {
          // 标题设置
          text: '主要指标概览（' + unit + '）',
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          left: 'center',
          top: this.ROOTFONTFIZE * (10 / 144),
        },
        grid: {
          //方法 2
          // top: 118,
          // left: 15,
          left: this.ROOTFONTFIZE * (20 / 144),
          top: this.ROOTFONTFIZE * (100 / 144),
          right: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (40 / 144),
          containLabel: true,
        },
        color: ['rgba(90, 202, 198, 1)', 'rgba(89, 143, 200, 1)', 'rgba(86, 84, 202, 1)'],
        legend: {
          orient: 'horizontal',
          top: this.ROOTFONTFIZE * (50 / 144),
          icon: 'roundRect',
          itemWidth: this.ROOTFONTFIZE * (20 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          left: this.ROOTFONTFIZE * (10 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },

          align: 'left',
          data: Name,
        },
        xAxis: {
          type: 'category',
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#dcdcdd',
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
          data: X,
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#dcdcdd',
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (val) {
              if (show_mode == 'num') {
                return val / 10000;
              } else {
                return (Number(val) * 100).toFixed(2) + '%';
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        dataZoom: {
          // 开启数据平移qc
          type: 'slider', // 滑动条单独显示
          show: true, // 是否显示滑动条
          startValue: startValue, // 展示区域内容的起始数值
          endValue: endValue, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
          height: this.ROOTFONTFIZE * (5 / 144), // 滑动条组件高度
          bottom: this.ROOTFONTFIZE * (20 / 144), // 距离图表区域下边的距离
          showDetail: false, // 拖拽时是否显示详情
          showDataShadow: false,
          fillerColor: '#D4D4D4', // 平移条的填充颜色
          borderColor: 'transparent', // 边框颜色
          zoomLock: true, // 锁定视图
          brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
          // 通过该属性可以只滑动，不显示缩放功能
          handleStyle: {
            opacity: 0,
          },
          lineStyle: {
            opacity: 0,
          },
          textStyle: {
            fontSize: 0,
          },
        },
        series: seriesArr,
      };
      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      this.loading_chart1 = false;
      // this.funResize()
    },

    // 折线图
    initChart2() {
      this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
      this.chartColumn2.clear();
      if (this.initChart2Empty) return (this.loading_chart1 = false);
      let dSingleReitPLsnapshot_line = this.dSingleReitPLsnapshot_line;
      let X = Object.keys(dSingleReitPLsnapshot_line[0]);
      X.shift();
      X.reverse();

      // x轴完成
      let Y = [];
      dSingleReitPLsnapshot_line.map((node) => {
        let obj = {
          name: '',
          data: [],
        };
        let arr = Object.values(node);
        obj.name = arr[0];
        arr.shift();
        arr.reverse();
        obj.data = arr;
        Y.push(obj);
      });
      // Y轴完成

      let Name = Y.map((node) => {
        return node.name;
      });

      let seriesArr = Y.map((v, index) => {
        return {
          name: v.name,
          data: v.data,
          type: 'line',
          smooth: false,
          symbol: v.data.length == 1 ? 'circle' : '',
          symbolSize: v.data.length == 1 ? 20 : '',
          cursor: 'pointer',
          label: {
            show: false,
            position: 'top',
            fontSize: 13,
            textStyle: {
              color: '#fff',
            },
          },
        };
      });

      let text = '';
      switch (this.annualreportDATA_chart1) {
        case 'q':
          text = 'Q-o-Q';
          break;
        case 'h':
          text = 'H-o-H';
          break;

        case 'a':
          text = 'Y-o-Y';
          break;
      }

      const max = Math.max(...seriesArr.map((item) => (item.data || []).length));
      const endValue = max - 4;
      const startValue = max;

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text: '主要指标概览（' + text + '）',
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          left: 'center',
          top: this.ROOTFONTFIZE * (10 / 144),
        },
        grid: {
          left: this.ROOTFONTFIZE * (20 / 144),
          top: this.ROOTFONTFIZE * (100 / 144),
          right: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (40 / 144),
          containLabel: true,
        },
        dataZoom: {
          // 开启数据平移qc
          type: 'slider', // 滑动条单独显示
          show: true, // 是否显示滑动条
          startValue, // 展示区域内容的起始数值
          endValue, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
          height: this.ROOTFONTFIZE * (5 / 144), // 滑动条组件高度
          bottom: this.ROOTFONTFIZE * (20 / 144), // 距离图表区域下边的距离
          showDetail: false, // 拖拽时是否显示详情
          showDataShadow: false,
          fillerColor: '#D4D4D4', // 平移条的填充颜色
          borderColor: 'transparent', // 边框颜色
          zoomLock: true, // 锁定视图
          brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
          // 通过该属性可以只滑动，不显示缩放功能
          handleStyle: {
            opacity: 0,
          },
          lineStyle: {
            opacity: 0,
          },
          textStyle: {
            fontSize: 0,
          },
        },
        color: ['#5acac6', '#598fc8', '#5654ca'],
        legend: {
          orient: 'horizontal',
          top: this.ROOTFONTFIZE * (50 / 144),
          icon: 'roundRect',
          itemWidth: this.ROOTFONTFIZE * (35 / 144),
          itemHeight: this.ROOTFONTFIZE * (3 / 144),
          left: this.ROOTFONTFIZE * (10 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },

          align: 'left',
          data: Name,
        },
        xAxis: {
          type: 'category',
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#dcdcdd',
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
          data: X,
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#dcdcdd',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (val) {
              val = val * 100;
              let num = (parseInt(Math.abs(val)) + '').length >= 2 ? 1 : 2;
              val = val.toFixed(num);
              if (val == 0) {
                return 0;
              } else {
                return val + '%';
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            let str = '';
            str += `${args[0].axisValueLabel}`;
            str += '<br>';
            args.forEach((node) => {
              let val = (Number(node.value) * 100).toFixed(2);
              // str += `${node.marker}${node.seriesName}：${val}%`;
              // str += "<br>";
              str += `<span style=" display: flex;justify-content: space-between;align-items: center;"><span>${node.marker}${node.seriesName}：</span><span>${val}%</span></span>`;
              return str;
            });
            return str;
          },
        },

        series: seriesArr,
      };

      this.chartColumn2.setOption(option);
      this.chartColumn2.resize();
      this.loading_chart1 = false;
      // this.funResize()
    },

    //  2. 表格 2
    // async fnGetSingleReitDIVSnapshotWaterfall() {
    //   let code = "508000.SH";
    //   let rp_type = "a";
    //   let rp_period = "2022A";

    //   console.log(code,'code想怎男');

    //   const { data } = await this.$https.get(
    //     "/api/single_reit_DIVsnapshot_waterfall?code=" +
    //       code +
    //       "&rp_type=" +
    //       rp_type +
    //       "&rp_period=" +
    //       rp_period
    //   );

    //   if (data.code !== 200) return this.$message.error(data.msg);

    //   let data1 = data.data;

    //   let table_td1 = Object.keys(data1);
    //   let table_td2 = Object.values(data1);

    //   this.dSingleReitDIVSnapshotWaterfall_td1 = table_td1;
    //   this.dSingleReitDIVSnapshotWaterfall_td2 = table_td2;
    // },

    //  3. 表格 3
    async fnGetSingleReitFundStatus() {
      let code = this.CODE;

      // let rp_period = "2022A";
      let rp_type = this.annualreportDATA_chart1;

      const { data } = await this.$https.get(
        '/api/single_reit_fundstatus?code=' + code + '&rp_type=' + rp_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);
      if (Object.keys(data.data).length === 0) {
        this.dSingleReitFundStatus_th = [];
        this.dSingleReitFundStatus_td = [];

        return;
      }

      let data1 = data.data;

      // 下载 start
      this.json_fields3 = {};
      Object.keys(data1[0]).forEach((node) => {
        this.json_fields3[node] = node;
      });
      this.json_table3 = data1;

      // 下载 end

      let table_td = data1.map((node) => {
        return Object.values(node);
      });

      table_td = table_td.map((node, idxx) => {
        node = node.map((e, index) => {
          if (index !== 0) {
            e = Math.abs(Number(e)) >= 10 ? Number(e).toFixed(1) : Number(e).toFixed(2);
          }
          return e;
        });
        return node;
      });

      let table_th = Object.keys(data1[0]);
      table_th.shift();
      table_th.reverse();
      table_th.unshift('');

      table_td = table_td.map((node, idx) => {
        var obj_1 = node[0];
        node.shift();
        node.reverse();
        node.unshift(obj_1);
        if (
          node[0] == '期初基金单位' ||
          node[0] == '期末基金单位' ||
          node[0] == '本报告期基金单位变化'
        ) {
          node[0] = node[0] + '（亿份）';
          node = node.map((e, id) => {
            if (id !== 0) {
              e = Number(e) / 100000000;
              let num = (parseInt(Math.abs(e)) + '').length >= 2 ? 1 : 2;
              e = e.toFixed(num);
              return e;
            } else {
              return e;
            }
          });

          return node;
        } else {
          return node;
        }
      });
      this.dSingleReitFundStatus_td = table_td;
      this.dSingleReitFundStatus_th = table_th;

      this.$nextTick(() => {
        document
          .getElementsByClassName('table_area1')[2]
          .scrollTo(document.getElementsByClassName('table_area1')[2].scrollWidth, 0);
      });
    },

    // 瀑布图
    async fnSelect_select_val_chart3(val) {
      if (this.annualreportDATA_chart1 == 'q') {
        this.periodDATA_chart3 = val;
      }
      if (this.annualreportDATA_chart1 == 'h') {
        this.periodDATA_chart5 = val;
      }
      if (this.annualreportDATA_chart1 == 'a') {
        this.periodDATA_chart4 = val;
      }
      await this.fnGetSingleReitDIVSnapshot();
      await this.fnGetSingleReitDIVSnapshotWaterfall();
    },
    async fnGetSingleReitDIVSnapshotWaterfall(flag) {
      // console.log('this.assign_table', this.assign_table);
      this.loading_chart3 = true;
      let code = this.CODE;
      // 季报年报
      let rp_type = this.annualreportDATA_chart1;
      // 报告期
      let rp_period = this.bButtons1_card4 ? this.periodDATA_chart4 : this.periodDATA_chart3;

      if (this.bButtons1_card5) {
        rp_period = this.periodDATA_chart5;
      }

      const { data } = await this.$https.get(
        '/api/single_reit_DIVsnapshot_waterfall?code=' +
          code +
          '&rp_period=' +
          rp_period +
          '&rp_type=' +
          rp_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);
      this.chart4_rp_period = rp_period;
      this.single_reit_DIVsnapshot_waterfall_ori_data = data.data;
      // this.taktInitChart4(this.single_reit_DIVsnapshot_waterfall_ori_data);
      //   return;
      if (flag) {
        this.taktInitChart4(this.single_reit_DIVsnapshot_waterfall_ori_data);
        return;
      }
      this.takeInitChart3(this.single_reit_DIVsnapshot_waterfall_ori_data);
    },

    //新的分派详情绘图，加入调增和调减的详情项目
    taktInitChart4(data1) {
      //先获取到汇总数据并筛选出符合详情的数据
      var add_adj = {};
      this.chart4_add_adj.forEach((item) => {
        add_adj[item.factory_name] = item[this.chart4_rp_period];
      });
      var sub_adj = {};
      this.chart4_sub_adj.forEach((item) => {
        sub_adj[item.factory_name] = item[this.chart4_rp_period];
      });

      var assign_obj_ori = {};
      var assign_obj = {};

      this.list_assign.forEach((item) => {
        var list = item.list;
        for (var key = 0; key < list.length; key++) {
          if (item.num == 2) {
            if (key == 0) {
              assign_obj_ori['s' + item.num + '_' + (key + 1)] = this.getSomeValue(
                Number(data1['EBITDA']),
                add_adj[list[key]]
              );
            } else {
              assign_obj_ori['s' + item.num + '_' + (key + 1)] = this.getSomeValue(
                assign_obj_ori['s' + item.num + '_' + key]['next_init'],
                add_adj[list[key]]
              );
            }
            assign_obj['s' + item.num + '_' + (key + 1) + '_' + 'd'] = [
              { value: '-', myName: '' },
              { value: assign_obj_ori['s' + item.num + '_' + (key + 1)]['dianzi'], myName: '' },
              { value: '-', myName: '' },
              { value: '-', myName: '' },
            ];
            assign_obj['s' + item.num + '_' + (key + 1) + '_' + '1'] = [
              { value: '-', myName: '' },
              {
                value: assign_obj_ori['s' + item.num + '_' + (key + 1)]['up'],
                myName: list[key],
                ori:
                  assign_obj_ori['s' + item.num + '_' + (key + 1)].up != 0 ? add_adj[list[key]] : 0,
              },
              { value: '-', myName: '' },
              { value: '-', myName: '' },
            ];
            assign_obj['s' + item.num + '_' + (key + 1) + '_' + '2'] = [
              { value: '-', myName: '' },
              {
                value: assign_obj_ori['s' + item.num + '_' + (key + 1)]['down'],
                myName: list[key],
                ori:
                  assign_obj_ori['s' + item.num + '_' + (key + 1)].down != 0 &&
                  assign_obj_ori['s' + item.num + '_' + (key + 1)].up == 0
                    ? add_adj[list[key]]
                    : 0,
              },
              { value: '-', myName: '' },
              { value: '-', myName: '' },
            ];
          }
          if (item.num == 3) {
            if (key == 0) {
              assign_obj_ori['s' + item.num + '_' + (key + 1)] = this.getSomeValue(
                assign_obj_ori['s2_' + this.list_assign[0].list.length].next_init,
                sub_adj[list[key]]
              );
            } else {
              assign_obj_ori['s' + item.num + '_' + (key + 1)] = this.getSomeValue(
                assign_obj_ori['s' + item.num + '_' + key]['next_init'],
                sub_adj[list[key]]
              );
            }
            assign_obj['s' + item.num + '_' + (key + 1) + '_' + 'd'] = [
              { value: '-', myName: '' },
              { value: '-', myName: '' },
              { value: assign_obj_ori['s' + item.num + '_' + (key + 1)]['dianzi'], myName: '' },
              { value: '-', myName: '' },
            ];
            assign_obj['s' + item.num + '_' + (key + 1) + '_' + '1'] = [
              { value: '-', myName: '' },
              { value: '-', myName: '' },
              {
                value: assign_obj_ori['s' + item.num + '_' + (key + 1)]['up'],
                myName: list[key],
                ori:
                  assign_obj_ori['s' + item.num + '_' + (key + 1)].up != 0 ? sub_adj[list[key]] : 0,
              },
              { value: '-', myName: '' },
            ];
            assign_obj['s' + item.num + '_' + (key + 1) + '_' + '2'] = [
              { value: '-', myName: '' },
              { value: '-', myName: '' },
              {
                value: assign_obj_ori['s' + item.num + '_' + (key + 1)]['down'],
                myName: list[key],
                ori:
                  assign_obj_ori['s' + item.num + '_' + (key + 1)].down != 0 &&
                  assign_obj_ori['s' + item.num + '_' + (key + 1)].up == 0
                    ? sub_adj[list[key]]
                    : 0,
              },
              { value: '-', myName: '' },
            ];
          }
        }
      });

      let s4 = [
        { value: Number(data1['EBITDA']), myName: 'EBITDA', ori: Number(data1['EBITDA']) },
        '-',
        '-',
        {
          value: Number(data1['可分派金额']),
          myName: '可分派金额',
          ori: Number(data1['可分派金额']),
        },
      ];

      let obj = {
        ...assign_obj,
        s4: s4,
        X: ['EBITDA', '调增项', '调减项', '可分派金额'],
      };

      this.chart3_obj = obj;
      this.chart3_data1 = data1;
      this.$nextTick(() => {
        this.initChart4();
      });
    },
    // 柱状图(新的分派详情绘图)
    initChart4() {
      if (this.chart3_obj.s0) {
        return;
      }
      this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      this.chartColumn3.clear();

      let obj = this.chart3_obj;
      let data1 = this.chart3_data1;

      if (Object.keys(data1).length === 0) return (this.loading_chart3 = false);

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      //这里处理下系列
      let new_list = [];
      for (var key in obj) {
        if (key == 'X') {
          continue;
        }
        if (key == 's4') {
          new_list.push({
            name: '汇总',
            type: 'bar',
            silent: true,
            stack: 'TotalAll',
            // barWidth: "30px",

            itemStyle: {
              normal: {
                color: '#5ae8ed',
              },
            },
            silent: true,
            markLine: {
              symbol: ['none', 'none'], // none为标线两端的样式为无，可更改
              data: [
                {
                  yAxis: 0,
                },
              ],
              label: {
                show: false,
              },
              lineStyle: {
                type: 'solid',
                color: '#dcdcdd',
              },
            },
            data: obj[key],
          });
          continue;
        }
        var key_list = key.split('_');
        key_list = key_list.filter((str) => str != '');
        if (key_list.length <= 1) {
          continue;
        }
        var num_1 = key_list[0].split('').filter((str) => str != '')[1];
        var num_2 = key_list[1];
        if (
          obj['s' + num_1 + '_' + num_2 + '_' + '1'][num_1 - 1]['value'] == 0 &&
          obj['s' + num_1 + '_' + num_2 + '_' + '2'][num_1 - 1]['value'] == 0
        ) {
          continue;
        }
        if (key_list[2] == 'd') {
          new_list.push({
            type: 'bar',
            stack: 'Total' + num_1 + '_' + num_2,
            silent: true,
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent',
            },
            emphasis: {
              itemStyle: {
                borderColor: 'transparent',
                color: 'transparent',
              },
            },
            data: obj[key],
          });
          continue;
        }
        if (obj[key][num_1 - 1]['value'] != 0) {
          new_list.push({
            name: this.map_obj[obj[key][num_1 - 1]['myName']]['name'],
            type: 'bar',
            stack: 'Total' + num_1 + '_' + num_2,
            silent: true,
            itemStyle: {
              color: this.map_obj[obj[key][num_1 - 1]['myName']]['color'],
            },
            data: obj[key],
          });
        }
      }
      var line_date = this.getLineDate(new_list);
      var new_x_list = line_date.new_x_list;
      var length = line_date.length;

      var x_value = [];
      for (var i = 0; i < new_x_list.length; i++) {
        x_value.push(i);
      }

      new_list.forEach((item) => {
        item['barWidth'] = parseInt((100 - 5 * length) / length) + '%';
        item['barGap'] = (5 * 100) / parseInt((100 - 5 * length) / length) + '%';
      });

      //插入折线
      new_list.push({
        type: 'line', // 折线图
        name: '虚线延长',
        xAxisIndex: 1,
        // AxisIndex: 1,
        smooth: false, // 不平滑，确保是直线
        itemStyle: {
          normal: {
            lineStyle: {
              type: 'dashed', // 设置为虚线
              color: OPTIONDATA.title.textStyle.color, // 虚线颜色
              width: 1, // 虚线宽度
            },
          },
        },
        silent: true,
        tooltip: {
          trigger: 'none', // 禁用tooltip
        },
        showSymbol: false,
        markPoint: {
          silent: false,
          itemStyle: {
            color: 'transparent',
          },
        },
        step: 'end',
        data: new_x_list,
        z: 1,
      });

      let option = {
        title: {
          // 标题设置
          text: '调增调减细项', // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          left: 'center',
          top: this.ROOTFONTFIZE * (20 / 144),
        },
        tooltip: {
          trigger: 'axis',
          // axisPointer: {
          //   type: 'shadow',
          // },
          formatter: function (args) {
            let str = '';
            args.forEach((node) => {
              let val = node.data.ori;
              if (!val) {
                return;
              }
              if (val == '-' || node.seriesName == 'series\u00000') {
                return;
              }

              val = Number(val) / 10000;
              let num = (parseInt(Math.abs(val)) + '').length >= 2 ? 1 : 2;
              if (val == 0) {
                return '';
              } else {
                val = Number(val).toFixed(num);
              }

              if (val.indexOf('NaN') != -1) {
                return '';
              }

              if (node.name == '可分派金额' || node.name == 'EBITDA') {
                str += `${node.marker}${node.name}：${val}`;
                str += '<br>';

                return str;
              }

              str += `${node.marker}${node.data.myName}：${val}`;
              str += '<br>';

              return str;
            });
            return str;
          },
        },
        legend: {
          orient: 'horizontal',
          top: this.ROOTFONTFIZE * (30 / 144),
          icon: 'circle',
          itemWidth: this.ROOTFONTFIZE * (15 / 144),
          itemHeight: this.ROOTFONTFIZE * (15 / 144),
          left: this.ROOTFONTFIZE * (30 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },

          align: 'left',
          data: [
            {
              name: '现金相关',
              icon: 'rect',
              itemStyle: {
                color: '#1aad19',
              },
            },
            {
              name: '投资相关',
              icon: 'rect',
              itemStyle: {
                color: '#1a9bff',
              },
            },
            {
              name: '筹资相关',
              icon: 'rect',
              itemStyle: {
                color: '#ff1e10',
              },
            },
            {
              name: '其他',
              icon: 'rect',
              itemStyle: {
                color: 'gray',
              },
            },
            {
              name: '汇总',
              itemStyle: {
                color: '#79f8fc',
              },
            },
          ],
        },
        grid: {
          left: this.ROOTFONTFIZE * (30 / 144),
          right: this.ROOTFONTFIZE * (30 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          top: this.ROOTFONTFIZE * (100 / 144),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: true,
              onZero: false, //轴线是否在0刻度轴上
              // onZero: true, //轴线是否在0刻度轴上
              lineStyle: {
                color: '#7a7a79',
              },
            },

            axisTick: {
              show: true,
            },
            axisLabel: {
              interval: 0,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              margin: this.ROOTFONTFIZE * (20 / 144),
              // margin: 0,
              show: true,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
            axisPointer: {
              type: 'shadow',
            },
            data: obj.X,
          },
          {
            type: 'category',
            show: false,
            silent: true,
            triggerEvent: false,
            showSymbol: false,
            axisLine: {
              show: true,
              onZero: false, //轴线是否在0刻度轴上
              // onZero: true, //轴线是否在0刻度轴上
              lineStyle: {
                // color: 'transparent',
                color: '#7a7a79',
              },
            },
            axisTick: {
              show: true,
            },
            axisLabel: {
              interval: 0,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              margin: this.ROOTFONTFIZE * (20 / 144),
              // margin: 0,
              show: true,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
            axisPointer: {
              type: 'none',
            },
            data: x_value,
          },
        ],
        yAxis: [
          {
            name: '单位：（万元）',
            nameTextStyle: {
              color: OPTIONDATA.yAxis.nameTextStyle.color,
              fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
            },
            nameGap: OPTIONDATA.yAxis.nameGap,
            type: 'value',
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (val) {
                let value = val / 10000;
                let num = (parseInt(Math.abs(value)) + '').length >= 2 ? 1 : 2;
                if (value == 0) {
                  return 0;
                } else {
                  return value.toFixed(num);
                }
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
        ],
        series: new_list,
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      this.loading_chart3 = false;
      this.chartColumn3.off('click', this.handleChartClick);
    },
    fnButtonChart4Back() {
      this.show_key1 = false;
      this.show_key2 = false;
      this.$nextTick(() => {
        this.showMore();
      });
    },
    //老的瀑布绘图数据处理
    takeInitChart3(data1) {
      if (Object.keys(data1).length === 0) {
        this.chart3_obj = {};
        this.chart3_data1 = {};

        this.$nextTick(() => {
          this.initChart3();
        });

        return;
      }
      let s1 = this.getSomeValue(Number(data1['净利润']), Number(data1['折旧和摊销(元)']));
      let s5 = this.getSomeValue(s1.next_init, Number(data1['本期利息支出']));
      let s2 = this.getSomeValue(s5.next_init, Number(data1['所得税费用']));
      let s3 = this.getSomeValue(Number(data1['EBITDA']), Number(data1['调增项']));
      let s4 = this.getSomeValue(s3.next_init, Number(data1['调减项']));
      let s7 = [
        Number(data1['净利润']),
        Number(data1['净利润']) + Number(data1['折旧和摊销(元)']),
        Number(data1['净利润']) + Number(data1['折旧和摊销(元)']) + Number(data1['本期利息支出']),
        Number(data1['净利润']) +
          Number(data1['折旧和摊销(元)']) +
          Number(data1['本期利息支出']) +
          Number(data1['所得税费用']),
        Number(data1['EBITDA']),
        Number(data1['EBITDA']) + Number(data1['调增项']),
        Number(data1['EBITDA']) + Number(data1['调增项']) + Number(data1['调减项']),
        Number(data1['可分派金额']),
      ];
      //垫脚
      let s0 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: s1.dianzi,
          myName: '',
        },
        {
          value: s5.dianzi,
          myName: '',
        },
        {
          value: s2.dianzi,
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: s3.dianzi,
          myName: '',
        },
        {
          value: s4.dianzi,
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
      ];

      //折旧和摊销上面部分
      let s1_1 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: s1.up,
          myName: Number(data1['折旧和摊销(元)']),
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //折旧和摊销下面部分
      let s1_2 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: s1.down,
          myName: Number(data1['折旧和摊销(元)']),
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //本期利息支出(万元)上面部分
      let s5_1 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: s5.up,
          myName: Number(data1['本期利息支出']),
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //本期利息支出(万元)下面部分
      let s5_2 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: s5.down,
          myName: Number(data1['本期利息支出']),
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //所得税费用上部分
      let s2_1 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: s2.up,
          myName: Number(data1['所得税费用']),
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //所得税费用下部分
      let s2_2 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: s2.down,
          myName: Number(data1['所得税费用']),
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //调增项上部分
      let s3_1 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: s3.up,
          myName: Number(data1['调增项']),
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //调增项下部分
      let s3_2 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: s3.down,
          myName: Number(data1['调增项']),
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //调减项上部分
      let s4_1 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: s4.up,
          myName: Number(data1['调减项']),
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //调减项下部分
      let s4_2 = [
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: s4.down,
          myName: Number(data1['调减项']),
        },
        {
          value: '-',
          myName: '',
        },
      ];
      //汇总
      let s6 = [
        {
          value: Number(data1['净利润']),
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: Number(data1['EBITDA']),
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: '-',
          myName: '',
        },
        {
          value: Number(data1['可分派金额']),
          myName: '',
        },
      ];

      let obj = {
        s0: s0,
        s1_1: s1_1,
        s1_2: s1_2,
        s5_1: s5_1,
        s5_2: s5_2,
        s2_1: s2_1,
        s2_2: s2_2,
        s3_1: s3_1,
        s3_2: s3_2,
        s4_1: s4_1,
        s4_2: s4_2,
        s6: s6,
        s7: s7,
        X: [
          '净利润',
          '折旧和摊销(元)',
          '本期利息支出',
          '所得税费用',
          'EBITDA',
          '调增项',
          '调减项',
          '可分派金额',
        ],
      };

      this.chart3_obj = obj;
      this.chart3_data1 = data1;
      this.$nextTick(() => {
        this.initChart3();
      });
    },
    // 柱状图(老的分派详情绘图)
    initChart3() {
      if (!this.chart3_obj.s0) {
        return;
      }
      this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      this.chartColumn3.clear();

      let obj = this.chart3_obj;
      let data1 = this.chart3_data1;
      let s1 = {
        name: obj.s1_1[1].myName >= 0 ? '增加' : '减少',
        color: obj.s1_1[1].myName >= 0 ? '#ff5229' : '#36c2f5',
      };
      let s5 = {
        name: obj.s5_1[2].myName >= 0 ? '增加' : '减少',
        color: obj.s5_1[2].myName >= 0 ? '#ff5229' : '#36c2f5',
      };
      let s2 = {
        name: obj.s2_1[3].myName >= 0 ? '增加' : '减少',
        color: obj.s2_1[3].myName >= 0 ? '#ff5229' : '#36c2f5',
      };
      let s3 = {
        name: obj.s3_1[5].myName >= 0 ? '增加' : '减少',
        color: obj.s3_1[5].myName >= 0 ? '#ff5229' : '#36c2f5',
      };
      let s4 = {
        name: obj.s4_1[6].myName >= 0 ? '增加' : '减少',
        color: obj.s4_1[6].myName >= 0 ? '#ff5229' : '#36c2f5',
      };

      if (Object.keys(data1).length === 0) return (this.loading_chart3 = false);

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text: '分派表数据汇总', // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (16 / 144),
          },
          left: 'center',
          top: this.ROOTFONTFIZE * (20 / 144),
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            let axisValueLabel = params[0].axisValueLabel;
            let val = data1[axisValueLabel];
            val = Number(val) / 10000;
            let num = (parseInt(Math.abs(val)) + '').length >= 2 ? 1 : 2;
            if (val == 0) {
              val = 0;
            } else {
              val = Number(val).toFixed(num);
            }
            return `${axisValueLabel}：${val}`;
          },
        },
        legend: {
          orient: 'horizontal',
          top: this.ROOTFONTFIZE * (30 / 144),
          icon: 'circle',
          itemWidth: this.ROOTFONTFIZE * (15 / 144),
          itemHeight: this.ROOTFONTFIZE * (15 / 144),
          left: this.ROOTFONTFIZE * (30 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },

          align: 'left',
          data: [
            {
              name: '增加',
              itemStyle: {
                color: '#ff5229',
              },
            },
            {
              name: '减少',
              itemStyle: {
                color: '#36c2f5',
              },
            },
            {
              name: '汇总',
              itemStyle: {
                color: '#79f8fc',
              },
            },
          ],
        },
        grid: {
          left: this.ROOTFONTFIZE * (30 / 144),
          right: this.ROOTFONTFIZE * (30 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          top: this.ROOTFONTFIZE * (100 / 144),
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
            lineStyle: {
              color: '#7a7a79',
            },
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            margin: this.ROOTFONTFIZE * (20 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
          data: obj.X,
        },
        yAxis: {
          name: '单位：（万元）',
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          type: 'value',
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (val) {
              let value = val / 10000;
              let num = (parseInt(Math.abs(value)) + '').length >= 2 ? 1 : 2;
              if (value == 0) {
                return 0;
              } else {
                return value.toFixed(num);
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },

        series: [
          //垫子
          {
            type: 'bar',
            stack: 'Total',
            silent: true,
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent',
            },
            emphasis: {
              itemStyle: {
                borderColor: 'transparent',
                color: 'transparent',
              },
            },
            data: obj.s0,
          },
          //折旧和摊销上面部分
          {
            name: s1.name,
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              color: s1.color,
            },
            data: obj.s1_1,
          },
          // 折旧和摊销下面部分
          {
            name: s1.name,
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              color: s1.color,
            },
            data: obj.s1_2,
          },
          //本期利息支出上面部分
          {
            name: s5.name,
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              color: s5.color,
            },
            data: obj.s5_1,
          },
          // 本期利息支出下面部分
          {
            name: s5.name,
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              color: s5.color,
            },
            data: obj.s5_2,
          },
          //所得税上部分
          {
            name: s2.name,
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              color: s2.color,
            },
            data: obj.s2_1,
          },
          //所得税下部分
          {
            name: s2.name,
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              color: s2.color,
            },
            data: obj.s2_2,
          },
          //调增项上部分
          {
            name: s3.name,
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              color: s3.color,
            },
            data: obj.s3_1,
          },
          //调增项下部分
          {
            name: s3.name,
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              color: s3.color,
            },
            data: obj.s3_2,
          },
          //调减项上部分
          {
            name: s4.name,
            type: 'bar',
            stack: 'Total',

            itemStyle: {
              normal: {
                color: s4.color,
              },
            },
            data: obj.s4_1,
          },
          //调减项下部分
          {
            name: s4.name,
            type: 'bar',
            stack: 'Total',

            itemStyle: {
              normal: {
                color: s4.color,
              },
            },
            data: obj.s4_2,
          },
          {
            name: '汇总',
            type: 'bar',
            stack: 'Total',
            silent: true,
            // barWidth: "30px",
            itemStyle: {
              normal: {
                color: '#5ae8ed',
              },
            },
            silent: true,
            markLine: {
              symbol: ['none', 'none'], // none为标线两端的样式为无，可更改
              data: [
                {
                  yAxis: 0,
                },
              ],
              label: {
                show: false,
              },
              lineStyle: {
                type: 'solid',
                color: '#dcdcdd',
              },
            },
            data: obj.s6,
          },
          {
            type: 'line', // 折线图
            name: '虚线延长',
            smooth: false, // 不平滑，确保是直线
            itemStyle: {
              normal: {
                lineStyle: {
                  type: 'dashed', // 设置为虚线
                  color: OPTIONDATA.title.textStyle.color, // 虚线颜色
                  width: 1, // 虚线宽度
                },
              },
            },
            silent: true,
            tooltip: {
              trigger: 'none', // 禁用tooltip
            },
            showSymbol: false,
            markPoint: {
              silent: false,
              itemStyle: {
                color: 'transparent',
              },
            },
            step: 'end',
            data: obj.s7,
            z: 1,
          },
        ],
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      this.loading_chart3 = false;
      this.chartColumn3.on('click', this.handleChartClick);
    },
    async handleChartClick(params) {
      // if (params.name == '调增项') {
      //   this.show_key1 = true;
      // }
      // if (params.name == '调减项') {
      //   this.show_key2 = true;
      // }

      if (params.name == '调减项' || params.name == '调增项') {
        // await this.fnGetSingleReitDIVSnapshotWaterfall(true);
        await this.showMore(params.name + '（万元）');
      }
    },
    //获取折线部分
    getLineDate(new_list) {
      //这里处理另外一条X轴的数据
      var line_obj = {
        2: [],
        3: [],
        All: [],
      };
      new_list.forEach((item) => {
        var name = item.stack.replace('Total', '');
        var name_new = name.split('_')[0];
        if (!line_obj[name_new]) {
          line_obj[name_new] = [];
        }
        if (name == 'All') {
          line_obj[name].push(item.data[0].ori);
          line_obj[name].push(item.data[3].ori);
          return;
        }
        var index = name.split('_')[0] - 1;
        if (item.data[index].ori && item.data[index].ori != '-') {
          line_obj[name_new].push(item.data[index].ori);
        }
      });
      //这里开始清算
      var length = line_obj['2'].length + line_obj['3'].length + 1;
      //数组1
      var list1 = [];
      for (var i = 0; i < length - 1; i++) {
        list1.push(null);
      }
      list1.push(line_obj['All'][0]);

      //数组2
      var list2 = JSON.parse(JSON.stringify(line_obj['2']));
      for (var i = 0; i < length - line_obj['2'].length; i++) {
        list2.push(null);
      }

      //数组3
      var list3 = [];
      for (var i = 0; i < line_obj['2'].length; i++) {
        list3.push(null);
      }
      list3 = list3.concat(line_obj['3']);
      for (var i = 0; i < length - line_obj['2'].length - line_obj['3'].length; i++) {
        list3.push(null);
      }

      // //数组4
      var list4 = [];
      for (var i = 0; i < length - 1; i++) {
        list4.push(null);
      }
      list4.push(line_obj['All'][1]);

      var x_list = [];
      x_list = list1.concat(list2).concat(list3).concat(list4);

      //进行重新计算
      var new_x_list = [];
      for (var i = 0; i < x_list.length; i++) {
        if (i < length - 1 && x_list[i] == null) {
          new_x_list.push(null);
          continue;
        }
        if (i == length - 1 || i == x_list.length - 1) {
          new_x_list.push(x_list[i]);
          continue;
        }
        new_x_list.push(new_x_list[i - 1] + (x_list[i] == null ? 0 : x_list[i]));
      }
      return {
        new_x_list: new_x_list,
        length: length,
      };
    },
    //获取垫子值和上部分以及下部分值
    getSomeValue(init_val, diff_val) {
      if (!diff_val) {
        diff_val = 0;
      }
      var obj = {
        dianzi: 0,
        up: 0,
        down: 0,
        next_init: init_val + diff_val,
      };
      //初始值大于0
      if (init_val >= 0) {
        //差值大于0
        if (diff_val >= 0) {
          obj.dianzi = init_val;
          obj.up = diff_val;
        } else {
          //初始值+差值大于0
          if (init_val + diff_val >= 0) {
            obj.dianzi = init_val + diff_val;
            obj.up = Math.abs(diff_val);
          } else {
            obj.up = init_val;
            obj.down = init_val + diff_val;
          }
        }
      } else {
        //差值小于0
        if (diff_val <= 0) {
          obj.dianzi = init_val;
          obj.down = diff_val;
        } else {
          //初始值+差值小于0
          if (init_val + diff_val <= 0) {
            obj.dianzi = init_val + diff_val;
            obj.down = -diff_val;
          } else {
            obj.down = init_val;
            obj.up = init_val + diff_val;
          }
        }
      }
      return obj;
    },

    // 季报年报中报选项获取
    async fnGetSingleReitDIVSnapshot2() {
      this.assign_table = null;
      let code = this.CODE;
      // 季报年报
      let rp_type = this.annualreportDATA_chart1;

      const { data } = await this.$https.get(
        '/api/v2/single_reit_DIVsnapshot?code=' + code + '&rp_type=' + rp_type
      );

      let dSingleReitDIVSnapshot = data.data.ori_;
      this.chart4_add_adj = data.data.add_adj;
      this.chart4_sub_adj = data.data.sub_adj;
      this.assign_table = data.data;

      // 下载 start
      this.json_fields2 = {};
      Object.keys(dSingleReitDIVSnapshot[0]).forEach((node) => {
        this.json_fields2[node] = node;
      });
      this.json_table2 = dSingleReitDIVSnapshot;
      // var dSingleReitDIVSnapshot_td_key1 = 0;
      // var dSingleReitDIVSnapshot_td_key2 = 0;
      // for (var key in this.json_table2) {
      //   if (dSingleReitDIVSnapshot[key]['factory_name'] == '调增项') {
      //     dSingleReitDIVSnapshot_td_key1 = key;
      //   }
      // }
      // this.json_table2.splice(
      //   parseInt(dSingleReitDIVSnapshot_td_key1) + 1,
      //   0,
      //   ...this.chart4_add_adj
      // );
      // for (var key in this.json_table2) {
      //   if (dSingleReitDIVSnapshot[key]['factory_name'] == '调减项') {
      //     dSingleReitDIVSnapshot_td_key2 = key;
      //   }
      // }
      // this.json_table2.splice(
      //   parseInt(dSingleReitDIVSnapshot_td_key2) + 1,
      //   0,
      //   ...this.chart4_sub_adj
      // );

      // console.log('TTTTTTTTTTT1', this.json_fields2);
      // console.log('TTTTTTTTTTT2', this.json_table2);

      // 下载 end

      let dSingleReitDIVSnapshot_th = Object.keys(dSingleReitDIVSnapshot[0]);
      let flag = false;
      if (dSingleReitDIVSnapshot_th.length == 1) {
        flag = true;
        this.dSingleReitDIVSnapshot_th = [];
      }
      if (flag) return;
      dSingleReitDIVSnapshot_th.shift();
      let tmp = dSingleReitDIVSnapshot_th;

      tmp = tmp.filter((node) => {
        return node !== '';
      });

      tmp = tmp.map((node) => {
        let obj = {
          label: node,
          value: node,
        };
        return obj;
      });

      // q periodDATA 季报
      // h periodDATA_h  中报
      // a periodDATA_a  年报
      if (this.annualreportDATA_chart1 == 'q') {
        this.periodDATA = tmp;
        this.$refs.periodDATA_chart3.value = this.periodDATA[0].value;
        this.periodDATA_chart3 = this.periodDATA[0].value;
      }

      if (this.annualreportDATA_chart1 == 'h') {
        this.periodDATA_h = tmp;
        this.$refs.periodDATA_chart5.value = this.periodDATA_h[0].value;
        this.periodDATA_chart5 = this.periodDATA_h[0].value;
      }

      if (this.annualreportDATA_chart1 == 'a') {
        this.periodDATA_a = tmp;
        this.$refs.periodDATA_chart4.value = this.periodDATA_a[0].value;
        this.periodDATA_chart4 = this.periodDATA_a[0].value;
      }
      this.$nextTick(() => {
        document
          .getElementsByClassName('table_area1')[1]
          .scrollTo(document.getElementsByClassName('table_area1')[1].scrollWidth, 0);
      });
    },

    showMore(value) {
      if (value == '调增项（万元）') {
        this.show_key1 = !this.show_key1;
      }
      if (value == '调减项（万元）') {
        this.show_key2 = !this.show_key2;
      }
      if (this.show_key1 || this.show_key2) {
        this.fnGetSingleReitDIVSnapshotWaterfall(true);
      }
      if (!this.show_key1 && !this.show_key2) {
        this.fnGetSingleReitDIVSnapshotWaterfall();
      }
      this.fnGetSingleReitDIVSnapshotMore();
    },
    // 瀑布图下面的表格
    async fnGetSingleReitDIVSnapshot() {
      let code = this.CODE;
      // 季报年报
      let rp_type = this.annualreportDATA_chart1;

      const { data } = await this.$https.get(
        '/api/v2/single_reit_DIVsnapshot?code=' + code + '&rp_type=' + rp_type
      );

      this.dSingleReitDIVSnapshot_ori = data.data.ori_;
      this.add_adj_list = Object.values(data.data.add_adj);
      this.sub_adj_list = Object.values(data.data.sub_adj);

      this.dSingleReitDIVSnapshot_th = Object.keys(this.dSingleReitDIVSnapshot_ori[0]);
      this.dSingleReitDIVSnapshot_th.shift();
      this.dSingleReitDIVSnapshot_th.reverse();
      // let tmp = this.dSingleReitDIVSnapshot_th
      this.dSingleReitDIVSnapshot_th.unshift('');
      let flag = false;
      if (this.dSingleReitDIVSnapshot_th.length == 1) {
        this.dSingleReitDIVSnapshot_ori = [];
        this.add_adj_list = [];
        this.sub_adj_list = [];
        this.show_key1 = false;
        this.show_key2 = false;
        this.chart3_obj = {};
        this.chart3_data1 = {};
        this.dSingleReitDIVSnapshot_th = [];
        this.dSingleReitDIVSnapshot_td = [];
        flag = true;
      }

      if (flag) return;
      this.fnGetSingleReitDIVSnapshotMore();
    },
    fnGetSingleReitDIVSnapshotMore() {
      this.dSingleReitDIVSnapshot = JSON.parse(JSON.stringify(this.dSingleReitDIVSnapshot_ori));

      this.dSingleReitDIVSnapshot_td_key1 = 0;
      this.dSingleReitDIVSnapshot_td_key2 = 0;
      for (var key in this.dSingleReitDIVSnapshot) {
        if (this.dSingleReitDIVSnapshot[key]['factory_name'] == '调增项') {
          this.dSingleReitDIVSnapshot_td_key1 = key;
        }
      }
      if (this.show_key1) {
        this.dSingleReitDIVSnapshot.splice(
          parseInt(this.dSingleReitDIVSnapshot_td_key1) + 1,
          0,
          ...this.add_adj_list
        );
      }
      for (var key in this.dSingleReitDIVSnapshot) {
        if (this.dSingleReitDIVSnapshot[key]['factory_name'] == '调减项') {
          this.dSingleReitDIVSnapshot_td_key2 = key;
        }
      }
      if (this.show_key2) {
        this.dSingleReitDIVSnapshot.splice(
          parseInt(this.dSingleReitDIVSnapshot_td_key2) + 1,
          0,
          ...this.sub_adj_list
        );
      }
      this.dSingleReitDIVSnapshot_td = this.dSingleReitDIVSnapshot.map((node) => {
        return Object.values(node);
      });

      this.dSingleReitDIVSnapshot_td.map((node) => {
        if (node[0] == '折旧和摊销(元)') {
          node[0] = '折旧和摊销';
        }
      });

      let ARRTMP = [];

      this.dSingleReitDIVSnapshot_td.forEach((node) => {
        var obj_1 = node[0];
        node.shift();
        node.reverse();
        node.unshift(obj_1);
        // 处理数据是不是百分比的情况
        //  let value = node[1].toString().split(".")[1]?node[1].toString().split(".")[1]:[]
        let value = node[1];
        let bpercentage =
          value.length > 12 || (Number(value) < 1 && Number(value) > 0) ? true : false;
        let ARR = [];

        if (!bpercentage) {
          node[0] = node[0] + '（万元）';

          node.forEach((e, idx) => {
            if (idx >= 1 && idx <= node.length) {
              e = e / 10000;
              e = Number(Number(e).toFixed(1)).toLocaleString();
              ARR.push(e);
            } else {
              ARR.push(e);
            }
          });
        } else {
          if (node[0] == 'DPU') {
            node[0] = node[0] + '（元）';
            node.forEach((e, idx) => {
              if (idx >= 1 && idx <= node.length) {
                e = Number(e).toFixed(4);
                ARR.push(e);
              } else {
                ARR.push(e);
              }
            });
          } else {
            node[0] = node[0] + '（%）  ';

            node.forEach((e, idx) => {
              if (idx >= 1 && idx <= node.length) {
                e = (Number(e) * 100).toFixed(2) + '%';
                ARR.push(e);
              } else {
                ARR.push(e);
              }
            });
          }
        }

        ARRTMP.push(ARR);
      });

      this.dSingleReitDIVSnapshot_td = ARRTMP;
    },

    async fnGetCbtns(data) {
      this.show_key1 = false;
      this.show_key2 = false;
      var num = 0;
      if (data == '业绩概览') {
        this.bButtons1_card1 = true;
        this.bButtons1_card2 = false;
        this.bButtons1_card3 = false;
        this.dButtons1_current = '业绩概览';
      }

      if (data == '分派表') {
        this.bButtons1_card1 = false;
        this.bButtons1_card2 = true;
        this.bButtons1_card3 = false;
        this.dButtons1_current = '分派表';
        await this.fnGetSingleReitDIVSnapshot2();
        await this.fnGetSingleReitDIVSnapshotWaterfall();
        await this.fnGetSingleReitDIVSnapshot();
        num = 1;
      }

      if (data == '基金情况') {
        this.bButtons1_card1 = false;
        this.bButtons1_card2 = false;
        this.bButtons1_card3 = true;
        this.dButtons1_current = '基金情况';
        num = 2;
      }
      this.$nextTick(() => {
        document
          .getElementsByClassName('table_area1')
          [num].scrollTo(document.getElementsByClassName('table_area1')[num].scrollWidth, 0);
      });
    },
  },
  components: {
    CSelect,
    CSelects,
    CCategoryRadio,
    CButtons,
    CButtonsDownload2,
    CTimeButtons,
    CButtonsDownloadSingle,
    CButtonsSeparate,
  },
  computed: {
    table1_width() {
      var width =
        this.ROOTFONTFIZE * (400 / 144) +
        this.ROOTFONTFIZE * (242 / 144) * (this.dSingleReitPLsnapshot_table_th.length - 1);
      console.log('table1_width', width);
      return 'width:' + width + 'px;!important;';
    },
    table2_width() {
      var width =
        this.ROOTFONTFIZE * (400 / 144) +
        this.ROOTFONTFIZE * (242 / 144) * (this.dSingleReitDIVSnapshot_th.length - 1);
      console.log('table1_width', width);
      return 'width:' + width + 'px;!important;';
    },
    table3_width() {
      var width =
        this.ROOTFONTFIZE * (400 / 144) +
        this.ROOTFONTFIZE * (242 / 144) * (this.dSingleReitFundStatus_th.length - 1);
      console.log('table1_width', width);
      return 'width:' + width + 'px;!important;';
    },
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.$nextTick(() => {
        this.initChart1();
        this.initChart2();
        this.initChart3();
        this.initChart4();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
}

.CARD {
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: rgba(29, 31, 37, 1);
  background-color: var(--charts_bgc);
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) 0px;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.CARD:last-child {
  margin-bottom: 0;
}

.CARD_tit {
  padding-left: calc(var(--ROOTFONTFIZE) * (22 / 144));
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  // height: 20px;
  color: var(--chart_title);
}

.CARD_tit2 {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.CARD_body {
  padding: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.CARD_body_tabs {
  padding: 0px calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CARD_body_tabs div {
  display: flex;
  align-items: center;
}

.el-icon-download {
  margin-left: calc(var(--ROOTFONTFIZE) * (45 / 144));
}

.CARD_body_btn_sep {
  margin-top: calc(var(--ROOTFONTFIZE) * (38 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (15 / 144));

  margin-top: 38px;
  margin-bottom: 15px;
}

.marketValue {
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.marketValue .value span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (36 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
}

.marketValue .value span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(49, 208, 104, 1);
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.marketValue .time {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(255, 255, 255, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.CARD_body_echart,
.CARD_body_table {
  height: calc(var(--ROOTFONTFIZE) * (245 / 144));
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144));
}

.choose_area {
  margin-top: calc(var(--ROOTFONTFIZE) * (24 / 144));
}

.charts_area {
  display: flex;
}

.left {
  flex: 1;
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.right {
  flex: 1;
}

// table start --------------------------------------------------------------------
.table_area {
  padding: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
  overflow-x: auto !important;
  &::-webkit-scrollbar {
    width: calc(var(--ROOTFONTFIZE) * (6 / 144));
    height: calc(var(--ROOTFONTFIZE) * (12 / 144));
    // background-color: #0e1d35;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
  }
}
.table_area1 {
  padding: 0 calc(var(--ROOTFONTFIZE) * (0 / 144));
  margin-left: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
}

table {
  // width: 100%;
  // min-width: 100%;
  // margin: auto;
  border: none;
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
  border-bottom: 1px solid var(--table_brc);
  padding-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.UnlockingShares {
}

.table_first {
  // border-bottom: 3px solid #c7c7c7 !important;
  // border-top: 2px solid #bebfbf !important;
  // background-color: #4a4c51 !important;

  border-bottom: calc(var(--ROOTFONTFIZE) * (2 / 144)) solid var(--table_brc) !important;
  border-top: calc(var(--ROOTFONTFIZE) * (2 / 144)) solid var(--table_brc) !important;
  background-color: var(--table_th_bgc) !important;
  td:nth-child(1) {
    background-color: var(--table_th_bgc) !important;
  }
}

th {
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 700;
  letter-spacing: 0.21px;
  line-height: calc(var(--ROOTFONTFIZE) * (16.76 / 144));
  color: var(--table_color);
  text-align: right;
  width: calc(var(--ROOTFONTFIZE) * (242 / 144));
}
th:nth-child(1) {
  width: calc(var(--ROOTFONTFIZE) * (400 / 144));
  position: sticky;
  left: 0;
  background-color: var(--table_th_bgc) !important;
}

.table_main {
  width: auto;
  background-color: var(--table_bgc);
  padding: 0;
  border-bottom: 1px solid var(--table_brc);
}

.table_main:hover {
  background-color: var(--table_bgc_hover);
  td:nth-child(1) {
    background-color: var(--table_bgc_hover);
  }
}
.table_main:nth-child(1) {
  background-color: var(--table_bgc_hover) !important;
}

tr {
  line-height: calc(var(--ROOTFONTFIZE) * (15 / 144));
  // padding: 0 !important;
}
tr:last-child {
  border-bottom: none !important;
}

td {
  width: calc(var(--ROOTFONTFIZE) * (242 / 144));
  text-align: right;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  letter-spacing: 0px;
  color: var(--table_color);
  padding: calc(var(--ROOTFONTFIZE) * (10 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
}

td:nth-child(1) {
  position: sticky;
  left: 0;
  width: calc(var(--ROOTFONTFIZE) * (400 / 144));
  text-align: left;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 700;
  // line-height: 10px;
  color: var(--table_color);
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: var(--table_bgc);
}

// table end --------------------------------------------------------------------

.canvasArea {
  box-sizing: border-box;
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.HEIGHT_664 {
  height: calc(var(--ROOTFONTFIZE) * (664 / 144)) !important;
}

.HEIGHT_370 {
  height: calc(var(--ROOTFONTFIZE) * (370 / 144)) !important;
}

.HEIGHT_471 {
  height: calc(var(--ROOTFONTFIZE) * (471 / 144)) !important;
}

.HEIGHT_396 {
  height: calc(var(--ROOTFONTFIZE) * (396 / 144)) !important;
}

.HEIGHT_389 {
  height: calc(var(--ROOTFONTFIZE) * (389 / 144)) !important;
}

.reportingPeriod {
  margin-left: 0 !important;
  background-color: #ff5229;
  padding-left: 0;
}

v::deep .el-select {
  margin-left: 0px !important;
}
.downloadBtnsBack_case {
  position: relative;
  .downloadBtnsBack {
    position: absolute;
    top: calc(var(--ROOTFONTFIZE) * (20 / 144));
    right: calc(var(--ROOTFONTFIZE) * (40 / 144));
    z-index: 1;
  }
}
</style>
