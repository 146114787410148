<template>
  <div class="card mb-0">
    <div class="card-title">主要近期收益表现汇总</div>
    <div class="wrap">
      <div>
        <CButtons
          :dButtons1="select_1"
          :dButtons1_current="select_1_default"
          @Cbtns_sep="select1Change" />
      </div>
      <div style="margin-top: 20px">
        <div class="flex" style="justify-content: flex-end">
          <Download
            :dButtonsDownloads="dButtonsDownloads"
            :excel_info="excel_info"
            echartRefName="download-profit" />
        </div>
        <el-table
          class="table1"
          :key="select_1_default"
          :header-cell-style="tableStyle"
          :data="page_data"
          :height="OFFSETHEIGHT - 320"
          @cell-click="fnClick"
          style="width: 100%; margin-top: 4px">
          <el-table-column v-if="select_1_default === '个股'" label="代码" width="95">
            <template slot-scope="scope">
              {{ select_1_default === '个股' ? scope.row.reit_code : '-' }}
            </template>
          </el-table-column>
          <el-table-column v-if="select_1_default === '个股'" label="简称" min-width="160">
            <template slot-scope="scope">
              {{ select_1_default === '个股' ? scope.row.reitsName : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="板块" min-width="70">
            <template slot-scope="scope">
              {{ select_1_default === '个股' ? scope.row.category_2 : scope.row.reit_code }}
            </template>
          </el-table-column>
          <el-table-column
            label="现价"
            min-width="80"
            v-if="select_1_default === '个股'"
            prop="now_price"
            sortable>
            <template slot-scope="scope">
              {{ scope.row.now_price | fix2 }}
            </template>
          </el-table-column>
          <el-table-column
            label="涨跌幅"
            min-width="84"
            v-if="select_1_default === '个股'"
            prop="growth_rate"
            sortable>
            <template slot-scope="scope">
              {{ scope.row.growth_rate | fix2_1 }}
            </template>
          </el-table-column>
          <el-table-column
            label="日增幅"
            min-width="120"
            v-if="select_1_default === '大类'"
            prop="growth_rate"
            sortable>
            <template slot-scope="scope">
              <div class="flex items-center">
                <div
                  class="color-bar"
                  :style="{
                    '--bar_color': '#5150bf',
                    '--w': calcPx(
                      normalize(
                        scope.row.growth_rate,
                        data_info.growth_rate.max,
                        data_info.growth_rate.min
                      )
                    ),
                  }"></div>
                <div>{{ scope.row.growth_rate | pct }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="now_amt"
            v-if="select_1_default === '大类'"
            label="成交额(亿元)"
            min-width="140"
            sortable>
            <template slot-scope="scope">
              <div class="flex items-center">
                <div
                  class="color-bar"
                  :style="{
                    '--bar_color': '#5150bf',
                    '--w': calcPx(
                      normalize(scope.row.now_amt, data_info.now_amt.max, data_info.now_amt.min)
                    ),
                  }"></div>
                <div>{{ scope.row.now_amt | fix8 }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="now_amt"
            v-if="select_1_default === '个股'"
            label="成交额(万元)"
            min-width="160"
            sortable>
            <template slot-scope="scope">
              <div class="flex items-center">
                <div
                  class="color-bar"
                  :style="{
                    '--bar_color': '#5150bf',
                    '--w': calcPx(
                      normalize(scope.row.now_amt, data_info.now_amt.max, data_info.now_amt.min)
                    ),
                  }"></div>
                <div>{{ scope.row.now_amt | amt6 }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="1D" label="1D" width="77" class-name="color-box" sortable>
            <template slot-scope="scope">
              <div
                :style="{
                  '--box-color': getGradientColor(scope.row['1D_s'] || 0),
                  color: scope.row['1D_s'] > 0.3 ? 'black' : '#fff',
                }">
                {{ scope.row['1D'] | pct }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="1W" label="1W" width="77" class-name="color-box" sortable>
            <template slot-scope="scope">
              <div
                :style="{
                  '--box-color': getGradientColor(scope.row['1W_s'] || 0),
                  color: scope.row['1W_s'] > 0.3 ? 'black' : '#fff',
                }">
                {{ scope.row['1W'] | pct }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="1M" label="1M" width="77" class-name="color-box" sortable>
            <template slot-scope="scope">
              <div
                :style="{
                  '--box-color': getGradientColor(scope.row['1M_s'] || 0),
                  color: scope.row['1M_s'] > 0.3 ? 'black' : '#fff',
                }">
                {{ scope.row['1M'] | pct }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="3M" label="3M" width="77" class-name="color-box" sortable>
            <template slot-scope="scope">
              <div
                :style="{
                  '--box-color': getGradientColor(scope.row['3M_s'] || 0),
                  color: scope.row['3M_s'] > 0.3 ? 'black' : '#fff',
                }">
                {{ scope.row['3M'] | pct }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="6M" label="6M" width="77" class-name="color-box" sortable>
            <template slot-scope="scope">
              <div
                :style="{
                  '--box-color': getGradientColor(scope.row['6M_s'] || 0),
                  color: scope.row['6M_s'] > 0.3 ? 'black' : '#fff',
                }">
                {{ scope.row['6M'] | pct }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="YTD" label="YTD" width="77" class-name="color-box" sortable>
            <template slot-scope="scope">
              <div
                :style="{
                  '--box-color': getGradientColor(scope.row.YTD_s || 0),
                  color: scope.row['YTD_s'] > 0.3 ? 'black' : '#fff',
                }">
                {{ scope.row.YTD | pct }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="2024" label="2024" width="77" class-name="color-box" sortable>
            <template slot-scope="scope">
              <div
                :style="{
                  '--box-color': getGradientColor(scope.row['2024_s'] || 0),
                  color: scope.row['2024_s'] > 0.3 ? 'black' : '#fff',
                }">
                {{ scope.row['2024'] | pct }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="MAX" width="77" class-name="color-box" prop="MAX" sortable>
            <template slot-scope="scope">
              <div
                :style="{
                  '--box-color': getGradientColor(scope.row.MAX_s || 0),
                  color: scope.row['MAX_s'] > 0.3 ? 'black' : '#fff',
                }">
                {{ scope.row.MAX | pct }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="流通市值(亿元)" min-width="140" prop="market_cap_float" sortable>
            <template slot-scope="scope">
              <div class="flex items-center">
                <div
                  class="color-bar"
                  :style="{
                    '--bar_color': '#5ae8ed',
                    '--w': calcPx(
                      normalize(
                        scope.row.market_cap_float,
                        data_info.market_cap_float.max,
                        data_info.market_cap_float.min
                      )
                    ),
                  }"></div>
                <div>{{ scope.row.market_cap_float | fix9 }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="市值(亿元)" prop="market_cap" sortable min-width="140">
            <template slot-scope="scope">
              <div class="flex items-center">
                <div
                  class="color-bar"
                  :style="{
                    '--bar_color': '#5ae8ed',
                    '--w': calcPx(
                      normalize(
                        scope.row.market_cap,
                        data_info.market_cap.max,
                        data_info.market_cap.min
                      )
                    ),
                  }"></div>
                <div>{{ scope.row.market_cap | fix9 }}</div>
              </div>
            </template></el-table-column
          >
          <el-table-column label="目前分派率" prop="div_yield" sortable min-width="110">
            <template slot-scope="scope">
              <div class="flex items-center">
                <div
                  v-if="scope.row.div_yield"
                  class="color-bar"
                  :style="{ '--bar_color': '#ff3300', '--w': calcPx(scope.row.div_yield) }"></div>
                <div v-if="scope.row.div_yield">{{ scope.row.div_yield | fix2_1 }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import CButtons from '../Basic/CButtons_separate.vue';
import Download from '../Basic/Download.vue';
import { fnReturnTableStyle } from '@/utils/util';
import { getGradientColor, numberFix2, number2Pct, normalize } from '@/utils';

const calcPx = (n) => {
  return n * 70 + 'px';
};

const getMaxAndMin = (data, k) => {
  const all = data.map((item) => item[k]);
  return {
    max: Math.max(...all),
    min: Math.min(...all),
  };
};

export default {
  name: 'Profitability',
  components: { CButtons, Download },
  data() {
    return {
      CODELIST: Object.freeze({}),
      ROOTFONTFIZE: '',
      OFFSETHEIGHT: 0,
      select_1: Object.freeze(['大类', '个股']),
      select_1_default: '大类',
      page_data: Object.freeze([]),
      data_info: Object.freeze({ market_cap_float: {}, market_cap: {} }),
      dButtonsDownloads: Object.freeze([
        /* {
          id: '241020',
          name: '下载图片',
        }, */
        {
          id: '241020',
          name: '下载表格',
        },
      ]),
      excel_info: Object.freeze({
        /*overviewName: '',
          excel_data:[],
          json_fields:{}
        */
      }),
    };
  },
  computed: {
    tableStyle() {
      const val = this.$store.state.theme === 'dark';
      return () => fnReturnTableStyle(val);
      // return fnReturnTableStyle(val);
    },
  },
  filters: {
    fix2_1(n) {
      if (n === '') return '';
      return numberFix2(n * 100) + '%';
    },
    fix2(n) {
      if (n === '') return '';
      return numberFix2(n);
    },
    pct(n) {
      if (n === '') return '';
      return number2Pct(n);
    },
    fix8(n) {
      if (n === '') return '';
      return numberFix2(n / 100000000, 3);
    },
    fix9(n) {
      if (n === '') return '';
      return numberFix2(n / 10000, 3);
    },
    amt5(n) {
      if (n === '') return '';
      return numberFix2(n / 10000, 3);
    },
    amt6(n) {
      if (n === '') return '';
      return numberFix2(n / 10000, 2);
    },
  },
  methods: {
    fnClick(row, column, cell) {
      if (row.reit_code) {
        let arr = [row['reitsName'], row['reit_code']];
        this.$eventBus.$emit('skipPage', arr);
      }
    },
    calcPx,
    normalize,
    number2Pct,
    getGradientColor,
    select1Change(v) {
      this.select_1_default = v;
      this.loadData();
    },
    pageInit() {
      if (this._page_init) return;
      this.loadData();
    },
    getExcelInfo() {
      const overviewName = '主要近期收益表现汇总-' + this.select_1_default;
      const json_fields = {
        代码: 'reit_code',
        简称: 'reitsName',
        板块: this.select_1_default === '个股' ? 'category_1' : 'reit_code',
        现价: 'now_price',
        日增幅: 'growth_rate',
        成交额: 'now_amt',
        '1D': '1D',
        '1W': '1W',
        '1M': '1M',
        '3M': '3M',
        '6M': '6M',
        YTD: 'YTD',
        2024: '2024',
        MAX: 'MAX',
        流通市值: 'market_cap_float',
        市值: 'market_cap',
        目前分派率: 'div_yield',
      };
      if (this.select_1_default !== '个股') {
        delete json_fields['代码'];
        delete json_fields['简称'];
        delete json_fields['现价'];
      } else {
        delete json_fields['日增幅'];
      }
      this.excel_info = Object.freeze({
        json_fields,
        overviewName,
        excel_data: this.page_data,
      });
    },
    loadData() {
      this.page_data = Object.freeze([]);
      if (this.select_1_default === '大类') {
        this.loadDataByCategory();
      } else {
        this.loadDataBySingle();
      }
    },
    async loadDataBySingle() {
      const CLASSIFY = JSON.parse(localStorage.getItem('CLASSIFY')) || [];
      const { data } = await this.$https.get(
        `/api/v2_2/period_risk_single?annualize=1&r_type_=${CLASSIFY.join('&r_type_=')}`
      );
      if (data.code !== 200) return this.$message.error(data.msg);
      this._page_init = true;
      this.page_data = Object.freeze(data.data);
      this.data_info = Object.freeze({
        market_cap_float: getMaxAndMin(data.data, 'market_cap_float'),
        market_cap: getMaxAndMin(data.data, 'market_cap'),
        now_amt: getMaxAndMin(data.data, 'now_amt'),
      });
      this.getExcelInfo();
    },
    async loadDataByCategory() {
      const CLASSIFY = JSON.parse(localStorage.getItem('CLASSIFY')) || [];
      const { data } = await this.$https.get(
        `/api/v2_2/period_risk_category?annualize=1&r_type_=${CLASSIFY.join('&r_type_=')}`
      );
      if (data.code !== 200) return this.$message.error(data.msg);
      this._page_init = true;
      this.page_data = Object.freeze(data.data);
      this.data_info = Object.freeze({
        market_cap_float: getMaxAndMin(data.data, 'market_cap_float'),
        market_cap: getMaxAndMin(data.data, 'market_cap'),
        now_amt: getMaxAndMin(data.data, 'now_amt'),
        growth_rate: getMaxAndMin(data.data, 'growth_rate'),
      });
      this.getExcelInfo();
    },
    // 获取当前屏幕宽度
    fnGetWidth() {
      /* var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
      return; */
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1440 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      let OFFSETHEIGHT = element.offsetHeight;
      this.OFFSETHEIGHT = OFFSETHEIGHT;
      return;
    },
    resize() {
      this.fnGetWidth();
      this.echart_instance && this.echart_instance.resize();
    },
  },
  created() {
    const CODELIST = JSON.parse(localStorage.getItem('CODELIST')) || {};
    const remark_CODELIST = CODELIST.reduce((p, c) => {
      const { reitCode, ...args } = c;
      p[reitCode] = args;
      return p;
    }, {});
    this.CODELIST = Object.freeze(remark_CODELIST);
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    this.echart_instance && this.echart_instance.dispose();
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style scoped lang="less">
.card {
  background-color: rgba(29, 31, 37, 1);
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  height: auto;
  .card-title {
    color: var(--chart_title);
    font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
    font-weight: 700;
    // border-left: 5px solid #1573fe;
    border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
    padding-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
    position: relative;
  }
  .wrap {
    width: 97%;
    margin: auto;
    padding-top: 20px;
    .container {
      margin-left: 0;
    }
  }
}
.mb-0 {
  margin-bottom: 0;
}
.pb-0 {
  padding-bottom: 0;
}

//fix 滚动条重叠部分背景颜色
/deep/.el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: transparent;
}

/deep/.flex {
  display: flex;
}
/deep/.items-center {
  align-items: center;
}
/deep/.space-between {
  justify-content: space-between;
}
/deep/.justify-center {
  justify-content: center;
}
/deep/.color-bar {
  width: var(--w);
  min-width: 1px;
  height: 16px;
  margin-right: 10px;
  background-color: var(--bar_color);
}

/deep/.color-box {
  padding: 0 !important;
}
/deep/.color-box .cell {
  height: 100%;
  width: 100%;
  padding: 0;
}
/deep/.color-box .cell > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #fff;
  background-color: var(--box-color);
}

/* el-table start */

::v-deep .table1 table {
  border: none;
}
::v-deep .el-table th.el-table__cell > .cell {
  font-size: calc(var(--ROOTFONTFIZE) * (13 / 144));
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (13 / 144));
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid var(--table_brc);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  /* background-color: var(--table_color_hover); */
  color: var(--table_color_hover);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell:hover {
  cursor: pointer;
}

::v-deep .el-table::before {
  background-color: var(--table_bgc);
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: var(--table_bgc);
}

/* el-table end */
</style>
