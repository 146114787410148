<template>
  <div :style="cssVars">
    <div class="title">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="市场表现" name="first">
          <Performance ref="first" />
        </el-tab-pane>

        <el-tab-pane label="收益情况" name="second">
          <Profitability ref="second" />
        </el-tab-pane>

        <el-tab-pane label="分红情况" name="third">
          <Dividend ref="third" />
        </el-tab-pane>
        <el-tab-pane label="交易排名" name="fourth">
          <TradingRank ref="fourth" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Performance from './Performance.vue';
import Profitability from './Profitability.vue';
import Dividend from './Dividend.vue';
import TradingRank from './TradingRank.vue';

export default {
  name: 'MarketNewsletter',
  components: {
    Dividend,
    Performance,
    Profitability,
    TradingRank,
  },
  data() {
    return {
      activeName: 'first',
      TABPANEHEIGHT: 0,
      ROOTFONTFIZE: 0,
    };
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
        '--TABPANEHEIGHT': this.TABPANEHEIGHT + 'px',
      };
    },
  },
  methods: {
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;

      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      window.sessionStorage.setItem('ROOTFONTFIZE', this.ROOTFONTFIZE);

      let OFFSETHEIGHT = element.offsetHeight;
      this.TABPANEHEIGHT = OFFSETHEIGHT - 135;
    },

    handleClick(e) {
      this.activeName = e.name;
      localStorage.setItem('activeName', this.activeName);
      this.tabChange(e.label);
    },
    tabChange(label) {
      this.$eventBus.$emit('setBreadcrumbs', label || '市场走势');
      if (this.activeName == 'first') {
        this.$refs.first.pageInit();
      }
      if (this.activeName == 'second') {
        this.$refs.second.pageInit();
      }
      if (this.activeName == 'third') {
        this.$refs.third.pageInit();
      }

      if (this.activeName == 'fourth') {
        this.$refs.fourth.pageInit();
      }
    },
  },
  created() {
    this.$nextTick(() => {
      let name = localStorage.getItem('activeName');
      this.handleClick({
        name: name,
      });
    });
  },
  mounted() {
    this.fnGetWidth();
  },
};
</script>

<style lang="less" scoped>
// el-tabs
.el-tabs {
  background-color: var(--bigger-card-bgc);
}

::v-deep .el-tabs__item {
  color: var(--el_tabs_item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
  color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
  color: var(--el_tabs_item_color_active) !important;
  font-size: 18px;
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  background-color: var(--primary-color) !important;
}

::v-deep .el-tab-pane {
  display: block;
  height: var(--TABPANEHEIGHT);
  overflow-y: auto;
  box-sizing: border-box;

  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}
</style>
