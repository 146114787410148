<template>
  <div class="container">
    <slot></slot>
    <el-select ref="mySelect" :popper-append-to-body="false" popper-class="last-check" :style="cssVars"
      @change="handleChange" :value="value" placeholder="请选择">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
      <el-option v-show="show_custom" style="width: 200px;" value="">
        <div @click.stop.prevent>
          自定义
          <el-input class="custom_input" ref="customInput" type="number" v-model="inputValue" placeholder="输入后回车"
            size="mini" @keyup.enter.native="handleEnter" />
          %
        </div>
      </el-option>

    </el-select>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  props: {
    options: {
      type: Array,
      default: [],
    },
    width: {
      type: Number,
      default: 0,
    },
    show_custom: {
      type: Boolean,
      default: false,
    },
    default: {
      type: String,
      default: '',
    },
  },
  watch: {
    default(now) {
      this.value = now;
    },
  },
  methods: {
    handleChange(val) {
      console.log("handleChange", val);
      this.$emit('CSelect_select_val', val);
    },
    handleEnter() {
      if (this.inputValue.trim()) {
        console.log("handleEnter", this.inputValue);
        let value = parseFloat(this.inputValue) / 100 + "";
        let label = this.inputValue + "%";
        const newOption = {
          value: value,
          label: label
        }
        this.options.push(newOption);
        this.$emit("update:options", this.options);
        setTimeout(() => {
          this.$emit('CSelect_select_val', value);
          this.$refs.mySelect.blur();
        }, 100);

      }
      this.inputValue = ''
    },
  },
  components: {},
  computed: {
    cssVars() {
      return {
        'width': this.width + 'px',
        '--val3': this.val3,
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
  },
  created() {
    this.ROOTFONTFIZE = window.sessionStorage.getItem('ROOTFONTFIZE');
  },
  data() {
    return {
      inputValue: "",
      value: this.default,
      val3: this.wide
        ? '380px'
        : this.mini
          ? '130px'
          : this.auto
            ? 'auto'
            : this.middle
              ? '250px'
              : '300px',
      ROOTFONTFIZE: '',
    };
  },
};
</script>

<style scoped>
.container {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--chart_title) !important;
  padding-bottom: 0 !important;
}

.custom_input {
  border: 1px solid var(--el_tabs_item_color_active) !important;
  border-radius: 3px;
  box-sizing: border-box !important;
  width: 100px;
  padding-left: 5px;
  margin-left: 0px;
}

.el-select {
  margin-left: 15px;
  height: 35px;
  border-radius: 3px;
  box-sizing: border-box !important;
  background-color: #29292c;
  background-color: var(--Btn-bgc);
  padding: 3px 10px;
  display: flex !important;
  align-items: center !important;
}

::v-deep .el-select__tags-text {
  display: flex !important;
  align-items: center !important;
}

::v-deep .el-select .el-input .el-select__caret {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transform: rotate(180deg) !important;
}

::v-deep .el-select .el-input .el-select__caret.is-reverse {
  transform: rotate(0deg) !important;
}

::v-deep .el-select .el-input__inner {
  background-color: rgba(0, 0, 255, 0) !important;
  border: none;
  margin: 0 !important;
  padding: 5px 0px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: var(--Select-color2);
  display: flex !important;
  align-items: center !important;
}

::v-deep .el-tag.el-tag--info {
  background-color: var(--Select-bgc) !important;
  background-color: #fff;
  color: var(--Btn-color2) !important;
  border: none !important;
  font-weight: normal !important;
  width: auto !important;
}

::v-deep .el-tag.el-tag--info:nth-child(1) {
  margin-left: 0 !important;
}

::v-deep .el-select-dropdown {
  background-color: var(--Select-bgc2) !important;
  border: none !important;
  margin-left: -20px;
}

::v-deep .el-select-dropdown__item {
  background-color: var(--Select-bgc2) !important;
  color: var(--Select-color) !important;
}

::v-deep .el-select-dropdown__item.selected {
  color: var(--primary-color) !important;
}

::v-deep .el-select .el-tag__close.el-icon-close {
  background-color: #232324 !important;
  color: #fff !important;
}

::v-deep .last.el-select .el-tag__close.el-icon-close {
  display: none;
}
</style>
