export default [
  {
    version: 'v2.2',
    date: '2025/02/18',
    content: `<div class="content">
      各位葱花投研的用户，本产品目前正式更新为完整的v2.2版本的提升，对于期间大部分机构客户提出的建议，我们增加了较多的数据，提升了部分算法，并且加快了体验速度与数据颗粒度，本轮更新的主要调整部分如下：<br/>
      <br/>
      <span>新增板块大类——市场动态（本次主要重大更新！）：</span>
      <div>2.1 日内走势提示：</div>
      <div class="s_t">
        <span>2.1.1 中证REITs全收益指数 - 增加日内分比成交板块；</span><br/>
        <span>2.1.2 中证REITs全收益指数 - 增加日内走势分钟线及对应的领跌领涨板块；</span><br/>
        <span>2.1.3 中证REITs全收益指数 - 增加日内实时倍数及分派率；</span><br/>
        <span>2.1.4 公募REITs日内涨跌幅排行</span><br/>
      </div>
      <div>2.2 日内收益板块：</div>
      <div class="s_t">
        2.2.1 增加了<span>大类板块的，日内实时收益表现、实时分派率，并可以排序；</span><br/>
        2.2.2 增加了<span>个股板块的，日内实时收益表现、实时分派率，并可以排序；</span>
      </div>
      <div>2.3 分红板块汇总：</div>
      <div class="s_t">
        2.3.1 对于近3个月的分红情况进行汇总，<span>提示了单次分红收益率，及对应时间段分红次数等，以便于帮助投资者可以快速的了解实际分红情况</span><br/>
        <span>2.3.2 展示了分板块，分年份的平均分红次数汇总，以帮助投资者更快捷的捕捉分红策略机会；</span>
      </div>
      <div>2.4 交易排名：</div>
      <div class="s_t">
        2.4.1 增加了不同时间段，大类、个股的交易情况<span>（成交额、大宗成交额、换手率、资金净流入、主力资金净流入、主力主动资金净流入）</span>排名的快捷排序/筛选；<br/>
        2.4.2 增加了不同时间段，大类、个股的<span>大宗交易</span>明细表格；
      </div>
      <br/>
      <span>历史版本v2.1 优化：</span>
      <div>1.Dashboard大类资产板块：</div>
      <div class="s_t">
        <span>1.1 首页增加了大类资产比较，可以选择不同时间段/年份</span>
      </div>
      <div>2. 市场走势板块<span>（重大更新）</span>：</div>
      <div class="s_t">
        2.1-2.3 每个板块均增加了：<span>1D,1W,1M时间段，以便于周报月报日报的撰写；</span><br/>
        <span>2.1-2.3 每个板块均增加了：年份筛选，可以筛选2021-2024以来的每个年份</span>
      </div>
      <div>3.财报板块：</div>
      <div class="s_t">
        	3.1 waterfall 分析板块<span>开放了股的waterfall的选择</span>；
      </div>
      <div>4. 估值分析板块（重大更新）：</div>
      <div class="s_t">
        IRR/NPV 板块<span>新增了自定义按钮</span>，可以筛选：要求回报、增速自调、退出年份自调、退出折现率等多种参数；
      </div>
    </div>`,
  },
  {
    version: 'v2.1',
    date: '2025/01/16',
    content: `<div class="content">
    各位葱花投研的用户，本产品目前正式更新为完整的v2.1版本，对于期间大部分机构客户提出的建议，我们增加了较多的数据，提升了部分算法，并且加快了体验速度与数据颗粒度，本轮更新的主要调整部分如下：<br/>
    <br/>
    <span>运营数据更新</span><br/>
     <div class="b_t">1.增加高速公路板块</div>
    <div class="s_t">
      <span>1.1 个股—> 底层资产—> 运营数据</span><br/>
      <span>1.2 高速公路增加了日均收费车辆、当月路费收入等</span><br/>
      <span>1.3 根据上述关键驱动因子，新增了预测板块；</span><br/>
    </div>
       <div class="b_t">2.增加产业园板块</div>
    <div class="s_t">
      <span>2.1 个股—> 底层资产—> 运营数据</span><br/>
      <span>2.2 产业园增加了每季度入住率，租金及根据财报的等效租金</span><br/>
      <span>2.3 根据上述关键驱动因子，新增了预测板块；</span><br/>
    </div>
    <div class="b_t">3.增加分红修正</div>
    <div class="s_t">
      <span>3.1 估值分析—> 动态估值</span><br/>
      <span>分红修正：</span><br/>
      <span>当REITS发生首期返还本金分红时，进行纯本金部分的剔除以还原真实分派率。</span>
    </div>
    </div>`,
  },
  {
    version: 'v2.0',
    date: '2024/11/09',
    content: `<div class="content">
    各位葱花投研的用户，本产品目前正式更新为完整的v2.0版本，对于期间大部分机构客户提出的建议，我们增加了较多的数据，提升了部分算法，并且加快了体验速度与数据颗粒度，本轮更新的主要调整部分如下：<br/>
    <br/>
    <span>大类及行业部分：</span><br/>
     <div class="b_t">1.Dashboard大类资产板块：</div>
  <div class="s_t">
    <span>1.1 增加了Yield Spread部分的选择按钮，可选择不同收益率的价差;</span>
  </div>
  <div class="b_t">
    2. 市场动态板块：
  </div>
  <div class="s_t">
    2.1 增加了 <span>最大回撤的板块+个股数据及对应的下载功能</span> ；
  </div>
  <div class="b_t">
    3. 财报分析板块<span>（重大更新）</span> ：
  </div>
  <div class="s_t">
    3.1 财报分析 – 增加了所有：<span>季报、半年报及年报数据</span>可以柱状对比；<br/>
    3.2 Yield传递推导 – Waterfall部分增加更多细分层级，包含<span>Net Yield, FFO Yield和AFFO Yield 拆分</span>；<br/>
    3.3 增加了<span>IPO完成率数据（总收入、EBITDA及可供分配）</span>；<br/>
    3.4 增加了<span>管理人费用分析数据</span>；
  </div>
  <div class="b_t">4. 估值分析板块<span>（重大更新）</span>：</div>
  <div class="s_t">
    4.1 增加了<span>动态估值 - 倍数年限折算功能</span>；<br/>
    <span>4.2 增加了截面 - 估值分析器工具</span>，并增加了6大功能预设包括：<br/>
    <span>REITs利息本金拆分(个股) 、 REITs利息本金拆分(行业)</span><br/>
    <span>分派率与NPV对比矩阵</span><br/>
    <span>IRR与Implied Cap Rate</span><br/>
    <span>行业实际增速、个股实际增速</span><br/>
    <span>除了上述预设外，可以高强度的进行个券指标筛选功能</span>；
  </div>
  <div class="b_t">5. 趋势洞察板块：</div>
  <div class="s_t">5.1 <span>认购信息</span>– 可当日直接同步至IPO信息表格，可<span>快捷了解战配及发行情况</span>；</div>
  <div class="b_t"><span>个股部分</span>：</div>
  <div class="b_t">1.REITs概况： <span>增加了历次IPO及扩募的信息明细打开功能</span>；</div>
  <div class="b_t">2.市场数据 – 大宗交易： <span>增加了个券的大宗交易明细下载功能</span>；</div>
  <div class="b_t">3. <span>财报分析（重大更新）</span>：</div>
  <div class="s_t">
    3.1 减少了原来只能显示5张财报限制，可以使用滚轴跨更多财报期分析；<br/>
    3.2 增加了<span>调增项、调减项明细的Waterfall显示、财报细项一键即知，1小时内可直接分拆</span>；<br/>
    3.3 修复了原有分派表部分显示的bug;
  </div>
  <div class="b_t">
    <span>4. 股东分红（重大更新）</span>：
  </div>
  <div class="s_t">
    4.1 增加了<span>投资人数据，对于财报期中的</span><br/>
    <span>投资人类型、投资人金额，和投资人的机构类型，直接解析，可快捷获得不同财报期结构</span>；
  </div>
    </div>`,
  },
  {
    version: 'v1.5',
    date: '2024/10/09',
    content: `<div class="content">
        各位葱花投研的用户，本产品于近期根据大家的使用习惯，与日常反应的问题，进行了第一轮的前期Bug修复及对应的功能优化已经完成，目前发布版本为v1.5版本更新（不是完整的v2.0更新版本），本轮更新的主要调整部分如下：<br />
        <span>大类及行业部分：</span>
        <div class="b_t">1.Dashboard大类资产板块：</div>
        <div class="s_t">
          1.1 修复了部分数据显示bug;<br />
          <span>1.2 增加了DR007数据对比;</span>
        </div>
        <div class="b_t">2.市场动态板块：</div>
        <div class="s_t">
          2.1 增加了指数部分<span>（大类+细分）的下载功能</span>；<br />
          2.2 收益分析增加了数据截至日期显示；<br />
          2.3 年化收益率及波动率对比，调整为：<span>行业+个股平行对比</span>；<br />
          2.4 风险分析，<span>增加 REITs行业类别与中证REITs指数自身对比，</span
          >以了解板块和个券的beta水平；<br />
          2.5 修复了其他细节数据显示bug;
        </div>
        <div class="b_t">3.财报分析板块：</div>
        <div class="s_t">3.1 调整了图形显示顺序，从最早年份到最新年份，从左到右顺序调整；</div>
        <div class="b_t">4.估值分析板块<span>（重大更新）</span>：</div>
        <div class="s_t">
          4.1 <span>增加了新指标：隐含Cap Rate 、PAFFO口径数据；</span><br />
          4.2 <span>新数据同步增加：历史估值、估值bands及平行估值分析；</span><br />
          4.3 <span> IRR/NPV: </span><br />
          <span>添加了IRR/NPV切换模式，可以切换NPV估值结果数据</span><br />
          <span>增加了IRR/NPV都可以多行业，多选模式，同一数据可以平行多图对比；</span><br />
          <span
            >增加了增速切换方式，原本为默认行业增速回填，目前模式：行业增速、个股增速、无增速；多档切换（v2.0后可自定义）</span
          ><br />
          <span>NPV模式增加，自设要求回报率切换，可在4-6%之间的要求回报，切换NPV结果</span><br />
        </div>
        <div class="b_t">
          <span>个股部分：</span><br />
          1. REITs概况： <span>目前分钟线改为日内实时分钟线；</span><br />
          2. REITs估值部分：<span>增加了隐含Cap Rate 、PAFFO口径数据</span><br />
          3. REITs解禁份额：<span>使用了新的OCR及AI分析工具，目前准确率及完整度更高；</span><br />
        </div>
      </div>`,
  },
];
