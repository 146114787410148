import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/components/Login';
import Home from '@/components/Home';
import Welcome from '@/components/Welcome';
import Dashboard from '@/components/Dashboard';
import MarketDynamics from '@/components/MarketDynamics';

// import RiskRelated from '@/components/RiskRelated'

import FinancialReport from '@/components/FinancialReport/index';

import UnderlyingAssets from '@/components/UnderlyingAssets/index';

import ValuationAnalysis from '@/components/ValuationAnalysis/index';

import TrendInights from '@/components/TrendInights/index';

import IndividualShare from '@/components/IndividualShare/index';
import MarketNewsletter from '@/components/MarketNewsletter/index';

Vue.use(VueRouter);

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;

// 修改原型对象上种的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },

  {
    path: '/home',
    component: Home,
    redirect: '/dashboard',
    children: [
      { path: '/dashboard', component: Dashboard },
      { path: '/welcome', component: Welcome },
      { path: '/marketDynamics', component: MarketDynamics },
      { path: '/financialReport', component: FinancialReport },
      { path: '/underlyingAssets', component: UnderlyingAssets },
      { path: '/valuationAnalysis', component: ValuationAnalysis },
      { path: '/trendInights', component: TrendInights },
      { path: '/individualShare', component: IndividualShare },
      { path: '/market-newsletter', component: MarketNewsletter },

      // {path:'/riskRelated',component:RiskRelated},
    ],
  },
];
const router = new VueRouter({
  mode: 'hash',
  routes,
});
//
//挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作
// router.beforeEach((to,from,next)=>{
//   if(to.path==='/login'){
//      return next()
//   }

//   // 获取token
//   const tokenStr=window.sessionStorage.getItem('token');

//   if(!tokenStr){
//      return next('/login');
//   }
//   next()
// })

export default router;
