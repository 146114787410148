export default
    {
        content: `<div class="content">
<br/>发布日期：2025年【】月【】日
<br/>生效日期：2025年【】月【】日<br/>

<br/>上海葱花投研智能科技有限公司（下称“我们”）非常重视您的隐私保护。在您使用我们的产品及服务前，请仔细阅读葱花投研隐私政策（以下简称“本隐私政策”），我们将通过本隐私政策告知您，我们如何收集、使用、存储、处理和保护您的个人信息。
<br/>本隐私政策所称“葱花投研网站”包括但不限于葱花投研数据库（当前网址为https://database.chopinsight.cn/，如果将来我们变更网址或增加其他网址，请以届时的网址为准）、微信公众号“Alternative”以及微信视频号“葱花拌豆腐渣”或其他公众号平台、微信小程序或其他小程序、以及可能不时涉及的其他互联网媒介等。
<br/>有关您个人信息权益的重要条款已用加粗及下划线形式提示，请您特别关注。如果对本隐私政策有任何疑问，您可以通过我们在本隐私政策中公布的联系方式与我们取得联系。<br/>

<br/>一、本隐私政策的管辖法律和适用范围
<br/>（一）本隐私政策的管辖法律
<br/>本隐私政策依照中华人民共和国（“中国”，为本隐私政策之目的，不包括香港特别行政区、澳门特别行政区和台湾地区）法律（不包括冲突法）制定和解释，适用中国法律并仅受中国法律管辖。
<br/>本隐私政策所称中国法律，指中国的立法机关或政府机构不时颁布的法律、法规、规章、规范性文件、以及其他形式的监管规定或政府命令。
<br/>（二）本隐私政策的适用范围
<br/>本隐私政策适用于我们提供的所有产品，您访问葱花投研网站和使用葱花投研提供的服务，均适用本隐私政策。不适用于通过我们的服务而接入的其他第三方产品或服务（“第三方”），第三方可能有自己的隐私政策，请您仔细阅读其隐私政策。
<br/>二、我们如何收集和使用您的个人信息
<br/>为了更好地向您提供我们的产品及服务，我们会按照如下方式收集和使用你的信息。如你选择不提供这些信息，你可能无法注册成为我们的用户或无法享受我们提供的某些产品或服务，或者无法达到相关产品或服务拟达到的效果。
<br/>（一）个人信息
<br/>1.账号信息
<br/>当您注册葱花投研网站账号时，可能需要您提供【实名制手机号】等必要信息；
<br/>当您使用第三方账号（包括但不限于微信、QQ、邮箱等）使用葱花投研产品时，您可能需要授权葱花投研获取您的第三方账号信息（包括头像、昵称及您授权的其他信息）。当我们基于授权从第三方获取您的个人信息时，我们将严格遵守中国法律的相关规定。如果我们开展业务需要进行的个人信息处理活动超出了您授权的处理范围，我们将在处理您的个人信息前取得您的明示同意。
<br/>2.使用行为信息
<br/>为保证提供葱花投研产品的稳定性、安全性以及可持续性，我们可能会收集您的浏览、互动（包括关注、收藏、点赞）、搜索等行为记录和操作日志，留存您发布的内容（如适用）。
<br/>我们也可能据此向您推荐您可能感兴趣的内容。
<br/>3.设备信息和服务日志
<br/>为了保障葱花投研产品的运行安全，我们会收集您的设备信息和网络日志信息；
<br/>其中我们收集的设备信息包括设备型号、操作系统、操作系统版本、分辨率、网络信息、唯一设备标识符、MAC地址、浏览器类型/版本/内核、使用的语言、电信运营商、手机网络。
<br/>4.系统通知功能
<br/>您可自主选择是否使用的系统通知功能，在首次使用此项功能之前，我们会以弹窗形式事先请求获取您设备的通知权限，您可以自主选择是否作出授权，也可以在您的设备的“设置－应用和通知”路径下（视不同设备称谓或具体路径可能有所差异）自主关闭或重新开启该权限。如果您拒绝授权该权限，您将无法使用此项功能，但并不影响您使用其他功能。
<br/>（二）管理个人信息的权利
<br/>我们保障您对自己的个人信息行使以下权利：访问和编辑个人信息、更正个人信息、删除个人信息、改变您授权同意的范围或撤回授权、注销账号。为了保障您的账号及个人信息安全，在涉及部分请求（如注销账号等）时，我们可能需要您提供相应方式证明您的身份和请求的正当性，我们将在收到您反馈并验证您的身份后的30天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定的成本费用。
<br/>（三）保障葱花投研网站和相关服务的运行安全
<br/>为满足《中华人民共和国网络安全法》（以下简称“网络安全法”）等法律法规的要求和网络安全保障的需要，为您提供安全、可靠的产品与服务，保障系统运行的稳定性和安全性，防止您的账号被盗、个人信息被非法获取，保护您的账号安全，我们需要收集您的网络信息（IP地址）、设备信息（设备标识符、操作系统和应用程序版本、浏览器类型）、日志信息、搜索关键字信息来判断您的账号风险，以及预防、发现、调查危害系统运行安全、非法或违反我们规则、协议的行为。我们也会收集您的前述设备信息、网络信息、日志信息用于对我们产品或服务系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用我们的产品或服务的过程中对产品或服务及您的账号安全进行保护。
<br/>三、我们如何存储您的个人信息
<br/>（一）存储地域
<br/>我们在中国境内收集和产生的个人信息，将仅存储在中国境内。如果未来因为处理跨境业务需要向境外机构传输境内收集的个人信息的，我们会严格执行法律、法规、规章或规范性文件的规定，向您告知出境的目的、涉及的个人信息类型、接收方身份、安全风险，且事先征得您的同意，并通过签订协议、安全评估、核查等有效措施，要求接收方为所获得的个人信息保密并妥善处理，确保接收方有充分的能力来保护您的个人信息。
<br/>（二）存储期限
<br/>除非法律、法规、规章或规范性文件另有规定，我们将在实现本隐私政策中所述目的所必需的期间内保留您的个人信息，除非法律、法规、规章或规范性文件要求或允许在更长的期间内存储这些信息。
<br/>四、我们如何使用Cookie和同类技术
<br/>（一）Cookie
<br/>使用Cookie能提升联机体验，当您注册和登录时，我们会使用Cookie。我们使用Cookie判断您是否登陆，帮助您简化填写个人信息、输入搜索内容的步骤和流程，使您获得更轻松的访问体验。来自本网站的Cookie只能被本网站读取。
<br/>大多数浏览器都设置为接受Cookie。如果您想通过Cookie横幅更改Cookie偏好，可以通过浏览器设置或在您进入我们的任何网站时更新Cookie的偏好（如果适用）。如果您的浏览器被设置为拒绝Cookie，部分功能将不可实现，但您仍然能够访问本网站的大多数网页。
<br/>（二）其他同类技术
<br/>目前，我们并未使用除Cookie以外的其他同类技术。
<br/>五、个人信息的委托处理、共享、转让、公开披露
<br/>（一）委托处理
<br/>我们可能委托授权合作伙伴处理您的个人信息，以便授权合作伙伴代表我们为您提供某些服务或履行职能。我们仅会出于本隐私政策所述的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求他们妥善保护和处理你的个人信息，不得将您的个人信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的个人信息用于我们未委托的用途，其将单独征得您的同意。
<br/>（二）共享
<br/>我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外：
<br/>1.事先获得您明确的同意或授权；
<br/>2.基于法定情形：根据法律规定、诉讼等争议解决需要，司法机关或政府机构等依法提出的要求；
<br/>3.与关联方共享：我们可能会与我们的关联方（包括但不限于未来可能不时设立的关联公司）共享您的个人信息。但我们仅共享必要的个人信息，且关联方对您的个人信息的处理和保护亦须受本隐私政策所声明的目的的约束。关联方如要改变您的个人信息的处理目的，将再次征求您的授权和同意；
<br/>4.您与我们关于个人信息共享的其他约定。
<br/>请您知悉，即使已经取得您的授权同意，我们也仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并尽量对共享内容中的个人信息进行去标识化处理。对我们与之共享您的个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求其按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施妥善保护和处理您的个人信息。
<br/>（三）转让
<br/>我们不会将您的个人信息转让给其他任何公司、组织和个人，但以下情形除外：
<br/>1.随着我们业务的发展，我们及我们的关联公司有可能进行合并、收购、资产转让或其他类似的交易。如相关交易涉及到您的个人信息转让，我们会要求新持有您个人信息的公司、组织和个人继续受本隐私政策的约束，否则我们将要求该公司、组织和个人重新征得您的授权同意；
<br/>2.在获得您明确同意的情形下转让，亦即获得您明确同意后，我们会向其他方转让我们已经获取的您的个人信息。
<br/>（四）公开披露
<br/>我们仅会在以下情形中，公开披露您的个人信息：
<br/>1.已经获得您的单独同意；
<br/>2.根据中国法律的要求、强制性的行政执法或司法活动的要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在可行的限度内，当我们收到上述披露信息的请求时，我们会要求请求方出具与之相应的法律文件，如传票或调查；
<br/>3.中国法律允许的其他情形。
<br/>（五）征得授权同意的例外
<br/>根据中国法律，在以下情形中，收集和使用您的个人信息无需征得您的授权同意：
<br/>1.与国家安全、国防安全有关的；
<br/>2.与公共安全、公共卫生、重大公共利益有关的；
<br/>3.与犯罪侦查、起诉、审判和判决执行等有关的；
<br/>4.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
<br/>5.所收集的个人信息是您自行向社会公众公开的；
<br/>6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
<br/>7.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
<br/>8.为订立、履行个人作为一方当事人的合同所必需；
<br/>9.新闻单位为开展合法的新闻报道所必需的；
<br/>10.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
<br/>11.中国法律规定的其他情形。
<br/>六、未成年人个人信息保护
<br/>我们的产品和服务面向十八周岁以上的成年人，我们不向未成年人提供任何服务，并且我们拒绝收集未成年人的个人信息或者向这类人群发送任何宣传资料。如果家长或监护人有理由相信未成年人未经事先同意向我们提交了个人信息，请联系我们删除此类个人信息。
<br/>七、我们如何保护您的个人信息
<br/>（一）数据安全措施
<br/>为保障您的信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。使用加密技术确保数据的保密性；使用受信赖的保护机制防止数据遭到恶意攻击；部署访问控制机制，确保只有授权人员才可访问个人信息；以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
<br/>请注意，无法与任何特定个人直接建立联系的数据，不属于个人信息。如果我们将这类非个人信息与其他信息结合用于识别自然人个人身份，或将其与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。
<br/>（二）账号安全
<br/>互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保障您的账号安全。如果您发现自己的个人信息泄密，尤其是您的账号密码发生泄露，请您立即联络我们，以便我们采取相应措施。
<br/>（三）个人信息安全事件处置
<br/>在不幸发生个人信息安全事件后，我们将按照中国法律的规定，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
<br/>八、本隐私政策的更新及如何联系我们
<br/>我们的产品及服务可能不时发生变化，本隐私政策也将随之调整。未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在本站点网站发出更新版本或以其他方式提醒您相关内容的更新，也请您及时了解最新的隐私政策。对于重大变更，我们还会提供更为显著的通知。
<br/>如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，可以通过以下方式与我们联系：通过微信公众号联系客服，或者发送邮件至【】。
<br/>九、其他及争议解决
<br/>本隐私政策涉及内容遵照本隐私政策执行，未尽事宜遵照《用户协议》中的相关条款执行。
<br/>如果您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向我们住所地有管辖权的法院提起诉讼，以及中国法律允许的其他途径寻求解决方案。
      </div>`,
    };
