<template>
  <div class="card">
    <div class="card-title">分红情况汇总</div>
    <div class="wrap">
      <div class="flex items-center">
        <div class="sub-title">近期分红公告</div>
        <div style="margin-left: auto; margin-right: 0">
          <Download
            :dButtonsDownloads="dButtonsDownloads1"
            :excel_info="excel_info1"
            echartRefName="excel_info1" />
        </div>
      </div>
      <div style="margin-top: 10px">
        <el-table
          class="table1"
          :header-cell-style="tableStyle"
          :data="table_data"
          @row-click="fnClick"
          height="550"
          style="width: 100%">
          <el-table-column type="index" width="50" label="No."></el-table-column>
          <el-table-column align="center" prop="代码" label="代码" min-width="94">
          </el-table-column>
          <el-table-column align="center" prop="大类" label="大类" min-width="94">
          </el-table-column>
          <el-table-column align="center" prop="细分" label="细分" min-width="100">
          </el-table-column>
          <el-table-column align="center" prop="REITs名称" label="简称" min-width="150">
          </el-table-column>
          <!-- <el-table-column align="center" prop="方案进度" label="方案进度" width="90">
          </el-table-column> -->
          <el-table-column align="center" prop="公告日" sortable label="公告日" min-width="100">
          </el-table-column>
          <el-table-column
            align="center"
            prop="权益登记日"
            sortable
            label="权益登记日"
            min-width="124">
          </el-table-column>
          <el-table-column align="center" prop="除权日" sortable label="除权日" min-width="100">
          </el-table-column>
          <el-table-column
            align="center"
            prop="分红支付日"
            sortable
            label="分红支付日"
            min-width="124"></el-table-column>
          <el-table-column
            align="center"
            prop="单次分派率"
            label="单次分派率"
            sortable
            min-width="130">
            <template slot-scope="scope">
              {{ scope.row['单次分派率'] | pct }}
            </template> </el-table-column
          ><el-table-column
            align="center"
            prop="目前分派率"
            label="目前分派率"
            sortable
            min-width="130">
            <template slot-scope="scope"> {{ scope.row['目前分派率'] | pct }} </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="每基金单位分红"
            label="每基金单位分红"
            sortable
            min-width="130">
            <template slot-scope="scope"> {{ scope.row['每基金单位分红'] | fix2 }} </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="当年以来累计分红收益"
            label="当年以来累计分红收益"
            sortable
            min-width="150">
            <template slot-scope="scope">
              {{ scope.row['当年以来累计分红收益'] | pct }}
            </template> </el-table-column
          ><el-table-column
            align="center"
            prop="近1年累计分红收益"
            label="近1年累计分红收益"
            sortable
            min-width="130">
            <template slot-scope="scope"> {{ scope.row['近1年累计分红收益'] | pct }} </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="当年累计分红次数"
            label="当年累计分红次数"
            sortable
            min-width="130">
          </el-table-column>
          <el-table-column
            align="center"
            prop="近1年分红次数"
            label="近1年分红次数"
            sortable
            min-width="130">
          </el-table-column>
        </el-table>
      </div>
      <div :style="{ 'margin-top': '30px' }">
        <CButtons
          :dButtons1="button_data"
          :dButtons1_current="select_1_default"
          @Cbtns_sep="select1Change" />
      </div>
      <div class="relative" :style="{ 'margin-top': '30px' }">
        <div class="sub-title flex">
          主要个股年均分红次数
          <div style="margin-left: auto; margin-right: 0">
            <Download
              :dButtonsDownloads="dButtonsDownloads2"
              :excel_info="excel_info2"
              echartRefName="chaert1Ref" />
          </div>
        </div>
        <div class="sub-t1">年均分红次数</div>

        <div
          style="width: 100%; height: 450px; margin-top: 10px"
          ref="chaert1Ref"
          v-loading="loading"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 1)"></div>
      </div>
      <div class="relative" :style="{ 'margin-top': '30px' }" ref="dwn3Ref">
        <div class="sub-title flex">
          主要REITs板块各支REITs年均分红次数
          <div style="margin-left: auto; margin-right: 0">
            <Download
              :dButtonsDownloads="dButtonsDownloads2"
              :excel_info="excel_info3"
              echartRefName="dwn3Ref" />
          </div>
        </div>
        <div class="sub-t1">年均分红次数</div>
        <div
          style="width: 100%; height: 450px; margin-top: 10px"
          ref="chaert2Ref"
          v-loading="loading"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 1)"></div>
        <!-- <div data-text="分红劳模型行业" class="c_box"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CButtons from '../Basic/CButtons_separate.vue';
import Download from '../Basic/Download.vue';

import { fnReturnTableStyle } from '@/utils/util';
import { numberFix2, number2Pct } from '@/utils';

export default {
  name: 'Dividend',
  components: { CButtons, Download },
  data() {
    return {
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      ROOTFONTFIZE: '',
      table_data: Object.freeze([]),
      button_data: Object.freeze(['YTD']),
      select_1_default: 'YTD',
      single_reit: Object.freeze([]),
      category_reit: Object.freeze([]),
      ipo_year: Object.freeze({ max: 1, min: 0 }),
      loading: true,
      dButtonsDownloads1: Object.freeze([
        /* {
          id: '241020',
          name: '下载图片',
        }, */
        {
          id: '241020',
          name: '下载表格',
        },
      ]),
      dButtonsDownloads2: Object.freeze([
        {
          id: '241020',
          name: '下载图片',
        },
        {
          id: '241020',
          name: '下载表格',
        },
      ]),
      excel_info1: Object.freeze({
        /*overviewName: '',
          excel_data:[],
          json_fields:{}
        */
      }),
      excel_info2: Object.freeze({
        /*overviewName: '',
          excel_data:[],
          json_fields:{}
        */
      }),
      excel_info3: Object.freeze({
        /*overviewName: '',
          excel_data:[],
          json_fields:{}
        */
      }),
    };
  },
  computed: {
    tableStyle() {
      const val = this.$store.state.theme === 'dark';
      return () => fnReturnTableStyle(val);
      // return fnReturnTableStyle(val);
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      this.renderChart1();
      this.renderChart2();
    },
  },
  filters: {
    pct(n) {
      if (n === '') return '';
      return number2Pct(n);
    },
    fix2(n) {
      if (n === '') return '';
      return numberFix2(n, 3);
    },
  },
  methods: {
    fnClick(row, column, cell) {
      let arr = [row['REITs名称'], row['代码']];
      // console.clear();
      // console.log(arr);
      this.$eventBus.$emit('skipPage', arr);
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    select1Change(v) {
      this.select_1_default = v;
      this.loadData3();
    },
    pageInit() {
      if (this._page_init) {
        setTimeout(() => {
          this.resize();
        }, 100);
        return;
      }
      this.loadData();
    },
    loadData() {
      this.loadData1();
      this.loadData2();
      this.loadData3();
    },
    getExcelInfo1() {
      const overviewName = '近期分红公告';
      const json_fields = Object.keys((this.table_data || [])[0] || {}).reduce((p, c) => {
        p[c] = c;
        return p;
      }, {});
      setTimeout(() => {
        this.excel_info1 = Object.freeze({
          json_fields,
          overviewName,
          excel_data: this.table_data,
        });
      }, 0);
    },
    getExcelInfo2() {
      const overviewName = '主要个股年均分红次数';
      const json_fields = {
        代码: 'reit_code',
        简称: 'name',
        大类: 'category_1',
        细分: 'category_2',
        ipo_date: 'ipo_date',
        年均分红次数: 'data',
      };
      setTimeout(() => {
        this.excel_info2 = Object.freeze({
          json_fields,
          overviewName,
          excel_data: this.single_reit,
        });
      }, 0);
    },
    getExcelInfo3() {
      const overviewName = '主要REITs板块各支REITs年均分红次数';
      const json_fields = {
        板块: 'index',
        分红次数: 'data',
      };
      setTimeout(() => {
        this.excel_info3 = Object.freeze({
          json_fields,
          overviewName,
          excel_data: this.category_reit,
        });
      }, 0);
    },
    async loadData1() {
      const { data } = await this.$https.get(`/api/v2_2/div_sum_table`);
      if (data.code !== 200) return this.$message.error(data.msg);
      this._page_init = true;
      this.table_data = Object.freeze(data.data);
      this.getExcelInfo1();
    },
    // 按钮
    async loadData2() {
      const { data } = await this.$https.get(`/api/v2_2/div_button`);
      if (data.code !== 200) return this.$message.error(data.msg);
      this._page_init = true;
      this.button_data = Object.freeze(['YTD', '历年平均', ...data.data.map((v) => v + '')]);
    },
    async loadData3() {
      this.loading = true;
      const time_type = this.select_1_default === '历年平均' ? 'max' : this.select_1_default;
      const { data } = await this.$https.get(`/api/v2_2/div_barchart?time_type=${time_type}`);
      if (data.code !== 200) return this.$message.error(data.msg);
      this._page_init = true;
      this.loading = false;
      const single_data = ((data.data || {}).single_reit || []).map((item) => {
        return {
          ...item,
          ipo_date: new Date(item.ipo_date.replace(/-/g, '/')).getFullYear(),
        };
      });
      this.single_reit = Object.freeze(single_data.sort((a, b) => b.data - a.data));
      this.category_reit = Object.freeze(
        ((data.data || {}).category || []).sort((a, b) => b.data - a.data)
      );
      const date = single_data.map((item) => item.ipo_date * 1);
      this.ipo_year = Object.freeze({
        max: Math.max(...date),
        min: Math.min(...date),
      });
      this.$nextTick(() => {
        this.renderChart1();
        this.renderChart2();
        this.getExcelInfo2();
        this.getExcelInfo3();
      });
    },
    renderChart1() {
      if (!this.echart1_instance) {
        this.echart1_instance = this.$echarts.init(this.$refs.chaert1Ref);
      }
      this.echart1_instance.clear();
      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      const options = {
        dataset: [
          {
            dimensions: [
              'reit_code',
              'name',
              'ipo_date',
              'data',
              'category_2',
              'category_1',
              'IPO_days',
            ],
            source: this.single_reit,
          },
        ],
        series: [
          {
            type: 'bar',
            encode: { x: 'name', y: 'data' },
            label: {
              show: true,
              position: 'top',
              fontSize: 14,
              color: OPTIONDATA.legend.textStyle.color,
              formatter: ({ data }) => {
                if (this.select_1_default === '历年平均') {
                  return numberFix2(data.data);
                }
                return data.data;
              },
            },
          },
        ],
        visualMap: {
          right: this.ROOTFONTFIZE * (10 / 144),
          top: 'center',
          text: ['上市年份'],
          min: this.ipo_year.min,
          max: this.ipo_year.max,
          calculable: true,
          textStyle: { color: OPTIONDATA.yAxis.axisLabel.color },
          dimension: `ipo_date`,
          inRange: {
            color: ['#39dde3', '#55e2e7', '#72e7eb', '#8eecef', '#aaf0f3', '#c6f5f7'],
          },
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: this.single_reit.length > 10 ? 270 : 0,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
        // 四个方向的空白
        grid: {
          top: this.ROOTFONTFIZE * (60 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (120 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
      };
      this.echart1_instance.setOption(options);
      this.echart1_instance.resize();
    },
    renderChart2() {
      if (!this.echart2_instance) {
        this.echart2_instance = this.$echarts.init(this.$refs.chaert2Ref);
      }
      this.echart2_instance.clear();
      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;
      const k = ['产权类', '经营权类'];
      const data1 = this.category_reit.filter((c) => k.includes(c.index));
      // .sort((a, b) => b.data - a.data);
      const data2 = this.category_reit.filter((c) => !k.includes(c.index));
      // .sort((a, b) => b.data - a.data);
      // const w_size = data2.length;
      // let w = this.$refs.chaert2Ref.clientWidth * 0.55;
      // if (w_size === 0) {
      //   w = 0;
      // }
      // if (w_size >= 3) {
      //   w = w * ((1 / w_size) * 3);
      // }
      // const h = this.$refs.chaert2Ref.clientHeight - 120;
      // const el = document.querySelector('.c_box');
      // el.style.width = w + 16 + 'px';
      // el.style.height = h + 'px';
      // el.style.left = '41%';
      // el.style.top = this.ROOTFONTFIZE * (80 / 144) + 'px';

      const colors = this.$store.state.colors || [];
      const colors_2 = colors.reduce((p, c) => {
        p[c.name] = c.value;
        return p;
      }, {});
      const options = {
        dataset: [
          {
            dimensions: ['index', 'data'],
            source: data1,
          },
          {
            dimensions: ['index', 'data'],
            source: data2,
          },
        ],
        series: [
          {
            xAxisIndex: 0,
            yAxisIndex: 0,
            type: 'bar',
            encode: { x: 'index', y: 'data' },
            label: {
              show: true,
              position: 'top',
              fontSize: 14,
              color: OPTIONDATA.legend.textStyle.color,
              formatter: ({ data }) => {
                return numberFix2(data.data);
              },
            },
            itemStyle: {
              color: ({ name }) => {
                return colors_2[name];
              },
            },
          },
          {
            xAxisIndex: 1,
            yAxisIndex: 1,
            datasetIndex: 1,
            type: 'bar',
            encode: { x: 'index', y: 'data' },
            label: {
              show: true,
              position: 'top',
              fontSize: 14,
              color: OPTIONDATA.legend.textStyle.color,
              formatter: ({ data }) => {
                return numberFix2(data.data);
              },
            },
            itemStyle: {
              color: ({ name }) => {
                return colors_2[name];
              },
            },
          },
        ],
        /* graphic: [
          {
            type: 'group',
            z: 100,
            left: '41%',
            top: this.ROOTFONTFIZE * (30 / 144),
            children: [
              {
                type: 'rect',
                shape: {
                  width: w,
                  height: h,
                },
                style: {
                  fill: 'rgba(0,0,0,0)',
                  stroke: OPTIONDATA.xAxis.axisLabel.color,
                  lineDash: 'dashed',
                  lineWidth: 1,
                  shadowBlur: 8,
                  shadowOffsetX: 3,
                  shadowOffsetY: 3,
                  shadowColor: 'rgba(0,0,0,0.2)',
                },
              },
              {
                type: 'text',
                top: '-20',
                style: {
                  fill: OPTIONDATA.xAxis.axisLabel.color,
                  text: '分红劳模型行业',
                  // font: 'bold 26px sans-serif',
                },
              },
            ],
          },
        ], */
        grid: [
          {
            top: this.ROOTFONTFIZE * (60 / 144),
            left: this.ROOTFONTFIZE * (35 / 144),
            width: '35%',
            containLabel: true,
          },
          {
            right: this.ROOTFONTFIZE * (60 / 144),
            top: this.ROOTFONTFIZE * (60 / 144),
            width: '55%',
            containLabel: true,
          },
        ],
        xAxis: [
          {
            gridIndex: 0,
            type: 'category',
            axisLabel: {
              interval: 0,
              margin: OPTIONDATA.xAxis.axisLabel.margin,
              fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
          },
          {
            gridIndex: 1,
            type: 'category',
            axisLabel: {
              interval: 0,
              margin: OPTIONDATA.xAxis.axisLabel.margin,
              fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
          },
        ],
        yAxis: [
          {
            gridIndex: 0,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
            },
            splitLine: OPTIONDATA.yAxis.splitLine,
          },
          {
            gridIndex: 1,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
            },
            splitLine: OPTIONDATA.yAxis.splitLine,
          },
        ],
      };
      this.echart2_instance.setOption(options);
      this.echart2_instance.resize();
    },
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
      return;
    },
    resize() {
      this.fnGetWidth();
      this.echart1_instance && this.echart1_instance.resize();
      this.echart2_instance && this.echart2_instance.resize();
    },
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    this.echart1_instance && this.echart1_instance.dispose();
    this.echart2_instance && this.echart2_instance.dispose();
  },
};
</script>

<style scoped lang="less">
.card {
  background-color: rgba(29, 31, 37, 1);
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  height: auto;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  color: var(--chart_title);
  .card-title {
    color: var(--chart_title);
    font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
    font-weight: 700;
    // border-left: 5px solid #1573fe;
    border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
    padding-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
    position: relative;
  }
  .wrap {
    width: 97%;
    margin: auto;
    padding-top: 20px;
    color: var(--chart_title);
    .sub-title {
      font-weight: 580;
      font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
    }
    .container {
      margin-left: 0;
    }
    .sub-t1 {
      position: absolute;
      left: 0;
      top: 190px;
      width: 1em;
      font-size: 15px;
    }
    .c_box {
      pointer-events: none;
      position: absolute;
      border: 1px var(--chart_title) dashed;
      &::after {
        content: attr(data-text);
        display: block;
        position: absolute;
        left: 0;
        top: -30px;
        width: 100%;
        font-size: 15px;
        text-align: center;
      }
    }
  }
}

//fix 滚动条重叠部分背景颜色
/deep/.el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: transparent;
}
/deep/.items-center {
  align-items: center;
}
/deep/.relative {
  position: relative;
}
/deep/.flex {
  display: flex;
}

/* el-table start */

::v-deep .table1 table {
  border: none;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid var(--table_brc);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  /* background-color: var(--table_color_hover); */
  color: var(--table_color_hover);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell:hover {
  cursor: pointer;
}

::v-deep .el-table::before {
  background-color: var(--table_bgc);
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: var(--table_bgc);
}

/* el-table end */
</style>
