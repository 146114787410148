<template>
  <div class="container">
    <span>
      <el-button
        v-for="(item, index) in dButtonsDownload"
        :key="index"
        v-has="item.id"
        icon="el-icon-download"
        @click="fnButtons1(item)"
        class="noActive"
        size="small"
        data-html2canvas-ignore>
        {{ item.name }}
      </el-button>
    </span>
  </div>
</template>

<script>
import hasDirectives from '@/directives/has';

export default {
  directives: {
    has: hasDirectives,
  },
  // 从父组件传值的
  props: {
    dButtonsDownloads: {
      type: Array,
      default: [],
    },
  },
  methods: {
    fnButtons1(val) {
      // console.log(val,'林允儿');
      this.$emit('fnButtondownload', val.name);
    },
  },
  components: {},
  computed: {},
  created() {
    //  this.ROOTFONTFIZE  = window.sessionStorage.getItem("ROOTFONTFIZE");
  },
  data() {
    return {
      ROOTFONTFIZE: '',
      //  dButtons1:this.dButtons1.length>0?this.dButtons1:['下载图片','下载表格'],
      dButtonsDownload: this.dButtonsDownloads,
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  font-weight: 400;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
}

span {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

// 切换按钮  start --------------------------------------------

.el-button {
  font-size: 14;
  font-weight: 400;
  letter-spacing: 0.1px;
  height: 30px;
  // border: 1px solid #49495b !important;
}

.noActive {
  background-color: var(--Btn-bgc2) !important;
  // color: var(--stock_area_item_color2) !important;
  color: var(--stock_area_item_color1) !important;
  border: 1px solid var(--Btn-borderColor) !important;
  // border: 1px solid #49495b !important;
}

::v-deep .el-icon-download {
  color: var(--Btn-download-color);
  font-weight: 700;
  float: left;
}

.el-button:hover {
  background-color: var(--Btn-bgc_hover) !important;
  color: var(--Btn-color2) !important;
  // border: 1px solid var(--Btn-brc) !important;
  border: 1px solid var(--primary-color) !important;
}
</style>
